import { httpClient } from "./httpUtil";

const CsrTaskService = {
    createCsrTsk: ( data) => {
        return httpClient.post(`/task/v1`,{...data},{},{},true);
      },
    getCsrTaskByUserId: (params) => {
        return httpClient.get(`/task/v1/all`,{...params}, {},true);
    },
    getAllTasks:(params)=>{
        return httpClient.get(`/task/v1/all`,{...params}, {},true);
    },
    updateCsrTaskStatus: (taskId,body) => {
      return httpClient.put(`/task/v1/${taskId}/update-status`,{...body}, {},{},true);
  },
  getCsrTaskTags: () => {
    return httpClient.get(`/task/v1/metadata`,{},{},{},true);
},
}

export {CsrTaskService};