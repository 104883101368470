import React, { useState, useEffect } from "react";
import CustomDatePicker from "../../commonComponents/CustomDatePicker";
import CustomSelect from "../../components/OnboardAuthorCategory";
import { ManageAppointmentWrapper, StyledTimePicker } from "./index.styled";
import { timeUtil } from "../../utils/timeUtils";
import FlexRow from "../../commonComponents/FlexRow/FlexRow";
import Button from "../../commonComponents/Button";
import TimeSlot from "../../components/TimeSlot";
import moment from "moment";
import { AiFillCloseCircle } from "react-icons/ai";
import { useLocation, useParams } from "react-router";

import { getadvisor } from "./../../Services/SuperSaver";
import {
  allConsultationType,
  allConsultationTypeUnregistered,
  allDurations,
  getEventDescription,
} from "./constants";
import { CalendarAPI } from "../../Services/CalendarAPI";
import { convertToPascalCase } from "../../utils/textUtil";
import Input from "./components/Input";
import { isEmailValid } from "../../utils/validate";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { LoaderIcon, toast } from "react-hot-toast";
import LayoutHOC from "../../components/UserSideBar";
import Backdrop from "../../components/Backdrop";
import ReschedulePopup, {
  EventDescription,
  SlotsUi,
} from "./components/ReschedulePopup";

const getFullName = (details) => {
  let name = "";
  if (details.first_name) {
    name += details.first_name + " ";
  }
  if (details.middle_name) {
    name += details.middle_name + " ";
  }
  if (details.last_name) {
    name += details.last_name + " ";
  }
  return name.trim();
};

function ManageAppointment() {
  const [bookingModelPopupStatus, setBookingModelPopupStatus] = useState(false);
  const [rescheduleObj, setRescheduleObj] = useState({
    advisor: "",
    type: "",
    time: "",
    call_booking_id: "",
  });
  const removeSpecialCharacte = (str) =>
    str?.slice(0, str.indexOf(":")).trim().replace("_", " ").toUpperCase();
  const removeLastCharacte = (str) =>
    str
      ?.slice(str.indexOf(">") + 1)
      .trim()
      .replace("_", " ")
      .toUpperCase();
  const toggleBackdrop = (re, value) => {
    const { title, start, call_booking_id, selectedAdvisor } = value || {};
    const type = removeSpecialCharacte(title);

    setRescheduleObj({
      ...value,
      type,
      advisor: selectedAdvisor,
      call_booking_id,
      time: start,
    });

    setBookingModelPopupStatus((state) => !state);
  };
  return (
    <ManageAppointmentWrapper>
      <ReschedulePopup
        toggleBackdrop={toggleBackdrop}
        bookingModelPopupStatus={bookingModelPopupStatus}
        rescheduleObj={rescheduleObj}
      />

      {bookingModelPopupStatus && (
        <Backdrop toggleBackdrop={toggleBackdrop}>
          <ReschedulePopup toggleBackdrop={toggleBackdrop} {...rescheduleObj} />
        </Backdrop>
      )}
    </ManageAppointmentWrapper>
  );
}

export default LayoutHOC(ManageAppointment);

const FormFillForBooking = ({
  user,
  currentAttendee,
  setStep,
  step,
  setAttendees,
  setCurrentAttendee,
  state,
  selectedConsultationType,
  setSelectedConsultationType,
  advisors,
  selectedAdvisor,
  setSelectedAdvisor,
  advisorDetails,
  isUser,
  attendees,
}) => {
  return (
    <div className="heading">
      <h2 className="create-event-title">User Details</h2>
      <p className="label">Client Name</p>
      <CustomSelect
        placeholder="Select Client"
        id="user"
        name="user"
        defaultValue={
          user && {
            ...user,
            value: user.user_id,
            label: user.name,
          }
        }
        isMulti={false}
        isDisabled={true}
      />
      <p className="label">Consultation Type</p>
      <CustomSelect
        tags={
          state?.state?.registered
            ? allConsultationType
            : allConsultationTypeUnregistered
        }
        placeholder="Select Consultation Type"
        id="consultationType"
        name="consultationType"
        value={selectedConsultationType}
        handleInput={(item) => setSelectedConsultationType(item)}
        defaultValue={selectedConsultationType}
        isMulti={false}
      />
      <p className="label">Advisor</p>
      <CustomSelect
        tags={advisors}
        placeholder="Select Advisor"
        id="advisor"
        name="advisor"
        value={selectedAdvisor}
        handleInput={(item) => setSelectedAdvisor(item.user_details)}
        defaultValue={
          advisorDetails && {
            ...advisorDetails,
            value: advisorDetails.external_id,
            label: getFullName(advisorDetails),
          }
        }
        isMulti={false}
      />

      {isUser() && (
        <>
          <p className="label">Attendees</p>
          {attendees.map((it) => {
            return <p style={{ margin: 0 }}>{it}</p>;
          })}
          <div style={{ display: "flex" }}>
            <Input
              key={attendees.length}
              label=""
              type="search"
              value={currentAttendee}
              placeholder="Attendee Email"
              validator={isEmailValid}
              onChange={(v) => setCurrentAttendee(v)}
              validatorError="Enter valid email"
            />

            <Button
              style={{
                marginTop: 10,
                marginLeft: 10,
                width: 50,
              }}
              buttonName="Add"
              onButtonClick={() => {
                if (isEmailValid(currentAttendee)) {
                  setAttendees([...attendees, currentAttendee]);
                  setCurrentAttendee("");
                }
              }}
            />
          </div>
        </>
      )}
      <Button buttonName="NEXT" onButtonClick={() => setStep(step + 1)} />
    </div>
  );
};

const DateAndTimePickerForBooking = ({
  startTime,
  setStartTime,
  selectedDuration,
  durations,
  setSelectedDuration,
  endTime,
}) => {
  return (
    <div className="heading">
      <h2 className="create-event-title">Date &amp; Time</h2>
      <FlexRow
        style={{
          justifyContent: "start",
          alignItem: "center",
          marginTop: "-14px",
        }}
      >
        <div>
          <p className="label">Date</p>
          <CustomDatePicker
            value={timeUtil.getTime(startTime)}
            defaultValue={timeUtil.getTime(startTime)}
            onChange={(e) => setStartTime(new Date(e.target.value))}
          />
        </div>
        <div style={{ width: "100%", marginTop: "8px" }}>
          <p className="label">Duration</p>
          <CustomSelect
            style2={{
              marginLeft: "12px",
              display: "block",
              flex: 1,
            }}
            value={selectedDuration}
            tags={durations}
            placeholder="Select Duration"
            id="duration"
            name="duration"
            handleInput={(item) => {
              setSelectedDuration(item);
            }}
            handleOnBlur={() => {}}
            defaultValue={selectedDuration}
            isMulti={false}
          />
        </div>
      </FlexRow>
      <FlexRow>
        <div style={{ flex: 1 }}>
          <p className="label">Start Time</p>
          <StyledTimePicker
            style={{
              marginLeft: "-10px",
              width: "180px",
            }}
            value={moment(startTime)}
            minuteStep={5}
            showSecond={false}
            onChange={(e) => {
              setStartTime(new Date(moment(e).format()));
            }}
            clearIcon={<AiFillCloseCircle />}
          />
        </div>
        <div style={{ flex: 1, marginTop: "0" }}>
          <p className="label" style={{ marginTop: "8px" }}>
            End Time
          </p>
          <StyledTimePicker
            style={{
              marginLeft: "-0px",
              width: "180px",
            }}
            value={moment(endTime)}
            minuteStep={5}
            showSecond={false}
            clearIcon={<AiFillCloseCircle />}
            disabled={true}
          />
        </div>
      </FlexRow>
    </div>
  );
};
