import React from "react";
import EditBtn from "../../../../commonComponents/EditBtn";
import { ClientDetails } from "../../../SuperClientProfile/SuperSaverProfile.styled";
import { USER_TYPE } from "../../../../utils/localStorageKeys";
import { USER_ROLE } from "../../../../constants/userRole";

const CoinModificationComponent = ({ coinBalance, show, setShow, userId }) => {
  const userType = JSON.parse(localStorage.getItem(USER_TYPE));
  const userRole = userType?.user_roll;

  const styling = {
    justifyContent: "space-between",
  }

  return (
    <div className="clientBox">
      <div className="ClientHed">Coin Balance</div>
      <ClientDetails
        className="clientDetails"
        style={styling}
      >
        <div className="balance">{coinBalance}</div>
        {userRole === USER_ROLE.ADMIN && (
          <span>
            <EditBtn
              HandleClick={() => {
                setShow(true);
              }}
            />
          </span>
        )}
      </ClientDetails>
    </div>
  );
};

export default CoinModificationComponent;
