import BASE_URL from "./API_ENDPOINT";
import { getHeaders } from "./SuperSaverClientChat";
import { httpClient } from "./httpUtil";
import toast from "react-hot-toast";

export const CMSApis = {
    draftArticle: async (data) => {
        const url = `${BASE_URL}articles/v1`;
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: await getHeaders(),
        });
        return await response.json();
    },
    draftArticleNew: async (data) => {
        const url = `${BASE_URL}articles`;
        const response = await httpClient.post(url,data);
        return  response;
    },
    getAllAuthors: async (data) => {
        const url = `${BASE_URL}authors`;
        const response = await httpClient.get(url);
        return  response;
    },

    getArticles: async (category,publishType="PUBLISHED",nextToken,pageSize,authorId,startDate,endDate) => {
        const dateFilter = (startDate && endDate) ? { start_date: startDate, end_date: endDate } : null;

        const url = `${BASE_URL}articles/all`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify(
                {
                    "article_category": [
                      category
                    ],
                    "sort_by": "RECENT",
                    "author_id":authorId,
                    "next_token": nextToken || 0,
                    "page_size": pageSize || 10,
                    "publish_type": publishType,
                    "article_date_filter": dateFilter
                  })
        });
        return await response.json();
    },

    getArticleById : async (id) => {
        try {
            const url = `${BASE_URL}articles/${id}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Source':'cms'
                }
            });
    
            const data = await response.json();
       
            return data;
        } catch (error) {
            console.error('Error fetching article:', error);
        }
    },

    updateArticleStatus : async (id,status,published_by) => {
        try {
            const url = `${BASE_URL}articles/${id}/update-status?article_status=${status}&published_by=${published_by}`;
            const response = await fetch(url,{method:'PUT',headers: await getHeaders()});
    
        
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching article:', error);
            toast.error(error.message);
        }
    },

    getArticleCategories: async () => {
        const url = `${BASE_URL}articles/categories`;
        const response = await fetch(url);
        return await response.json();
    },

    getAuthors: async() =>{
        const url = `${BASE_URL}authors`;
        const response = await fetch(url, { headers: await getHeaders() })
        return await response.json();
    },
    deleteArticle: async (id) => {
        const url = `${BASE_URL}articles/v1?articleId=${id}`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: await getHeaders(),
        });
        return response;
    },

    getTags: async () => {
        const url = `${BASE_URL}tags/v1/all`;
        const response = await fetch(url, {
            method: "GET",
            headers: await getHeaders(),
        });
        return await response.json();
    },
};
