import React, { useState } from "react";
import { timeStampDateConverter } from "../../../../utils/currentTime";
import { useDispatch, useSelector } from "react-redux";
import { StyledUpcomingCallDetails } from "./UpcomingCallDetails.styled";
import { dim, theme } from "../../../../styles/theme";
import { useParams } from "react-router";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import { CONSULTATION_STATUS } from "../../constant";
import Chip from "../../../../commonComponents/Chip";
import {
  getTimeZoneFromTimestamp,
  handleActivateCancelPopUp,
  handleCallConfirmation,
} from "../../utils";
import TextButton from "../../../../commonComponents/TextButton";
import CancelCallPreview from "../CancelCallPreview";
import Backdrop from "../../../../components/Backdrop";
import ConfiramtionPopup from "../ConfirmationPopup";
import { useSearchParams } from "react-router-dom";
import { getColorByImportance } from "../../../../utils/getColorByImportance";
import { dateFormatter } from "../../../../utils/timeUtils";

const UpcomingCallDetails = ({ upcomingCalls, index }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const nameParam = searchParams.get("user_id");
  const { userId } = params;
  const dispatch = useDispatch();
  const consultationData = useSelector(
    (state) => state.ConsultationReducer.consultationData
  );
  const chipStyle = {
    padding: `${dim._4px} ${dim._8px}`,
    borderRadius: dim._4px,
    fontWeight: 400,
  };
  const advisorName =
    upcomingCalls?.advisor_first_name + " " + upcomingCalls?.advisor_last_name;
  const callTitle = upcomingCalls?.title;
  const handleCancelBtn = () => {
    upcomingCalls.CTAAction(dispatch, consultationData, index);
  };
  const handleCancelCall = (CONSULTATION_STATUS) => {
    const { user_roll_id: userRollId } = JSON.parse(
      localStorage.getItem(LOCAL_DB_KEYS.CURRENT_USER)
    );

    const { call_booking_id } = upcomingCalls;
    const status = CONSULTATION_STATUS;
    const objData = {
      ...upcomingCalls,
      call_booking_id,
      status,
      requester_id: userRollId,
      userId: userId ? userId : nameParam,
    };
    handleCallConfirmation(dispatch, objData);
  };

  const textColor =
    upcomingCalls.active_time >= 0 ? "#D64541" : theme.color.grey_10;
  const cursorStyle =
    upcomingCalls.active_time >= 0 ? "pointer" : "not-allowed";

  return (
    <StyledUpcomingCallDetails width="100%">
      <div className="upcomingTag">Upcoming</div>
      <div className="firstLine">
        <div className="actions">
          {upcomingCalls?.action_items?.map((actionable, i) => (
            <Chip
              key={i}
              style={{
                ...chipStyle,
                ...getColorByImportance(actionable.importance),
              }}
            >
              {actionable.title}
            </Chip>
          ))}
        </div>
        {/* <div className="advisorComp iconComponent">
          <img
            src={process.env.PUBLIC_URL + `/assets/icons/advisorIconMom.svg`}
            alt=""
          />
          <div className="text">{advisorName} (Advisor)</div>
        </div> */}
      </div>
      <div className="secondLine">
        <div className="callInfo">
          <div className="callTitle">*{callTitle}</div>
        </div>
        <div className="iconComponent">
          <div className="dateCompo iconComponent">
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/calenderIcon.svg"}
              alt=""
            />
            <div className="text">
              {" "}
              {dateFormatter(upcomingCalls.start_time)}
            </div>
          </div>
          <div className="callDuration iconComponent">
            <img
              src={process.env.PUBLIC_URL + `/assets/icons/durationIcon.svg`}
              alt=""
            />
            <div className="text">
              {timeStampDateConverter(upcomingCalls.start_time)} -{" "}
              {timeStampDateConverter(upcomingCalls.end_time)}{" "}
              {getTimeZoneFromTimestamp(upcomingCalls.start_time)}
            </div>
          </div>
        </div>
        {upcomingCalls.isCTAAvailable && (
          <div className="advisorComp iconComponent">
            <TextButton
              style={{
                textDecoration: "underline",
                color: textColor,
                cursor: cursorStyle,
              }}
              onClick={() =>
                upcomingCalls.active_time >= 0 ? handleCancelBtn() : null
              }
            >
              {upcomingCalls.active_time < 0
                ? upcomingCalls.CTALabel
                : upcomingCalls.CTALabel}
            </TextButton>
          </div>
        )}
      </div>
      {/* {upcomingCalls.isCancelPopUpActive && (
        <CancelCallPreview
          activateCancelPopUp={upcomingCalls.isCancelPopUpActive}
          handleCancelBtn={handleCancelBtn}
          handleCancelCall={handleCancelCall}
        />
        
      )} */}
      {upcomingCalls.isCancelPopUpActive && (
        <Backdrop toggleBackdrop={() => handleCancelBtn()}>
          {
            <ConfiramtionPopup
              handleSubmit={() => {
                handleCancelCall(CONSULTATION_STATUS.COMPLETED);
              }}
              handleCancelBtn={handleCancelBtn}
              handleNoBtn={() => {
                handleCancelCall(CONSULTATION_STATUS.NOT_ATTENDED);
              }}
            />
          }
        </Backdrop>
      )}
    </StyledUpcomingCallDetails>
  );
};

export default UpcomingCallDetails;
