import React, { useState } from "react";
import UploadFile from "../uploadFile";
import { UploadAdvDocFormStyled } from "./UploadAdvDocForm.styled";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { postAdvisorDoc } from "../../Services/AdvisorDoc";
import { getAuth } from "firebase/auth";
import { getFillName } from "../../utils/randomFileNameGenerator";
import { PreSignedUrlUpload } from "../../utils/preSignedUrl";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const UploadAdvDocForm = ({ handleCross, userId, docArr, updateDocArr }) => {
    const [uploadImage, setUploadImage] = useState(false);
    const [originalLink, setOriginalLink] = useState("");
    const [buttonActive, setButtonActive] = useState(false);
    const [documentObj, setDocumentObj] = useState({
        name: "",
        details: "",
        file: "",
        type: "COMPUTATION",
    });
    const fyObj = useSelector((state) => state.LayoutReducer.F_Y_OBJ);
    const FYOptions = useSelector((state) => state.LayoutReducer.F_Y_ARR);
    const [selectedFy, setSelectedFy] = useState({});

    useEffect(() => {
        handleFYChange({
            label: fyObj.financialYear,
            value: fyObj.financialYearId,
        });
    }, [fyObj]);

    const handleFYChange = (value) => {
        setSelectedFy(value);
    };

    useEffect(() => {
        setButtonActive(
            documentObj.name.length > 0 &&
                documentObj.details.length > 0 &&
                documentObj.file.length > 0
        );
    }, [documentObj]);

    const handleImageUpload = (e) => {
        const imageFile = e.target.files[0];
        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf(".");
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        const newFilename = getFillName(
            fileName,
            ext,
            "advisordocument",
            userId
        );

        const ReturnValueSetter = () => {
            setOriginalLink(URL.createObjectURL(imageFile));
            setUploadImage(true);
            setDocumentObj({
                ...documentObj,
                file: newFilename,
            });
        };

        getAuth().onAuthStateChanged(function (user) {
            if (user) {
                    const preSignedUrl = PreSignedUrlUpload(
                        newFilename,
                        imageFile,
                        userId,
                        ReturnValueSetter
                    );
                    toast.promise(preSignedUrl, {
                        loading: "Document Uploading...",
                        success: "Document Uploaded",
                        error: "Oops something went wrong",
                    });
            }
        });
    };

    const handleCancelUpload = () => {
        setUploadImage(null);
        setDocumentObj({ ...documentObj, file: "" });
    };
    let userName = localStorage.getItem("user");
    const handleSubmit = () => {
        if (!buttonActive) {
            return;
        }

        getAuth().onAuthStateChanged(function (user) {
            if (user) {
                user.getIdToken().then(function (data) {
                    postAdvisorDoc(data, {
                        user_id: userId,
                        uploaded_by: JSON.parse(userName).displayName,
                        financial_year_id: selectedFy.value,
                        name: documentObj.name,
                        description: documentObj.details,
                        doc_links: {
                            links: [documentObj.file],
                        },
                        type: documentObj.type,
                    })
                        .then((data) => {
                            let arr = docArr;
                            arr.push(data);
                            updateDocArr([...arr]);
                        })
                        .finally(() => {
                            handleCross();
                        });
                });
            }
        });
    };

    return (
        <UploadAdvDocFormStyled disable={buttonActive}>
            {" "}
            <span className="closePop" onClick={handleCross}>
                &times;
            </span>
            <div className="popContent">
                <div className="" style={{ display: "flex" }}>
                    <b className="popTitle"> Upload Document </b>
                </div>
                <div className="inputdiv">
                    <p className="label">Document Name :</p>
                    <input
                        type="text"
                        className="inputUploadText name"
                        placeholder="Enter Document Name"
                        name=""
                        id=""
                        value={documentObj.name}
                        onChange={(e) => {
                            setDocumentObj({
                                ...documentObj,
                                name: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="inputdiv">
                    <p className="label">Document details :</p>
                    <input
                        type="text"
                        className="inputUploadText details"
                        placeholder="Enter Document details"
                        name=""
                        id=""
                        value={documentObj.details}
                        onChange={(e) => {
                            setDocumentObj({
                                ...documentObj,
                                details: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="inputdiv">
                    <p className="label">Document type :</p>
                    <select
                        className="inputUploadText details"
                        name=""
                        id=""
                        value={documentObj.type}
                        onChange={(e) =>
                            setDocumentObj({
                                ...documentObj,
                                type: e.target.value,
                            })
                        }
                    >
                        <option value="COMPUTATION">Computation</option>
                        <option value="DECLARATION">Declaration</option>
                        <option value="SALARY_RESTRUCTURING">
                            Salary Restructring
                        </option>
                        <option value="OTHERS">Others</option>
                    </select>
                </div>
                <div className="inputdiv">
                    <p className="label">Financial Year :</p>
                    <select
                        className="inputUploadText details"
                        name=""
                        id=""
                        value={selectedFy.value}
                        onChange={(e) => {
                            handleFYChange(
                                FYOptions.find((d, i) => {
                                    return e.target.value === d.value;
                                })
                            );
                        }}
                    >
                        {FYOptions.map((data, i) => {
                            if (data.current) {
                                return (
                                    <option
                                        style={{
                                            backgroundColor: "#15BE53",
                                            color: "#fff",
                                        }}
                                        key={i}
                                        value={data.value}
                                    >
                                        {data.label} &nbsp; (Current)
                                    </option>
                                );
                            } else {
                                return (
                                    <option key={i} value={data.value}>
                                        {`${data.label}`}
                                    </option>
                                );
                            }
                        })}
                    </select>
                </div>

                <div className="inputdiv">
                    <p className="label">Document Upload :</p>
                    <UploadFile
                        fileId="thumbnail"
                        supersaver={true}
                        labelName="Upload Documemnt"
                        icon={
                            <FileUploadOutlinedIcon
                                style={{
                                    fontSize: 50,
                                    color: "#85BB65",
                                }}
                            />
                        }
                        uploadClassName="uploadAdvisorDoc"
                        uploaded={uploadImage}
                        uploadLink={originalLink}
                        onFileUpload={handleImageUpload}
                        cancelUpload={handleCancelUpload}
                    />
                </div>
                <div className="input">
                    <label className="button" onClick={handleSubmit}>
                        Submit
                    </label>
                </div>
            </div>{" "}
        </UploadAdvDocFormStyled>
    );
};

export default UploadAdvDocForm;
