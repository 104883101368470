import React, { useState, useEffect, useContext, useCallback } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Button from "../../commonComponents/Button";
import OnboardAuthorTagSelect from "../../components/OnboardAuthorCategory";
import UploadFile from "../../commonComponents/uploadFile";
import { getAuth } from "@firebase/auth";
import "./UpdateAdvisor.css";
import Alert from "../../commonComponents/Alert/Alert1";
import { useLocation } from "react-router";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import BASE_URL from "../../Services/API_ENDPOINT";
import { uploadFile } from "../../utils/uploadFileUtil";
import { between } from "../../utils/betweenFn";
import EducationInput from "../onboardAdvisor/components/EducationInput";
import {
  errorValidator,
  findUndefinedProperties,
} from "../ConsultationExperience/utils";
import { getAuthToken } from "../../Services/httpUtil";
import TimeUtil from "../../utils/timeUtils";

const initialEdu = {
  institute: "",
  course: "",
  start_year: undefined,
  end_year: undefined,
  isDelete: true,
};

const UpdateAdvisor = () => {
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
  const [getAdvisor, setgetAdvisor] = useState(null);
  const a = useContext(AdvisorContext);
  const [Id, setId] = useState(null);
  const [advisorInfo, setAdvisorInfo] = useState({
    work_experience: "",
    client_rating: "",
  });

  const [loader, setLoader] = useState(false);
  const [educationObj, setEducationObj] = useState([initialEdu]);
  const [errorStatus, setErrorStatus] = useState(false);
  const [IdAddress, setIdAddress] = useState(null);
  const [DisplayEmail, setDisplayEmail] = useState("none");
  const [ShowAlert, setShowAlert] = useState(null);
  const [SubmitMsg, setSubmitMsg] = useState(null);
  const [username, setUsername] = useState("");
  const [user_details, setUser_details] = useState({
    first_name: "",
    email: "",
    mobile: "",
    last_name: "",
    middle_name: "",
    image: "",
    id: "",
    external_id: "",
  });

  const [other_qualification, setOther_qualification] = useState("");
  const [otherQualification, setotherQualification] = useState([]);
  const [qualifications, setQualifications] = useState({
    primary_qualification: "",
    other_qualification: otherQualification,
  });

  const [experience, setExperience] = useState("");

  const [no_of_assets_manage, setNo_of_assets_manage] = useState("");

  const [specialization, setSpecialization] = useState([]);

  const [specialization_details, setSpecialization_details] = useState(null);

  const [title, setTitle] = useState("");

  const [SpecializationPut, setSpecializationPut] = useState(null);

  const [address_details, setAddress_details] = useState({});

  const [brief, setBrief] = useState("");
  const [social_links, setSocial_links] = useState({
    facebook: "",
    linkedin: "",
    instagram: "",
    twitter: "",
    youtube: "",
    personal_website: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUser_details({ ...user_details, [name]: value });
  };

  const handlePrimaryQual = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "other_qualification") {
      setOther_qualification(value);
    } else {
      setQualifications({ ...qualifications, [name]: value });
    }

    setotherQualification(other_qualification.split(","));
  };

  const handleYearExperience = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setExperience(value);
  };

  const handleAssetsManaged = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setNo_of_assets_manage(value);
  };
  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setAdvisorInfo((state) => {
      return { ...state, [name]: value };
    });
  };
  const handleCategory = (value) => {
    setSpecialization(value);
    const specializations = [];
    value.forEach((element) => {
      specializations.push({ specialization: element.label });
    });

    let spe = specializations.map((d) => d.specialization);
    setSpecializationPut(spe);
    setSpecialization_details(specializations);
  };

  const [first, SetFirst] = useState({
    loading: false,
    data: [],
  });

  useEffect(() => {
    const url = `${BASE_URL}specializations/v1/all`;

    const fetchData = async (token) => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${token}`,
            Source: "cms",
          },
        });
        const items = await response.json();
        SetFirst({
          loading: true,
          data: items,
        });
      } catch (error) {
        console.log("error", error);
      }
    };
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          fetchData(data);
        });
      }
    });
  }, []);

  let tags = first.data.map((d, i) => ({
    value: `${d.specialization}`,
    label: `${d.specialization}`,
  }));

  const handleTitle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTitle(value);
  };

  const handelBio = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBrief(value);
  };

  const handleSocialLinks = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSocial_links({ ...social_links, [name]: value });
  };

  const [uploadAvatar, setUploadAvatar] = useState(false);
  const [originalAvatarLink, setOriginalAvatarLink] = useState(null);

  const handleAvatarUpload = async (e) => {
    const imageFile = e.target.files[0];
    // const bucketName = "prosperr-images";
    // const bucketZone = "ap-south-1";
    const bucketName =
      ENVIRONMENT !== "PRODUCTION"
        ? "stage-prosperr-images"
        : "prosperr-images";
    //'prosperr-images'
    const bucketZone =
      ENVIRONMENT !== "PRODUCTION" ? "eu-west-1" : "ap-south-1";
    try {
      // USE PRESIGNED URL
      const imageUrl = await uploadFile(bucketName, bucketZone, imageFile);
      setUser_details({
        ...user_details,
        image: imageUrl,
      });
      setOriginalAvatarLink(imageUrl);
    } catch (e) {
      console.log(e);
    }
    setUploadAvatar(true);
  };

  const handleCancelAvatarUpload = () => {
    setUploadAvatar(null);
  };

  const validate = (type, value) => {
    const errors = { ...formErrors };
    const regex = {
      email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
      mobile: /^([+]\d{2})?\d{10}$/,
    };

    if (!value) {
      errors[type] = `${type} is required`;
    } else if (regex[type] && !regex[type].test(value)) {
      errors[type] = `Not a valid ${type}`;
    } else if (value.length < 2) {
      errors[type] = `${type} cannot be less than 2 characters`;
    } else {
      delete errors[type];
    }

    setFormErrors(errors);
  };
  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    validate(name, value);
  };
  // Update advisor function
  const handleUpdateAdvisor = async (e) => {
    setLoader(true);
    e.preventDefault();

    let data = {
      id: Id,
      user_name: username,
      user_details: user_details,
      qualifications: {
        primary_qualification: qualifications.primary_qualification,
        other_qualification: otherQualification,
      },
      ...advisorInfo,
      experience: experience,
      educations: educationObj.map((d) => {
        return {
          ...d,
          end_year: new Date(d.end_year).getFullYear(),
          start_year: new Date(d.start_year).getFullYear(),
        };
      }),
      no_of_assets_manage: no_of_assets_manage,
      specialization_details: SpecializationPut,

      title: title,
      address_details: {
        id: IdAddress,
        city: district,
        zip_code: pincode,
      },
      brief: brief,
      social_links: social_links,
    };
    getAuthToken().then(function (TOKEN) {
      fetch(`${BASE_URL}advisors/v1/update`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `${TOKEN}`,
          Source: "cms",
        },
        body: JSON.stringify(data),
      })
        .then((result) => {
          setLoader(false);
          if (Object.keys(formErrors).length === 0 && result.status === 204) {
            setSubmitMsg("success");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 1000);
          } else {
            setSubmitMsg("error");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 1000);
          }

          result.json().then((response) => {});
        })
        .catch((e) => {
          console.log(e);
          setLoader(false);
        });
    });
  };
  // cpstart
  const [data, setData] = useState({
    loading: true,
    value: "",
    type: "mobile",
  });
  const [Fetchdata, setFetchdata] = useState(null);
  const [LoadingForGetAdvisor, setLoadingForGetAdvisor] = useState(null);

  function updateValue(e) {
    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}    \.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    function validateOnlyNumbers(phone) {
      return phone.match(/^\d+$/);
    }

    if (validateOnlyNumbers(e.target.value)) {
      if (e.target.value.length === 10) {
        setData({
          loading: true,
          value: `${e.target.value}`,
          type: "mobile",
        });
      } else {
        setData({
          loading: true,
          value: `${e.target.value}`,
          type: "mobile",
        });
      }
    } else {
      if (validateEmail(e.target.value)) {
        setData({
          loading: true,
          value: `${e.target.value}`,
          type: "Email",
        });
        // console.log("Email ");
      } else {
        setData({
          loading: true,
          value: `${e.target.value}`,
          type: "Email",
        });
        // console.log("Email ");
        // setData({type:"Email"})
      }
    }
  }
  //   cpend

  const [pincode, setpincode] = useState("");
  const [city, setcity] = useState(" ");
  const [district, setdistrict] = useState(" ");
  const [stateName, setstateName] = useState(" ");
  const [areaName, setareaName] = useState(" ");
  const [error, seterror] = useState(true);

  const Change = (e) => {
    setpincode(e.target.value);
    if (e.target.value.length === 6) {
      seterror("");
      fetch(`https://api.postalpincode.in/pincode/${e.target.value}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res);

          setstateName(res[0].PostOffice[0].State);
          setcity(res[0].PostOffice[0].Block);
          setdistrict(res[0].PostOffice[0].District);
          setareaName(res[0].PostOffice[0].Name);
          setpincode(res[0].PostOffice[0].Pincode);
        })
        .then(() => {
          document.getElementById("pincode").classList.remove("error");
        })
        .catch((err) => {
          document.getElementById("pincode").className = "error";

          seterror("Invalid PIN Code");
        });
    }
    if (e.target.value.length !== 6) {
      //   this.setState({
      setcity("");
      setstateName("");
      setdistrict("");
      seterror("");
      //   });
    }
  };

  const inputFields = [
    {
      name: "City",
      placeholder: "City",
      disabled: true,
      value: city,
      display: true,
    },
    {
      name: "District",
      placeholder: "District",
      disabled: true,
      value: district,
      display: true,
    },
  ];

  let txt = "";
  const state = useLocation();
  const context = useContext(AdvisorContext);
  useEffect(() => {
    if (state.state.number !== undefined) {
      context.setUpdateAdv(false);

      setSpecialization_details(null);

      setUploadAvatar(true);
      const url = `${BASE_URL}advisors/v1/getBy?type=mobile&value=${state.state.number}`;
      if (true) {
        const fetchData = async (Token) => {
          try {
            const response = await fetch(url, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `${Token}`,
                Source: "cms",
              },
            });
            var data = await response.json();
            if (response.status === 200 || response.status === 204) {
              setDisplayEmail("none");
            } else {
              setDisplayEmail("block");
              setTimeout(() => setDisplayEmail("none"), 1000);
            }

            setFetchdata(data);
            setLoadingForGetAdvisor(true);
            setcity(city);
            setAdvisorInfo({
              work_experience: data?.work_experience || "",
              client_rating: data?.client_rating || "",
            });
            setEducationObj(
              data.educations.length > 0
                ? data.educations.map((d) => {
                    return {
                      ...d,
                      end_year: TimeUtil.yearToTimestamp(d.end_year),
                      start_year: TimeUtil.yearToTimestamp(d.start_year),
                    };
                  })
                : [initialEdu]
            );
            setpincode(data.address_details.zip_code);
            setareaName(data.address_details.city);
            setcity(data.address_details.city);
            setdistrict(data.address_details.city);
            setUsername(data.user_name);
            setgetAdvisor(true);

            const numbers = data.qualifications.other_qualification;
            numbers.forEach(myFunction);

            function myFunction(value, i) {
              txt += value;
              if (i < numbers.length - 1) {
                txt += ",";
              }
            }

            setUser_details({
              first_name: data.user_details.first_name,
              email: data.user_details.email,
              mobile: data.user_details.mobile,
              last_name: data.user_details.last_name,
              middle_name: data.user_details.middle_name,
              image: data.user_details.image,
              id: data.user_details.id,
              external_id: data.user_details.external_id,
            });
            setOriginalAvatarLink(data.user_details.image);

            setId(data.id);

            setIdAddress(data.address_details.id);
            setSocial_links({
              facebook: data.social_links.facebook,
              linkedin: data.social_links.linkedin,
              instagram: data.social_links.instagram,
              twitter: data.social_links.twitter,
              youtube: data.social_links.youtube,
              personal_website: data.social_links.personal_website,
            });
            setQualifications({
              primary_qualification: data.qualifications.primary_qualification,
              other_qualification: txt,
            });

            setExperience(data.experience);
            setTitle(data.title);
            setBrief(data.brief);
            setAddress_details({
              city: data.address_details.city,
              zip_code: data.address_details.zip_code,
            });

            setNo_of_assets_manage(data.no_of_assets_manage);
            setOther_qualification(txt);
            setotherQualification(numbers);
            setSpecialization(data.specializations);
            let xxx = data.specializations.map((d) => ({
              value: d,
              label: d,
            }));
            let xxx2 = data.specializations.map((d) => d);
            setSpecializationPut(xxx2);
            setSpecialization_details(xxx);
          } catch (error) {
            console.log("error", error);
          }
        };

        getAuth().onAuthStateChanged(function (user) {
          if (user) {
            user.getIdToken().then(function (data) {
              fetchData(data);
            });
          }
        });
      }
    }
  }, [context.UpdateAdv, state]);
  useEffect(() => {
    if (state !== null && data.value === state.number) {
      handleGetAdvisor();
      context.setUpdateAdv(false);
    }
  }, [state]);

  const handleGetAdvisor = (e) => {
    if (state !== null && data.loading) {
    } else {
      e.preventDefault();
    }

    setSpecialization_details(null);

    setUploadAvatar(true);
    const url = `${BASE_URL}advisors/v1/getBy?type=${data.type}&value=${data.value}`;
    if (data.loading) {
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `${a.Token}`,
              Source: "cms",
            },
          });
          var data = await response.json();
          if (response.status === 200 || response.status === 204) {
            setDisplayEmail("none");
          } else {
            setDisplayEmail("block");
            setTimeout(() => setDisplayEmail("none"), 1000);
          }
          setFetchdata(data);
          setLoadingForGetAdvisor(true);
          setcity(city);
          setpincode(data.address_details.zip_code);
          setareaName(data.address_details.city);
          setcity(data.address_details.city);
          setdistrict(data.address_details.District);

          setgetAdvisor(true);

          // set user details
          const numbers = data.qualifications.other_qualification;
          numbers.forEach(myFunction);

          function myFunction(value, i) {
            txt += value;
            if (i < numbers.length - 1) {
              txt += ",";
            }
          }
          setUser_details({
            first_name: data.user_details.first_name,
            email: data.user_details.email,
            mobile: data.user_details.mobile,
            last_name: data.user_details.last_name,
            middle_name: data.user_details.middle_name,
            image: data.user_details.image,
            id: data.user_details.id,
          });
          setOriginalAvatarLink(data.user_details.image);

          setId(data.id);
          setIdAddress(data.address_details.id);
          setSocial_links({
            facebook: data.social_links.facebook,
            linkedin: data.social_links.linkedin,
            instagram: data.social_links.instagram,
            twitter: data.social_links.twitter,
            youtube: data.social_links.youtube,
            personal_website: data.social_links.personal_website,
          });

          setQualifications({
            primary_qualification: data.qualifications.primary_qualification,
            other_qualification: txt,
          });

          setExperience(data.experience);
          setTitle(data.title);
          setBrief(data.brief);
          setAddress_details({
            city: data.address_details.city,
            zip_code: data.address_details.zip_code,
          });

          setNo_of_assets_manage(data.no_of_assets_manage);
          setOther_qualification(txt);
          setotherQualification(numbers);
          setSpecialization(data.specializations);
          let specializationsValues = data.specializations.map((d) => ({
            value: d,
            label: d,
          }));
          let specialization2 = data.specializations.map((d) => d);
          setSpecializationPut(specialization2);
          setSpecialization_details(specializationsValues);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }
  };
  const handleEducationChange = useCallback((e, id) => {
    const { name, value } = e.target;

    const arr = (educationObj) =>
      educationObj.map((d, i) => {
        if (i == id) {
          return { ...d, [name]: value };
        }
        return d;
      });
    setEducationObj((state) => arr(state));
  }, []);
  const handleAdd = () => {
    setEducationObj((state) => [...state, initialEdu]);
  };
  const handleDelete = (id) => {
    setEducationObj((state) => state.filter((d, i) => i !== id));
  };
  const checkEducationObj = () => {
    const educationObj2 = educationObj.map((d, i) => {
      const data = {
        ...d,
        start_year: isNaN(new Date(d.start_year).getTime())
          ? d.start_year
          : new Date(d.start_year).getTime(),
        end_year: isNaN(new Date(d.end_year).getTime())
          ? d.end_year
          : new Date(d.end_year).getTime(),
      };
      const data2 = errorValidator(findUndefinedProperties(data));

      return { ...d, ...data2 };
    });
    setEducationObj(educationObj2);
  };
  return (
    <div className="form_container">
      <h2>Update Advisor</h2>

      {getAdvisor ? (
        <div className="box">
          <p>Upload Profile</p>
          <UploadFile
            fileId="thumbnail"
            acceptFileType="image/*"
            labelName=""
            icon={
              <AccountCircleIcon
                style={{
                  fontSize: 80,
                }}
              />
            }
            uploadClassName="avatarUpload"
            uploaded={uploadAvatar}
            uploadLink={originalAvatarLink}
            onFileUpload={handleAvatarUpload}
            cancelUpload={handleCancelAvatarUpload}
          />
          <div className="label">
            <div className="side">First Name:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="first_name"
              name="first_name"
              placeholder="*First Name"
              required
              value={user_details.first_name}
              onWheel={(e) => e.target.blur()}
              onChange={handleInput}
              onBlur={handleOnBlur}
            />
          </div>
          {!formErrors.first_name ? null : (
            <p className="error_p">{formErrors.first_name}</p>
          )}

          <div className="label">
            <div className="side">Middle Name:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              name="middle_name"
              id="middle_name"
              placeholder="Middle Name"
              value={user_details.middle_name}
              onChange={handleInput}
              // onBlur={handleOnBlur}
            />
          </div>
          {/* {!formErrors.middle_name ? null : <p>{formErrors.middle_name}</p>} */}

          <div className="label">
            <div className="side">Last Name:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="last_name"
              name="last_name"
              placeholder="*Last Name"
              value={user_details.last_name}
              onChange={handleInput}
              onBlur={handleOnBlur}
            />
          </div>
          {/* <p>{formErrors.lastname}</p> */}
          {!formErrors.last_name ? null : (
            <p className="error_p">{formErrors.last_name}</p>
          )}

          <div className="label">
            <div className="side">Email:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="email"
              id="email"
              name="email"
              placeholder="*Email"
              // readOnly
              value={user_details.email}
              onChange={handleInput}
              onWheel={(e) => e.target.blur()}
              // onBlur={handleOnBlur}
            />
          </div>
          {/* <p>{formErrors.email}</p> */}
          {!formErrors.email ? null : (
            <p className="error_p">{formErrors.email}</p>
          )}

          <div className="label">
            <div className="side">Phone No.:&nbsp;&nbsp;</div>
            <div className="phone">
              <select name="country" id="country">
                <option value="+91">+91</option>
                <option value="+1">+1</option>
                <option value="+43">+43</option>
                <option value="1-684">1-684</option>
              </select>

              <input
                autoComplete="off"
                type="number"
                id="phone"
                name="mobile"
                placeholder="Phone mobile"
                onWheel={(e) => e.target.blur()}
                value={user_details.mobile}
                onChange={handleInput}
                onBlur={handleOnBlur}
              />
            </div>

            {!formErrors.mobile ? null : (
              <p className="error_p">{formErrors.mobile}</p>
            )}
          </div>

          <div className="label">
            <div className="side">Primary Qualification:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="primary_qualification"
              name="primary_qualification"
              placeholder="Primary Qualification"
              value={qualifications.primary_qualification}
              onChange={handlePrimaryQual}
            />
          </div>

          <div className="label">
            <div className="side">Other Qualification:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="other_qualification"
              name="other_qualification"
              placeholder="Other Qualification"
              value={other_qualification}
              onChange={handlePrimaryQual}
              onBlur={handlePrimaryQual}
            />
          </div>

          <div className="label">
            <div className="side">client rating (0-5): &nbsp;&nbsp;</div>
            <input
              type="number"
              min={0}
              max={5}
              className="inputTag"
              name="client_rating"
              placeholder="client rating"
              value={advisorInfo?.client_rating}
              onWheel={(e) => e.target.blur()}
              onChange={handleInfoChange}
              onBlur={() => {
                setErrorStatus(true);
              }}
              autoComplete="off"
            />
            {errorStatus &&
              (advisorInfo?.client_rating.length < 1 ||
                !between(advisorInfo?.client_rating, 0, 5)) && (
                <p className="error">
                  Rating should be between 0 to 5 <sup>*</sup>{" "}
                </p>
              )}
          </div>
          <div className="label">
            <div className="side">work experience: &nbsp;&nbsp;</div>
            {/* <input
            type="text"
            className="inputTag"
            name="work_experience"
            placeholder="work experience"
            value={advisorInfo?.work_experience}
            onWheel={(e) => e.target.blur()}
            onChange={handleInfoChange}
            autoComplete="off"
          /> */}
            <textarea
              type="text"
              className="inputTag"
              name="work_experience"
              placeholder="work experience"
              value={advisorInfo?.work_experience}
              onWheel={(e) => e.target.blur()}
              onChange={handleInfoChange}
              autoComplete="off"
            />
            {errorStatus && advisorInfo?.work_experience.length < 1 && (
              <p className="error">
                please fill the work experience <sup>*</sup>
              </p>
            )}
          </div>

          <div className="label">
            <div className="side">Years of experience:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="yearofexperience"
              name="yearofexperience"
              placeholder="Years of experience"
              onWheel={(e) => e.target.blur()}
              value={experience}
              onChange={handleYearExperience}
            />
          </div>
          <div className="label">
            <div className="side">Education:&nbsp;&nbsp;</div>
            {educationObj.map((d, i) => {
              return (
                <EducationInput
                  {...d}
                  handleChange={handleEducationChange}
                  handleAdd={handleAdd}
                  handleDelete={handleDelete}
                  id={i}
                  length={educationObj.length}
                />
              );
            })}
          </div>

          <div className="label">
            <div className="side">No. Clients served:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="number"
              id="assetsmanaged"
              name="assetsmanaged"
              placeholder="Number of Clients served"
              value={no_of_assets_manage}
              onChange={handleAssetsManaged}
              onWheel={(e) => e.target.blur()}
            />
          </div>

          <div className="label" style={{ maxWidth: "600px" }}>
            <div
              className="side"
              style={{
                paddingBottom: "10px",
              }}
            >
              Specialisation / Domain:&nbsp;&nbsp;
            </div>
            <OnboardAuthorTagSelect
              className="categoryTag"
              tags={tags}
              placeholder="Specialisation / Domain"
              id="specialization"
              name="specialization"
              value={specialization}
              handleInput={handleCategory}
              // handleOnBlur={handleOnBlur}
              defaultValue={specialization_details}
              style={{
                height: "auto",
              }}
            />
          </div>

          <Alert SubmitMsg={SubmitMsg} ShowAlert={ShowAlert} />
          <div className="label">
            <div className="side">Title:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="title"
              name="title"
              placeholder="Your Title"
              value={title}
              onChange={handleTitle}
            />
          </div>
          <div className="label">
            <div className="side">Enter Pincode to get city:&nbsp;&nbsp;</div>

            <div>
              <div>
                {error ? <span className="error-display">{error}</span> : null}

                <div>
                  <input
                    autoComplete="off"
                    maxLength={6}
                    minLength={6}
                    // onChange={(e) => onsChange(e)}
                    name="pincode"
                    placeholder="Pin Code"
                    value={pincode}
                    id="zip_code"
                    type="number"
                    onInput={(e) => {
                      Change(e);
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                {inputFields.map((field) => {
                  return (
                    field.display && (
                      <div key={field.name}>
                        <input
                          autoComplete="off"
                          //   type="String"
                          id="zip_code"
                          disabled={field.disabled}
                          placeholder={field.placeholder}
                          onWheel={(e) => e.target.blur()}
                          value={field.value}
                          //   style={field.style}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>

          <div className="label">
            <div className="side">Breif detals:&nbsp;&nbsp;</div>
            <textarea
              type="text"
              id="bio"
              name="brief"
              placeholder="Breif detals (In about 60-100 words)"
              value={brief}
              onChange={handelBio}
            />
          </div>
          <div className="label">
            <div className="side">Facebook Handle:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="social_links"
              name="facebook"
              placeholder="Your Facebook Handle"
              value={social_links.facebook}
              onChange={handleSocialLinks}
            />
          </div>

          <div className="label">
            <div className="side">Linkedin Handle:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="social_links"
              name="linkedin"
              placeholder="Your Linkedin Handle"
              value={social_links.linkedin}
              onChange={handleSocialLinks}
            />
          </div>

          <div className="label">
            <div className="side">Instagram Handle:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="social_links"
              name="instagram"
              placeholder="Your Instagram Handle"
              value={social_links.instagram}
              onChange={handleSocialLinks}
            />
          </div>

          <div className="label">
            <div className="side">Twitter Handle:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="social_links"
              name="twitter"
              placeholder="Your Twitter Handle"
              value={social_links.twitter}
              onChange={handleSocialLinks}
            />
          </div>

          <div className="label">
            <div className="side">Youtube Handle:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="text"
              id="social_links"
              name="youtube"
              placeholder="Your Youtube Handle"
              value={social_links.youtube}
              onChange={handleSocialLinks}
            />
          </div>

          <div className="label">
            <div className="side">Your Website:&nbsp;&nbsp;</div>
            <input
              autoComplete="off"
              type="number"
              id="social_links"
              name="personal_website"
              placeholder="Your Website"
              onWheel={(e) => e.target.blur()}
              value={social_links.personal_website}
              onChange={handleSocialLinks}
              pattern="/^([+]\d{2})?\d{10}"
            />
          </div>

          {
            <Button
              buttonClassName={
                user_details.first_name !== "" &&
                user_details.email !== "" &&
                user_details.last_name !== "" &&
                Object.keys(formErrors).length === 0
                  ? "buttonOnboardAuthor"
                  : "btnhide"
              }
              onButtonClick={loader ? () => {} : handleUpdateAdvisor}
              buttonName="Submit"
              // disable={!isSubmit}
            />
          }
        </div>
      ) : null}
    </div>
  );
};

export default UpdateAdvisor;
