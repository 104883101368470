import React from 'react'
import { Route, Routes } from 'react-router'
import RentAgreement from '../../../container/RentAgreement'
import RentDetails from '../../../container/RentAgreement/common-components/RentDetails'
import OnboardAuthor from '../../../container/onboardAuthor'
import OnboardAdvisor from '../../../container/onboardAdvisor'
import UpdateAdvisor from '../../../container/UpdateAdvisor/UpdateAdvisor'
import Addslot from '../../../container/AddSlot/Addslot'
import ManageAppointment from '../../../container/ManageAppointment'
import SavedArticle from '../../../container/savedArticles'
import SuperClientProfile from '../../../container/SuperClientProfile'
import SuperSaverUserProfile from '../../../container/SuperSaverUserProfile'
import PendingAdvisorDoc from '../../../commonComponents/PendingAdvisorDoc'
import ExemptionPage from '../../../container/AssignedUsers/components/ExemptionPage'
import SuperSaverBooking from '../../../container/SuperSaverBooking'
import SuperSaverUser from '../../../container/SuperSaverUser'
import TaskManager from '../../../container/TaskManager'
import DeductionView from '../../../components/DeductionView'
import IncomeRoot from '../../../container/IncomeRoot'
import IncomeNew from '../../../container/IncomeNew'
import BusinessRentCryptoDetails from '../../../container/IncomeNew/Component/BusinessRentCryptoDetails'
import SalaryDetails from '../../../container/IncomeNew/Screens/SalaryDetails'
import ActiveChats from '../../../container/ActiveChats'
import OnboardingData from '../../../container/AssignedUsers/components/OnboardingData'
import ITRDocuments from '../../../container/ITRDocuments'
import BasicChatDashboard from '../../../container/BasicChatDashboard'
import ConsultationExperienceForm from '../../../container/ConsultationExperience/Screens/ConsultationExperienceForm'
import PayslipManualReview from '../../../container/PayslipManualReview'
import PayslipReview from '../../../container/PayslipManualReview/Screens/PayslipReview'
import ITRFilingUsers from '../../../container/ITRFilingUsers'
import ConsultationExperience from '../../../container/ConsultationExperience'
import ArticleEditor from '../../../container/ArticleEditor'
import Articles from '../../../container/articles'
import CsrAssignment from '../../../container/CsrAssignment'
import PromoImageLinkGenerator from '../../../container/PromoImageLinkGenerator'

const AdminRoutes = () => {
  return (
    <Routes>
    <Route
      path="/rent-agreement"
      element={<RentAgreement pageName="ImageUpload" />}
    />
    <Route
      path="/rent-agreement/details"
      element={<RentDetails pageName="ImageUpload" />}
    />
    <Route path="/onboardAuthor" element={<OnboardAuthor />} />
    <Route path="/onboard-advisors" element={<OnboardAdvisor />} />
    <Route path="/updateAdvisor" element={<UpdateAdvisor />} />
    <Route path="/manage-advisors" element={<Addslot />} />
    <Route path="/manage-appointment" element={<ManageAppointment />} />
    <Route path="/savedArticle" element={<SavedArticle />} />
    <Route
      path="/super-saver-bookings/users"
      element={<SuperClientProfile />}
    />
    <Route
      path="/superSaverUserProfile"
      element={<SuperSaverUserProfile />}
    />
    <Route
      path="/superSaverUserProfile/pending-advisorDoc"
      element={<PendingAdvisorDoc type={"operation"} />}
    />
    <Route
      path="/superSaverUserProfile/exemption-page/:userId"
      element={<ExemptionPage type={"operation"} />}
    />
    <Route
      path="/superSaverUserProfile/exemption-page"
      element={<ExemptionPage type={"operation"} />}
    />
    <Route
      path="/userProfileForAdvisor/exemption-page"
      element={<ExemptionPage type={"operation"} />}
    />
    <Route path="/super-saver-bookings" element={<SuperSaverBooking />} />
    <Route path="/super-saver-users" element={<SuperSaverUser />} />
    <Route path="/" element={<SuperSaverUser />} />
    <Route path="/:id" element={<SuperSaverUser />} />
    <Route path="/task-manager" element={<TaskManager />} />
    <Route path="/assign-csr" element={<CsrAssignment/>} />
    <Route
      path="/superSaverUserProfile/deductionView"
      element={<DeductionView />}
    />
    <Route
      path="/superSaverUserProfile/incomeView"
      element={<IncomeRoot />}
    />
    <Route
      path="/superSaverUserProfile/incomeView/:id/:userId"
      element={<IncomeNew />}
    />

    <Route
      path="/superSaverUserProfile/incomeView/capital-gain/detail"
      element={<BusinessRentCryptoDetails />}
    />
    <Route
      path="/superSaverUserProfile/incomeView/business-and-profession/detail"
      element={<BusinessRentCryptoDetails />}
    />
    <Route
      path="/superSaverUserProfile/incomeView/digital-asset/detail"
      element={<BusinessRentCryptoDetails />}
    />
    <Route
      path="/superSaverUserProfile/incomeView/income-from-house-rent/detail"
      element={<BusinessRentCryptoDetails />}
    />

    <Route
      path="/superSaverUserProfile/incomeView/salary-breakdown"
      element={<SalaryDetails />}
    />
    <Route path="/dashboard/advisor-chat/:id" element={<ActiveChats />} />

    <Route
      path="/super-saver-user-profile/onboarding-form"
      element={<OnboardingData />}
    />
    <Route
      path="/super-saver-user-profile/itr-filing"
      element={<ITRDocuments />}
    />
    <Route path="/dashboard" element={<BasicChatDashboard />} />
    <Route
      path="/consultation-experience/consultation-form"
      element={<ConsultationExperienceForm />}
    />
    <Route
      path="/consultation-experience"
      element={<ConsultationExperience />}
    />
    <Route
      path="/payslip-manual-review"
      element={<PayslipManualReview />}
    />
    <Route
      path="/payslip-manual-review/preview"
      element={<PayslipReview />}
    />
    <Route path="/itr-filing-users" element={<ITRFilingUsers />} />
    <Route path="/itr-documents" element={<ITRDocuments />} />

    <Route path="/editor" element={<ArticleEditor />} />
    <Route path="/articles/editor/:id" element={<ArticleEditor />} />
    <Route path="/articles/:id" element={<Articles />} />
    <Route
      path={'articles/generate-image-link'}
      element={<PromoImageLinkGenerator/>}>
    </Route>

  </Routes>
  )
}

export default AdminRoutes