import styled from "styled-components";
import { dim } from "../../../styles/theme";

export const GridContainer = styled.div`
  width: ${({ width }) => (width ? width : "100%")};

  .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    place-items: center;
    row-gap: 10px;
    column-gap: 10px;
    padding: ${dim._scale(16)};
  }

  @media (max-height: 700px) {
    .grid-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: ${dim._scale(20)};
      padding: ${dim._scale(10)};
    }

    & > * {
      width: 50%;
    }
  }
`;

export const LoaderContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${dim._scale(100)};
`;