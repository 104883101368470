import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { dim, theme } from '../../styles/theme';

const DropzoneWrapper = ({ handleChangeAttachment, children, id='' }) => {
	const onDrop = useCallback((acceptedFiles) => {
		handleChangeAttachment(acceptedFiles);
	});

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		noClick: true
	});

	const baseStyle = {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		position: 'relative',
		backgroundColor: `${theme.color.white_0}`,
		boxSizing: 'border-box',
	};

	const dropzoneLayoutStyle = {
		position: 'absolute',
		zIndex: 11,
		width: '100%',
		height: '100%',
		backgroundColor: `${theme.color.grey_150}`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: `${theme.color.green_20}`,
		fontSize: `${dim._20px}`,
		fontWeight: 'bold',
		textAlign: 'center',
		border: `${dim._2px} dashed ${theme.color.green_20}`,
		borderRadius: `${dim._10px}`,
		boxSizing: 'border-box',
	};

	return (
		<div {...getRootProps({ style: baseStyle, id})}>
			<input {...getInputProps()} />
			{isDragActive && <div style={dropzoneLayoutStyle}>Drop files here </div>}
			{children}
		</div>
	);
};

export default DropzoneWrapper;
