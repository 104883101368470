import { put, takeEvery } from "redux-saga/effects";
import toast from "react-hot-toast";
import { actions } from "./ConsultationAction";
import { ConsultationMomService } from "../../../Services/ConsultationMomService";
import { modifyConsultationData } from "../utils";
import { dateFormatter } from "../../../utils/timeUtils";

function* handleConsultationStatus(req) {
  const toastId = toast.loading("Loading...");
  try {
    const requester_id = req.data.requester_id;
    const res = yield ConsultationMomService.updateConsultationStatus(
      requester_id,
      req.data
    );
    const userId = req.data.userId;
    yield put({
      type: actions.GET_CONSULTATION_DATA,
      data: {
        userId,
      },
    });
    toast.dismiss(toastId);
    toast.success(
      `Consultation successfully canceled of Date ${dateFormatter(
        req.data.start_time
      )}, `,
      {
        style: {
          flexWrap: "normal",
        },
        className: "cancelCallSuccess",
      }
    );
  } catch (error) {
    toast.dismiss(toastId);
    toast.error(
      error ? error.response.data.message : "Oops Something went wrong"
    );
  }
}
function* handleActionableStatus(req) {
  const toastId = toast.loading("Loading...");
  try {
    const user_id = req.data.userId;
    const res = yield ConsultationMomService.updateActionableStatus(
      user_id,
      req.data
    );
    yield put({
      type: actions.GET_MOM_DATA,
      data: {
        userId: user_id,
        ...req.data,
      },
    });
    // yield req.data.getConsultationMom();
    toast.dismiss(toastId);
    toast.success(`Action Updated Successfully`, {});
  } catch (error) {
    toast.dismiss(toastId);
    toast.error(
      error ? error.response.data.message : "Oops Something went wrong"
    );
  }
}
function* getConsultationData(req) {
  try {
    const userId = req.data.userId;
    const data = JSON.parse(localStorage.getItem("type"));

    const { user_roll_id: userRollId, user_type: userType } = data;
    const res = yield ConsultationMomService.getConsultationList(
      userId,
      userType !== "OPERATION" ? userRollId : null
    );
    const data2 = modifyConsultationData(res?.data);
    yield put({ type: actions.SET_CONSULTATION_DATA, data: data2 });
  } catch (error) {
    toast.error(
      error ? error.response.data.message : "Oops Something went wrong"
    );
  }
}
function* getMomData(req) {
  try {
    // const userId = req.data.userId;
    const call_summary_id = req.data.call_summary_id;
    const res = yield ConsultationMomService.getConsultationMom(
      call_summary_id
    );

    const data2 = res?.data;
    // yield put({ type: actions.SET_MOM_DATA, data: null });
    yield put({ type: actions.SET_MOM_DATA, data: data2 });
  } catch (error) {
    toast.error(
      error
        ? error.response?.data?.message
          ? error.response.data.message
          : error
        : "Oops Something went wrong"
    );
  }
}

function* watchGetAllConsultationData() {
  yield takeEvery(actions.HANDLE_CONSULTATION_STATUS, handleConsultationStatus);
  yield takeEvery(actions.GET_CONSULTATION_DATA, getConsultationData);
  yield takeEvery(actions.HANDLE_ACTIONABLE_STATUS, handleActionableStatus);
  yield takeEvery(actions.GET_MOM_DATA, getMomData);
}

export { watchGetAllConsultationData };
