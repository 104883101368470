import React, { useEffect, useState } from "react";
import { Collapse, Button,Radio } from "antd";
import { StyledCsrTaskCreator } from "./StyledCsrTaskCreator";
import TaskGrid from "./TaskGrid";
import { TASK_STATUS } from "./constant";
import CsrTaskCreationPopUp from "./CsrTaskCreationPopUp";
import { USER_TYPE } from "../../utils/localStorageKeys";
import { CsrTaskService } from "../../Services/CsrTaskService";
import { USER_ROLE } from "../../constants/userRole";
import toast from "react-hot-toast/headless";
const { Panel } = Collapse;

const CsrTaskCreator = ({ userId, width }) => {
  const [taskState, setTaskState] = useState(TASK_STATUS.ACTIVE);
  const [isCreateTaskVisible, setIsCreateTaskVisible] = useState(false);
  const [taskData,setTaskData] = useState([]);
  const [loading,setLoading] = useState(false);
  const userType = JSON.parse(localStorage.getItem(USER_TYPE));
  const userRole = userType?.user_roll;


  const handleTaskStateChange = (value) => {
    setTaskState(value); 
  };
  const getTaskListFromBackend = async () => {
    const params = {
      status: taskState,
      userId: userId,
    };
    try{
      setLoading(true);
      const taskListFromBackend = await CsrTaskService.getCsrTaskByUserId(params);
      setTaskData([...taskListFromBackend?.data])
    }
    catch(e){
      toast.error("Something went wrong")
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaskListFromBackend();
  }, [taskState]);

  const handleCreateNewTask = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsCreateTaskVisible(true);
  };

  const Header = () => (
    <div className="header-container">
      <div className="heading-items">
      <span className="csr-heading">CSR Tasks</span>
      {userRole === USER_ROLE.ADVISOR && (
          <Button
            onClick={(e) => handleCreateNewTask(e)}
            color="primary"
            variant="outlined"
            className="create-btn"
          >
            Create new Task
          </Button>
        )}
      </div>
      

      <div className="header-buttons">
        <Radio.Group
        onChange={(e) => handleTaskStateChange(e.target.value)}
        value={taskState}
        buttonStyle="solid"
      >
        <Radio.Button value={TASK_STATUS.ACTIVE}>Pending</Radio.Button>
        <Radio.Button value={TASK_STATUS.COMPLETED}>Completed</Radio.Button>
      </Radio.Group>
      </div>
    </div>
  );
  
  if(userRole === USER_ROLE.ACCOUNTANT || userRole === USER_ROLE.AUTHOR){
      return null;
  }

  return (
    <StyledCsrTaskCreator>
      <Collapse defaultActiveKey={["1"]} style={{ maxHeight: "100%" }}>
        <Panel header={<Header />} key={1}>
          <div className="scrollable-content">
            {isCreateTaskVisible && (
              <CsrTaskCreationPopUp
                userId={userId}
                isModalVisible={isCreateTaskVisible}
                setIsModalVisible={setIsCreateTaskVisible}
                onTaskCreated={() => {getTaskListFromBackend()}}
              />
            )}

            <TaskGrid tasks={taskData} userRole={userRole} loading={loading} width={width} />
          </div>
        </Panel>
      </Collapse>
    </StyledCsrTaskCreator>
  );
};
export default CsrTaskCreator;
