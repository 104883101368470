import { TextField } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import FlexRow from "../../commonComponents/FlexRow/FlexRow";
import { SuperSaverUserProfileApi } from "../../Services/SuperSaverUserProfileService";
import Select from "react-select";
import toast from "react-hot-toast";
import { Spin } from "antd";
import { dim, theme } from "../../styles/theme";

const PopupCoinModification = ({
  handleCross,
  userId,
  setCoinBalance,
  setShowCoinModification,
}) => {
  const [coin, setCoin] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [loading, setLoading] = useState(false);

  const dropdownOptions = [
    {
      label: "Add",
      value: "CREDIT",
    },
    {
      label: "Deduct",
      value: "DEBIT",
    },
  ];

  const handleModifyCoin = async () => {
    try {
      setLoading(true);
      const response = await SuperSaverUserProfileApi.modifyUserCoin(
        coin,
        userId,
        selectedAction?.value
      );
      toast.success("User coin modified successfully");
      response?.status === 200 && setCoinBalance(response?.data?.total_balance);
      setShowCoinModification(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to modify user coin";
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <StyledPopupCoinModification
      disabled={coin && selectedAction ? false : true}
    >
      <Spin spinning={loading}>
        <span className="closePop" onClick={() => handleCross()}>
          &times;
        </span>
        <div className="popContent">
          <b>Are you sure you want to modify user coin balance?</b>
        </div>

        <div className="dropdown">
          <Select
            options={dropdownOptions}
            placeholder={"Select Action"}
            value={selectedAction}
            onChange={(value) => {
              setSelectedAction(value);
            }}
          />
        </div>
        <div className="input">
          <TextField
            className="standard-basic"
            label="Enter the coins"
            type="number"
            variant="standard"
            value={coin}
            onChange={(e) => {
              let value = e.target.value;
              if (value < 0) value *= -1;
              setCoin(value);
            }}
          />
        </div>
        <div className="popBox">
          <div>
            <FlexRow>
              <span
                className={`popButton yes ${
                  coin && selectedAction ? "enabled" : ""
                }`}
                onClick={() => {
                  coin && selectedAction && handleModifyCoin();
                }}
              >
                Yes
              </span>
              <span className="popButton no" onClick={() => handleCross()}>
                No
              </span>
            </FlexRow>
          </div>
        </div>
      </Spin>
    </StyledPopupCoinModification>
  );
};

export default PopupCoinModification;

const StyledPopupCoinModification = styled.div`
  background: ${theme.color.white_0};
  margin: auto;
  height: auto;
  border-radius: ${dim._8px};
  width: ${dim._scale(400)};
  position: relative;
  box-sizing: border-box;
  .popContent {
    font-size: ${dim._20px};
    margin: ${dim._0px} ${dim._30px} ${dim._0px} ${dim._30px};
    color: ${theme.color.grey_100};
    text-align: center;
    padding-top: ${dim._30px};
    padding-bottom: ${dim._30px};
  }
  .dropdown {
    box-sizing: border-box;
    width: 80%;
    margin: 0 auto;
  }
  .popBox {
    background-color: ${theme.color.white_10};
    border-radius: ${dim._0px} ${dim._0px} ${dim._8px} ${dim._8px};
  }
  .popButton {
    width: 50%;
    height: ${dim._50px};
    border-radius: ${dim._0px} ${dim._0px} ${dim._0px} ${dim._8px};
    text-align: center;
    padding-top: ${dim._14px};
    font-size: ${dim._18px};
    box-sizing: border-box;
  }
  .yes {
    color: ${theme.color.white_0};
    background: ${(props) => (!props.disabled ? `${theme.color.blue_10}` : `${theme.color.blue_100}`)};
    cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  }
  .no {
    border-radius: ${dim._0px} ${dim._0px} ${dim._8px} ${dim._0px};
    border-top: 1px solid ${theme.color.grey_120};
    cursor: pointer;
  }
  .closePop {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: ${dim._2px} ${dim._4px};
    line-height: ${dim._20px};
    right: -${dim._10px};
    top: -${dim._10px};
    font-size: ${dim._24px};
    background: ${theme.color.white_0};
    border-radius: ${dim._18px};
  }
  .input {
    box-sizing: border-box;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: ${dim._30px};
  }
  .standard-basic {
    margin: ${dim._10px} ${dim._4px};
  }
`;
