import { InputLabel } from "@mui/material";
import React from "react";
import Select from "react-select";
import styled from "styled-components";
import { dim } from "../../../../styles/theme";

const CustomDropdown = ({ heading = "", options, handleSelect, value, noOptionsMessage=()=>"No Option" }) => {
  return (
    <StyledCustomDropdown>
      <InputLabel>
        {heading}
      </InputLabel>
      <Select 
        options={options} 
        onChange={handleSelect}
        value={value}
        noOptionsMessage={noOptionsMessage}
      />
    </StyledCustomDropdown>
  );
};

export default CustomDropdown;

const StyledCustomDropdown = styled.span`
  width: ${dim._scale(250)};
`;
