import React, { useEffect, useState } from "react";
import { StyledManualUpdatePayslip } from "./ManualUpdatePayslip.styled";
import ReadPayslip from "../ReadPayslip";
import toast from "react-hot-toast";
import Select from "react-select";
import { Spin, Tooltip } from "antd";
import Button from "../../../../commonComponents/Button";
import {
  useDataToUpdateSalaryComponents,
  useDataToUpdateSalaryComponentsUpdateDeduction,
  useDataToUpdateSalaryComponentsUpdateEarning,
} from "../../Hooks/useDataToUpdateSalaryComponents";
import { PayslipRead } from "../../../../Services/PayslipReadApi";
import OutlinedCheckbox from "../../../../commonComponents/OutlinedCheckbox";
import { dim } from "../../../../styles/theme";

const initialDeductionSalaryComponent = [
  {
    label: "Provident fund",
    type: "PROVIDENT_FUND",
    amount: 0,
    editStatus: false,
    isEditable: false,
    manualLabel: "",
  },
  {
    label: "Income tax",
    type: "INCOME_TAX",
    amount: 0,
    editStatus: false,
    isEditable: false,
    manualLabel: "",
  },
];

const initialEarningSalaryComponent = [
  {
    label: "Basic salary",
    type: "BASIC_SALARY",
    amount: 0,
    editStatus: false,
    isEditable: false,
    manualLabel: "",
  },
  {
    label: "House rent allowance",
    type: "HOUSE_RENT_ALLOWANCE",
    amount: 0,
    editStatus: false,
    isEditable: false,
    manualLabel: "",
  },
  {
    label: "Variable pay",
    type: "VARIABLE_PAY",
    amount: 0,
    editStatus: false,
    isEditable: true,
    manualLabel: "",
  },

  {
    label: "Special allowance",
    type: "SPECIAL_ALLOWANCE",
    amount: 0,
    editStatus: false,
    isEditable: true,
    manualLabel: "",
  },
];

const ManualUpdatePayslip = ({
  isOpen,
  handleClose,
  companyName,
  month,
  externalId,
  loading,
  setUploadMonth,
  nonVerifiedPayslipMonths,
  systemGeneratedAmount,
  update = false,
  EarningComponent,
  viewMode,
  DeductionComponent,
  hasAlreadyApprovedPayslipInMonth,
  onSubmit = (data) => {},submit
}) => {
  const [earningComponent, setEarningComponent] = useState(
    initialEarningSalaryComponent
  );
  const [componentsConfirmed, setComponentsConfirmed] = useState(false);
  const [deductionComponent, setDeductionComponent] = useState(
    initialDeductionSalaryComponent
  );

  const [totalEarning, setTotalEarning] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);

  const filterEmptyArr = (arr) => {
    return arr.filter((d) => {
      return d.label.length > 0 ;
    });
  };

  const handleSubmit = async (e) => {
    const data = {
      external_id: externalId,
      // financial_year_id: localStorage.getItem(
      // 	LOCAL_STORAGE_KEYS.FINANCIAL_YEAR_ID
      // ),
      monthly_breakdown: {
        [month]: {
          system_generated_amount: systemGeneratedAmount,
          approved_salary: totalEarning,
          is_approved: true,
          approved_source: "USER",
          is_editable: true,
          salary_slip: "",
          extra_pay: 0,
          is_salary_slip_read: false,
          salary_component: [
            {
              credit: [...filterEmptyArr(earningComponent)],
            },
            {
              debit: [...filterEmptyArr(deductionComponent)],
            },
          ],
          salary_income_id: externalId,
        },
      },
    };

    try {
      const MonthSalaryBreakdown = await PayslipRead.postPayslipManualReview(
        data
      );

      onSubmit(MonthSalaryBreakdown);
      setEarningComponent(initialEarningSalaryComponent);
      setDeductionComponent(initialDeductionSalaryComponent);
      handleClose(e);
    } catch (error) {
      toast.error(
        error && error.response && error.response.data
          ? error.response.data.message
          : "Oops Something went wrong"
      );
    }
  };

  useDataToUpdateSalaryComponentsUpdateDeduction(
    DeductionComponent,
    setDeductionComponent
  );
  useDataToUpdateSalaryComponentsUpdateEarning(
    EarningComponent,
    setEarningComponent
  );

  useEffect(() => {
    if (isOpen && !update) {
      setEarningComponent([...initialEarningSalaryComponent]);

      setDeductionComponent([...initialDeductionSalaryComponent]);
    }
  }, [isOpen]);

  const dropdownStyle = {
    width: `${dim._scale(160)}`
  };

  const buttonStyling = {
    width: "86px",
    height: "34px",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
  };

  const isEnabled =
    filterEmptyArr(earningComponent).length > 0 &&
    filterEmptyArr(deductionComponent).length > 0 &&
    componentsConfirmed;

  const dynamicStyling = {
    opacity: isEnabled ? "1" : "0.5",
    cursor: isEnabled ? "pointer" : "not-allowed",
  };

  useEffect(() => {
    let total_earning = 0;

    earningComponent.map((d, i) => {
      total_earning = total_earning + Number(d.amount);

      return d;
    });

    setTotalEarning(!isNaN(total_earning) ? total_earning : 0);
  }, [earningComponent]);
  useEffect(() => {
    let total_earning = 0;

    deductionComponent.map((d, i) => {
      total_earning = total_earning + Number(d.amount);

      return d;
    });

    setTotalDeduction(!isNaN(total_earning) ? total_earning : 0);
  }, [deductionComponent]);
  return (
    <StyledManualUpdatePayslip>
      <Spin spinning={loading}>
        <>
          <h2 className="heading">{companyName || ""}</h2>
          <h2 className="heading Salary">
            Salary Slip Uploaded by User for
            <Tooltip title="Edit month from here only if user has uploaded payslip in wrong month">
              <div style={dropdownStyle}>
                <Select
                  options={nonVerifiedPayslipMonths}
                  onChange={setUploadMonth}
                  value={month}
                />
              </div>
            </Tooltip>
          </h2>
          {hasAlreadyApprovedPayslipInMonth ? (
            <div className="reject-message">
              Please reject this payslip as the user already has an approved
              payslip in this month
            </div>
          ) : (
            <>
              <ReadPayslip
                earningComponent={earningComponent}
                setComponent={setEarningComponent}
                componentTitle={"Earning"}
                componentLoading={false}
                totalEarning={totalEarning}
                viewMode={viewMode}
              />
              <ReadPayslip
                style={{ marginTop: 24 }}
                earningComponent={deductionComponent}
                setComponent={setDeductionComponent}
                componentTitle={"Deduction"}
                componentLoading={false}
                totalDeduction={totalDeduction}
                viewMode={viewMode}
              />
            </>
          )}

          {!hasAlreadyApprovedPayslipInMonth && totalEarning > 0 && (
            <div className="confirm-components">
              <OutlinedCheckbox
                value={componentsConfirmed}
                onChange={() => setComponentsConfirmed(!componentsConfirmed)}
              />
              <p className="confirm-components-text heading Salary">
                Please confirm that the above incomes and deductions correspond
                to the salary slip. Before submitting, please complete any
                missing fields and fix any errors.
              </p>
            </div>
          )}

          {!viewMode && (
            <div className="submitBtn">
              <Button
                style={buttonStyling}
                onButtonClick={(e) => {
                  submit(earningComponent, deductionComponent, "REJECTED");
                }}
                buttonName={"Reject"}
              >
                Reject
              </Button>
              <Button
                style={{ ...buttonStyling, ...dynamicStyling }}
                onButtonClick={() => {
                  filterEmptyArr(earningComponent).length > 0 &&
                    filterEmptyArr(deductionComponent).length > 0 &&
                    componentsConfirmed &&
                    submit(earningComponent, deductionComponent, "COMPLETED");
                }}
                buttonName={"Submit"}
              >
                Submit
              </Button>
            </div>
          )}
        </>
      </Spin>
    </StyledManualUpdatePayslip>
  );
};

export default ManualUpdatePayslip;
