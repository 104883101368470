import React, {useEffect, useState} from "react";
import {StyledConsultationView} from "./ConsultationView.styled";
import {
  WIDGET_ACTION_TYPE,
  reqObjInitialValue,
  widgetArray,
} from "../../constants";
import {AdvisorDashboard} from "../../../../Services/AdvisorDashboard";
import toast from "react-hot-toast";
import {useSearchParams} from "react-router-dom";
import {WidgetWrapper} from "../../components/WidgetWrapper";
import {ConsultationTableView} from "../../components/ConsultationTableView";
import posthog from "posthog-js";
import {PosthogActions} from "../../../../Integrations/Posthog/constant";

const ConsultationView = () => {
  const [loading, setLoading] = useState(false);
  const [ConsultationViewData, setConsultationViewData] = useState([]);
  const [searchParams] = useSearchParams();
  const upcoming_call = searchParams.get("upcoming_call");

  const [currentWidget, setCurrentWidget] = useState(
    upcoming_call
      ? WIDGET_ACTION_TYPE.UPCOMING_CALLS
      : WIDGET_ACTION_TYPE.PENDING_ACTIONS
  );
  const [widgetCount, setWidgetCount] = useState({});

  const [reqObj, setReqObj] = useState(reqObjInitialValue);

  const handleWidgetSelection = (type) => {
    setCurrentWidget((state) => type || state);
    posthog.capture(PosthogActions.ACTIONABLE_WIDGET_SELECTION, {
      widgetType: type,
    });
    setReqObj(reqObjInitialValue);
  };

  const callDetailType = {
    PENDING_ACTIONS: "ACTION_PENDING",
    COMPLETED_CALLS: "COMPLETED_CALL",
    UPCOMING_CALLS: "UPCOMING_CALL",
  };

  const getConsultationViewData = async (
    currentWidget,
    client_name,
    start_date,
    end_date,
    action_type
  ) => {
    try {
      setLoading(true);

      const actionType = {
        PENDING_MOM: "MOM_PENDING",
        PENDING_CALL: "UNMARKED_CALL",
      };
      const reqObj = {
        call_detail_type: callDetailType[currentWidget],
        client_name,
        start_date,
        end_date,
        action_type: actionType[action_type],
      };
      const res = await AdvisorDashboard.getConsultationViewData(reqObj);
      const {data: dashboardData} = res;

      setWidgetCount(dashboardData?.dashboard_count);
      const modifyData = dashboardData?.call_details.map((callInfo) => {
        return {
          ...callInfo,
          type: dashboardData?.call_detail_type,
          callType: callInfo?.type,
          ...callInfo?.user,
          actionType: callInfo?.action_type,
          date: callInfo?.start_time,
          momCompletionDate: callInfo?.mom_completed_date,
          meeting_url: callInfo?.meeting_link,
        };
      });
      setConsultationViewData(modifyData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message || "Oops something went wrong");
    }
  };

  useEffect(() => {
    getConsultationViewData(
      currentWidget,
      reqObj?.client_name,
      reqObj?.start_date,
      reqObj?.end_date,
      reqObj?.action_type
    );
  }, [
    currentWidget,
    reqObj?.client_name,
    reqObj?.start_date,
    reqObj?.end_date,
    reqObj?.action_type,
  ]);

  return (
    <StyledConsultationView>
      <WidgetWrapper
        widgetArray={widgetArray}
        handleWidgetSelection={handleWidgetSelection}
        currentWidget={currentWidget}
        consultationTableData={ConsultationViewData}
        callDetailType={callDetailType}
        widgetCount={widgetCount}
      />

      <ConsultationTableView
        consultationTableData={ConsultationViewData}
        currentWidget={currentWidget}
        setReqObj={setReqObj}
        reqObj={reqObj}
        loading={loading}
        getConsultationViewData={getConsultationViewData}
      />
    </StyledConsultationView>
  );
};

export default ConsultationView;
