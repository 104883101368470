import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import ArticleEditor from '../../../container/ArticleEditor'
import Articles from '../../../container/articles'
import PromoImageLinkGenerator from '../../../container/PromoImageLinkGenerator'

const AuthorRoutes = () => {
  return (
    <Routes>
    <Route path="/editor" element={<ArticleEditor />} />
    <Route path="/articles/editor/:id" element={<ArticleEditor />} />
    <Route path="/articles/:id" element={<Articles />} />
    <Route
      path="/"
      element={<Navigate to="/articles/individual-taxation" />}
    />
    <Route
      path={'articles/generate-image-link'}
      element={<PromoImageLinkGenerator/>}>
    </Route>
  </Routes>
  )
}

export default AuthorRoutes