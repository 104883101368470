import { httpClient } from "./httpUtil";

const SuperSaverUserProfileApi = {
  getUserDetailsByUserId: (userId, fYId) => {
    return (
      userId &&
      httpClient.get(
        `super-saver-user/v1/${userId}/details/internal?financial_year_id=${fYId}`
      )
    );
  },
  modifyUserCoin: (coin,user_id,type) => {
    const params = {
      coin,
      user_id,
      type
    };
    const url = `super-saver-user/v1/modify-user-coin/internal`;
    return httpClient.put(url,null,params);
  },
};

export { SuperSaverUserProfileApi };
