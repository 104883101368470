export const sortArrayAndAppendOtherAtLast = (arr) => {
    let otherValue;
    let sortedAndFilteredArr = arr
      ?.sort((a, b) => a.label.localeCompare(b.label))
      ?.filter((item) => {
        if(item.label === "Other" || item.label === "Others"){
            otherValue = item;
        }
        return item.label !== "Other" && item.label !== "Others"
      });
    
    if (otherValue) {
        sortedAndFilteredArr?.push({
            label: otherValue?.label,
            type: otherValue?.type,
            value: otherValue?.value,
            isSelected: otherValue?.isSelected || false
        });
    }

    return sortedAndFilteredArr;
}
