import { orange } from "@material-ui/core/colors";
import {getScaledValue} from "./globalStyle";

export const dim = {
  _0px: getScaledValue(0),
  _1px: getScaledValue(1),
  _2px: getScaledValue(2),
  _4px: getScaledValue(4),
  _6px: getScaledValue(6),
  _7px: getScaledValue(7),
  _8px: getScaledValue(8),
  _10px: getScaledValue(10),
  _12px: getScaledValue(12),
  _14px: getScaledValue(14),
  _16px: getScaledValue(16),
  _18px: getScaledValue(18),
  _20px: getScaledValue(20),
  _22px: getScaledValue(22),
  _24px: getScaledValue(24),
  _26px: getScaledValue(26),
  _28px: getScaledValue(28),
  _30px: getScaledValue(30),
  _32px: getScaledValue(32),
  _34px: getScaledValue(34),
  _36px: getScaledValue(36),
  _38px: getScaledValue(38),
  _40px: getScaledValue(40),
  _42px: getScaledValue(42),
  _44px: getScaledValue(44),
  _46px: getScaledValue(46),
  _48px: getScaledValue(48),
  _50px: getScaledValue(50),
  _52px: getScaledValue(52),
  _54px: getScaledValue(54),
  _56px: getScaledValue(56),
  _58px: getScaledValue(58),
  _60px: getScaledValue(60),
  _62px: getScaledValue(62),
  _64px: getScaledValue(64),
  _66px: getScaledValue(66),
  _68px: getScaledValue(68),
  _70px: getScaledValue(70),
  _72px: getScaledValue(72),
  _74px: getScaledValue(74),
  _76px: getScaledValue(76),
  _78px: getScaledValue(78),
  _80px: getScaledValue(80),
  _82px: getScaledValue(82),
  _84px: getScaledValue(84),
  _86px: getScaledValue(86),
  _88px: getScaledValue(88),
  _90px: getScaledValue(90),
  _92px: getScaledValue(92),
  _300px: getScaledValue(300),
  _scale: function _scale(value) {
    return getScaledValue(value);
  },
};

export const theme = {
  fontSize: {
    superHeading: dim._32px,
    heading: dim._24px,
    bigBody: dim._16px,
    body: dim._14px,
    smallBody: dim._12px,
    tag: dim._8px,
  },
  fontWeight: {
    normal: "normal",
    bold: "bold",
    weight_30: 300,
    weight_40: 400,
    weight_50: 500,
    weight_60: 600,
    weight_70: 700,
    weight_80: 800,
  },
  color: {
    black_0: "#000000",
    black_10: "#212121",
    black_15: "#3d4859",
    black_20: "#212F45",
    green_10: "#85bb65",
    green_15: "#abffab",
    green_20: "#15BE53",
    green_25: "#0C7231",
    green_30: "#0C7231",
    blue_05: "#92d1fc",
    blue_10: "#0B6aB0",
    blue_20: "#0A2540",
    blue_30: "#635BEF",
    blue_40: "#2769DA",
    blue_50: "#1A73E8",
    blue_60: "#13122f",
    blue_70: "#2c81ff",
    blue_80: "#2042f3",
    blue_90: "#1890ff",
    blue_100: "#91c2e6",
    red_0: "#f00707",
    red_20: "#E16A59",
    red_25: "#cc1818",
    red_30: "#470606",
    red_40: "#da5f55",
    red_50: "#EA4336",
    red_100: "#fff2f2",
    white_0: "#ffffff",
    white_10: "#fafafa",
    white_20: "#f5f5f5",
    white_30: "#F6F9FC",
    white_40: "#F1F5F7",
    white_50: "#fafafa",
    white_60: "#f8f8f8",
    grey_10: "#7f7f7f",
    grey_15: "#717578",
    grey_20: "#e5e5e5",
    grey_30: "#BBBBBB",
    grey_40: "#d9ebf9",
    grey_50: "#E9F0FB",
    grey_60: "#e8f0fe",
    grey_70: "#3c4043",
    grey_90: "#425466",
    grey_80: "#5b5f66",
    grey_100: "#808080",
    grey_110: "#c6c6c6",
    grey_120: "#D1D1D1",
    grey_130: "#929292",
    grey_150: '#f0f0f0',
    grey_155:'#8c8c8c',
    grey_160: "#595959",
    grey_165:'#333',
    pink_20: "#fbbc75",
    transparent: "#0000",
    greyBlue_10: "#EDF7FF",
    greyBlue_20: "#CBDCEE",
    greySky_10: "#f1f5f8",
    yellowLight: "#F1EBD3",
    yellow_0: "#ECB801",
    redisBrown: "#FCE8E6",
    greenishBg: "#dbfeff",
    orange_10: "#f5821e",
    orange_20: "#e9564f",
    orange_25:'#faad14',
    orangeLight_10: "#fcebdb",
    purpleLight_10: "#d3c6fb",
    yellow_5: "#fff3cd",
    yellow_10: "#856404",
    
  },
};
