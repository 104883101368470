import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router";
import Button from "../../commonComponents/Button";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import LoaderPage from "../../commonComponents/LoaderPage";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";

import { getSuperSaverUser } from "../../Services/SuperSaver";
import {
  columns
} from "../../utils/superSaverUser";
import { Main } from "./SuperSaverUser.styled";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";

const tabList = [
  {
    label: "All User",
    value: "ALL",
  },
  {
    label: "Super Saver User",
    value: "SUPER_SAVER_USER",
  },
  {
    label: "ITR Filing User",
    value: "ITR_FILING_USER",
  },
  {
    label: "Expired User",
    value: "EXPIRED_USER",
  },
];

const SuperSaverUser = () => {
  const selectedView = localStorage.getItem(
    LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE
  );
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [_columns, setColumns] = useState();
  const [value, setValue] = React.useState(
    (tabList.some((d) => d.value === selectedView) && selectedView) || "ALL"
  );

  const handleChange = (event, newValue) => {
    localStorage.setItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE, newValue);
    setValue(newValue);
    navigate("/");
  };

  useEffect(() => {
    setLoader(true);
    getSuperSaverUser(value !== "ALL" ? value : undefined).then(function (value) {
      setData(value.sort((a, b) => b.created_at - a.created_at));
      setLoader(false);
    });
  }, [value]);

  useEffect(() => {
    setColumns([...columns, getButtonColumns()]);
  }, []);

  function rowFunction(value, index, array) {
    const {
      first_name: primaryAdvisorFirstName,
      last_name: primaryAdvisorLastName,
    } = value?.advisor_details || {};
    const {
      first_name: secondaryAdvisorFirstName,
      last_name: secondaryAdvisorLastName,
    } = value?.secondary_advisor_details || {};
    const secondaryAdvisor =
      secondaryAdvisorFirstName &&
      `${secondaryAdvisorFirstName || ""} ${secondaryAdvisorLastName || ""}`;
    const primaryAdvisor =
      primaryAdvisorFirstName &&
      `${primaryAdvisorFirstName || ""} ${primaryAdvisorLastName || ""}`;
    return {
      id: index,
      name: value?.user_response?.first_name || "",
      subscription_status: value?.subscription_status
        ? value?.subscription_status
        : "",
      mobile: value.user_response ? value.user_response.mobile : "",
      csr_user: value?.csr_user ? value?.csr_user?.name : "-",
      service_type: value.category_type,
      super_saver_booking_id: value.external_id,
      service_id: value.category_id,
      email: value.user_response ? value.user_response.email : "",
      invite_id: value.invite_id,
      onboard_id: value.onboard_id,
      user_id: value.user_id,
      activity_details: value.activity_details,
      referral_code: value.referral_code,
      invitation_status: value.invitation_status,
      advisor: value.advisor_details ? primaryAdvisor : "Not-Assigned",
      secondaryAdvisor: secondaryAdvisor || "Not-Assigned",
      advisor_details: value.advisor_details,
      appVersion: value.app_version,
      appVersions:
        value.meta_data_payload && value.meta_data_payload.app_version,
    };
  }
  const handleOnCellClick = (params, navigate) => {
    if (params.field === "actions") {
      return;
    }

    navigate(`/superSaverUserProfile?user_id=${params.row.user_id}`,{
      state: { item: params.row },
    });
  };

  const renderDetailsButton = (params) => {
    let isAdvisorAssigned =
      params.row && params.row.advisor_details ? true : false;
    return (
      <Button
        onDisable={!isAdvisorAssigned}
        buttonClassName={"btn"}
        style={{
          width: "60px",
          marginTop: "15px",
          backgroundColor: !isAdvisorAssigned ? "#8c8c8c" : "",
          border: !isAdvisorAssigned ? "#8c8c8c" : "",
        }}
        onButtonClick={() => {
          const item = params.row; 
          navigate(`/manage-appointment?user_id=${params.row.user_id}`, {
            state: { ...item, registered: true },
          });
        }}
        buttonName={"Book"}
      />
    );
  };

  const getButtonColumns = () => {
    return {
      field: "actions",
      headerName: "Book Appointment",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: renderDetailsButton,
      showInMenu: false,
    };
  };


  return loader ? (
    <LoaderPage />
  ) : (
    <Main className="super">
      <TabContext value={value} variant="fullWidth">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tabList.map((d, i) => {
              return <Tab {...d} key={i} sx={{ width: 200 }} />;
            })}
          </TabList>
        </Box>

        <TabPanel value="ALL" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={_columns}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
        <TabPanel value="ITR_FILING_USER" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={_columns}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
        <TabPanel value="SUPER_SAVER_USER" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={_columns}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
        <TabPanel value="EXPIRED_USER" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data.filter((it) => {
                  return it?.subscription_status === "EXPIRED";
                })}
                columns={_columns}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
      </TabContext>
    </Main>
  );
};

export default SuperSaverUser;
