import React from "react";
import { Spin } from "antd";
import TaskCard from "../TaskCard";
import { GridContainer, LoaderContainer } from "./StyledTaskGrid";

const TaskGrid = ({ tasks = [], userRole, loading = false, width }) => {
  return (
    <GridContainer width={width}>
      {loading ? (
        <LoaderContainer>
          <Spin size="large" />
        </LoaderContainer>
      ) : (
        <div className="grid-container">
          {tasks?.map((task) => (
            <TaskCard key={task?.task_detail?.task_id} task={task} userRole={userRole} />
          ))}
          {tasks?.length === 0 && <div>No tasks Found</div>}
        </div>
      )}
    </GridContainer>
  );
};





export default TaskGrid;
