import React, {useState, useRef} from "react";
import {
  Drawer,
  Button,
  TextField,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
  Backdrop,
  Chip,
} from "@mui/material";
import posthog from "posthog-js";
import {EditorState, RichUtils, convertToRaw} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import PDFViewer from "../../../../../../commonComponents/PDFViewer";
import toast from "react-hot-toast";
import axios from "axios";
import {useSearchParams} from "react-router-dom";
import {StyledCard, StyledNotesEditor} from "./StyledNoteEditor";
import uploadFileService from "../../../../../../Services/UploadFilesService";
import {getContentTypeOnExt} from "../../../../../../utils/getContentTypeOnExtension";
import {AdvisorNotesService} from "../../../../../../Services/AdvisorNotesService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {LOCAL_DB_KEYS} from "../../../../../../constants/localStorage";
import {getFileNameWithoutSpecialCharacter} from "../../../../../../utils/FileWithoutSpecialCharactor";
import {PosthogActions} from "../../../../../../Integrations/Posthog/constant";

function NoteEditor({callBookingId, setSaveNewNotes}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileDescription, setFileDescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [noteHeader, setNoteHeader] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const editorRef = useRef(null);

  const advisorId = JSON.parse(
    localStorage.getItem(LOCAL_DB_KEYS.USER)
  ).user_roll_id;
  const userId = searchParams.get("user_id");

  const descriptionOptionsPills = [
    {label: "Declaration sheet", value: "Declaration sheet"},
    {label: "Salary slip", value: "Salary slip"},
    {label: "Capital gains report", value: "Capital gains report"},
  ];

  const handleEditorChange = (newEditorState) => setEditorState(newEditorState);

  const uploadFile = async (file) => {
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    const ext = name.substring(lastDot + 1);
    const fileSizeInKB = file.size / 1024;
    const fileSize =
      fileSizeInKB > 1000
        ? `${Math.round(file.size / (1024 * 1024))} MB`
        : `${Math.round(fileSizeInKB)} KB`;
    const uploaderId = JSON.parse(
      localStorage.getItem(LOCAL_DB_KEYS.USER)
    )?.user_roll_id;
    const newFilename = getFileNameWithoutSpecialCharacter(name);
    const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);

    const body = {
      document_type: "OTHERS",
      owner_of_document: uploaderId,
      uploaded_by: uploaderId,
      financial_year_id: fyId,
      file_name: newFilename,
      document_name: newFilename,
      //need to add file type once corrected from backend
    };

    try {
      const initialUpload = await uploadFileService.uploadFile(body);
      const {document_identifier, pre_signed_s3_url_for_upload} =
        initialUpload.data;
      const headers = {"Content-Type": getContentTypeOnExt(ext)};
      const params = {};
      let options = {params, headers};

      const data = await axios
        .put(pre_signed_s3_url_for_upload, file, options)
        .then(() => {})
        .catch((err) => {
          const {message} = err;
          throw new Error(message ? message : "Oops Something went wrong");
        });

      toast.success("Document uploaded successfully!");

      return {
        document_identifier,
        name,
        fileType: ext.toUpperCase(),
        pre_signed_s3_url_for_upload,
      };
    } catch (error) {
      const errorMsg = error?.response?.data?.message || error.message;
      if (errorMsg.includes("403")) {
        toast.error("This Document type is not supported");
      } else {
        toast.error(errorMsg);
      }
    }
  };

  const handleSaveNote = async () => {
    const noteContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const userId = searchParams.get("user_id");
    const note = {
      noteTitle: noteHeader,
      noteContent,
      documents: uploadedFiles,
      userId,
    };

    const payload = {
      call_booking_id: callBookingId,
      written_by: advisorId,
      advisor_notes_request: {
        note: {
          note_title: noteHeader,
          note_content: noteContent,
          documents: uploadedFiles,
          user_id: userId,
        },
      },
    };

    try {
      setLoading(true);
      await AdvisorNotesService.saveNote(advisorId, payload);
      toast.success("Note Saved Successfully");
      setEditorState(EditorState.createEmpty());
      setNoteHeader("");
      setUploadedFiles([]);
      setSaveNewNotes(noteHeader);
      posthog.capture(PosthogActions.NOTE_CREATED, {
        note_title: noteHeader,
        note_content: noteContent,
        documents: uploadedFiles,
        user_id: userId,
      });
    } catch {
      toast.error("Something went wrong, please try again");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => setSelectedFile(e?.target?.files[0]);

  const handleRemoveFile = (fileURL) => {
    setUploadedFiles(uploadedFiles?.filter((file) => file?.file !== fileURL));
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        setLoading(true);
        const uploadedFileData = await uploadFile(selectedFile);
        if (uploadedFileData?.document_identifier) {
          setUploadedFiles((prevFiles) => [
            ...prevFiles,
            {
              file: URL.createObjectURL(selectedFile),
              path: uploadedFileData?.pre_signed_s3_url_for_upload,
              type: selectedFile?.type,
              description: fileDescription,
              name: getFileNameWithoutSpecialCharacter(selectedFile?.name),
              document_identifier: uploadedFileData?.document_identifier,
            },
          ]);
          setDrawerOpen(false);
          setSelectedFile(null);
          setFileDescription("");
        }
      } catch {
        toast.error("Something went wrong, please try again");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleImageClick = async (file) => {
    setSelectedImage(file);
    setModalOpen(true);
  };

  const handleOtherFileFormats = (file) => {
    const link = document.createElement("a");
    link.href = file.file;
    link.download = file.name;
    link.click();
  };

  const handlePreview = (file) => {
    file.type.startsWith("image/") || file.type === "application/pdf"
      ? handleImageClick(file)
      : handleOtherFileFormats(file);
  };

  const getFilePreviewImage = (file) => {
    if (!file) return null;
    return file.type.startsWith("image/")
      ? file.file
      : file.type === "application/pdf"
      ? "https://t3.ftcdn.net/jpg/02/26/42/06/360_F_226420649_vlXjp3JyUrnW5EHY00dvhbqkVdUfyafj.jpg"
      : "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/File_alt_font_awesome.svg/1024px-File_alt_font_awesome.svg.png";
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const handleWrapperClick = () => {
    if (editorRef.current) {
      editorRef.current.focusEditor();
    }
  };

  return (
    <StyledNotesEditor>
      <div className="note-editor">
        {uploadedFiles.length > 0 && (
          <Grid container spacing={2}>
            {uploadedFiles.map((file, index) => (
              <Grid item xs={12 / uploadedFiles.length} key={index}>
                <StyledCard>
                  <IconButton
                    onClick={() => handleRemoveFile(file.file)}
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      zIndex: 1,
                      color: "gray",
                      background: "rgba(128, 128, 128, 0.3)",
                    }}
                    title="Remove item"
                  >
                    <CloseIcon />
                  </IconButton>
                  <CardMedia
                    title="Click to see Description"
                    component="img"
                    height="140"
                    image={getFilePreviewImage(file)}
                    alt={file.name}
                    onClick={() => handlePreview(file)}
                    style={{cursor: "pointer"}}
                  />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      {file.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {file.description?.length > 50
                        ? `${file.description.slice(0, 50)}...`
                        : file.description}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}

        <input
          placeholder="Title"
          value={noteHeader}
          onChange={(e) => setNoteHeader(e.target.value)}
          className="header-input"
        />

        <div className="editor-container" onClick={handleWrapperClick}>
          <Editor
            ref={editorRef}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleEditorChange}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "list",
                "textAlign",
                "history",
              ],
              inline: {options: ["bold", "italic", "underline"]},
              list: {options: ["unordered", "ordered"]},
              textAlign: {options: ["left", "center", "right"]},
              link: {options: ["link"]},
            }}
            placeholder="Notes are for Internal use only and will not be shared with client"
          />
        </div>

        <div className="editor-actions">
          <Button
            className="save-button"
            variant="contained"
            onClick={handleSaveNote}
            disabled={loading || !noteHeader || !editorState}
          >
            Save Note
          </Button>
          <Button onClick={() => setDrawerOpen(true)} title="Upload Files">
            <UploadFileIcon />
          </Button>
        </div>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: 400,
              padding: "20px",
              paddingTop: "200px",
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <div className="drawer-content">
            <p className="only-pdf-info">Only PDF is supported for now</p>
            <input
              accept="application/pdf" // will change once backend s3 link issue is resolved
              type="file"
              onChange={handleFileChange}
              style={{display: "block", margin: "20px 0"}}
            />
            <TextField
              label="File Description*"
              multiline
              rows={4}
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              variant="outlined"
              fullWidth
            />

            <div className="description-pills">
              {descriptionOptionsPills?.map((item, index) => {
                return (
                  <Chip
                    label={item?.label}
                    color="primary"
                    variant="outlined"
                    style={{
                      borderRadius: "16px",
                      marginTop: "10px",
                      marginRight: "5px",
                    }}
                    key={index}
                    onClick={() => setFileDescription(item?.label)}
                  />
                );
              })}
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              style={{marginTop: "20px"}}
              disabled={!selectedFile || !fileDescription}
            >
              Upload
            </Button>
          </div>
        </Drawer>

        <Backdrop
          sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          maxWidth="sm"
          fullWidth
          style={{marginTop: "50px"}}
        >
          <DialogTitle>
            Preview
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleModalClose}
              aria-label="close"
              style={{position: "absolute", right: 10, top: 10}}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{padding: "20px", textAlign: "center", minHeight: "100%"}}
          >
            {selectedImage?.type?.startsWith("image/") ? (
              <img
                src={selectedImage?.file}
                alt="Preview"
                style={{maxWidth: "100%", maxHeight: "80vh"}}
              />
            ) : (
              <PDFViewer src={selectedImage?.file} style={{height: "70vh"}} />
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{marginTop: "10px"}}
            >
              {selectedImage?.description}
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    </StyledNotesEditor>
  );
}

export default NoteEditor;
