import React from "react";
import { ClientDetails } from "../../../SuperClientProfile/SuperSaverProfile.styled";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { USER_TYPE } from "../../../../utils/localStorageKeys";
import { USER_ROLE } from "../../../../constants/userRole";
import { dim } from "../../../../styles/theme";
const CsrSelection = ({
  csrId: csrId,
  handleCsrUpdate,
  csrArr,
  title = "CSR",
  name,
}) => {
  const userType = JSON.parse(localStorage.getItem(USER_TYPE));
  const userRole = userType?.user_roll;
  const selectedCsr = csrArr?.find((csr) => csr?.csrId === csrId);
  const filteredCsrArr = csrArr?.filter((csr) => (csr?.name !== "" && csr?.mobile !== ""));
  
 if(userRole == USER_ROLE.ADMIN){
  return (
    <>
      <div className="clientox">
        <ClientDetails className="">
          <div
            className=""
            style={{
              width: "265px",
              padding: `${dim._24px} ${dim._24px} 0`,
            
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel id="demo-simple-select-standard-label">
                {title}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={csrId}
                name={name}
                onChange={(e) => {
                  handleCsrUpdate(e);
                }}
                label="Csr"
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 200 },
                  },
                }}
                renderValue={(selected) =>
                  selectedCsr ? (
                    <span style={{ whiteSpace: "pre-wrap" }}>
                       {selectedCsr?.label}<br />({selectedCsr?.mobile})
                    </span>
                  ) : ""
                }
              >
                {filteredCsrArr?.map((d, i) => (
                  <MenuItem value={d?.csrId} key={i}>
                        {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </ClientDetails>
      </div>
    </>
  );
}
return null;
};

export default CsrSelection;
