import React, { useEffect, useState, useCallback, useMemo } from "react";
import { dim, theme } from "../../../../styles/theme";
import TextButton from "../../../../commonComponents/TextButton";
import DropDownComponent from "../../components/DropdownComponet";
import { ClientActionableItemsStyle } from "./ClientsActionableItems.styled";
import AdvisorSubmitProof from "../AdvisorDependencyInfo/AdvisorSubmitProof";
import { errorValidator, findUndefinedProperties } from "../../utils";
import FormErrorMsgHandler from "../../../../commonComponents/FormErrormsgHandler";
import { DOCUMENT_TYPE, ERROR_MESSAGE } from "../../constant";
import SuperSaverIncomeService from "../../../../Services/SuperSaverIncomeService";
import { useSearchParams } from "react-router-dom";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import { useSelector } from "react-redux";
import { modifyMetaData } from "../../../../utils/modifyMetaData";
import { sortArrayAndAppendOtherAtLast } from "../../../../utils/sortArrayAndAppendOtherAtLast";

const client_text_content = {
  client_actionable_title: "Client Action Item(s)",
  add_action: "+ ADD ACTION",
};

const truncateString = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const ClientActionableItems = ({ handleClientData, handleHelpShow }) => {
  const ACTION_CATEGORY = useSelector(
    (state) => state.cmsReducer?.actionable_category
  );
  const DOCUMENT_TYPE = useSelector((state) => state.cmsReducer?.document_type);
  const dropDownData = {
    action_item: [
      {
        label: "Category",
        id: "type",
        errorType: "action_category",
        defaultValue: "Select Category",
        data: sortArrayAndAppendOtherAtLast(modifyMetaData(ACTION_CATEGORY)),
      },
      {
        label: "Action",
        id: "action",
        errorType: "action_type",
        defaultValue: "Select Action",
        data: [
          {
            label: "Documents upload",
            type: "DOCUMENT_UPLOAD",
            value: "DOCUMENT_UPLOAD",
            isSelected: false,
          },
          {
            label: "Comment",
            type: "COMMENT",
            value: "COMMENT",
            isSelected: false,
          },
        ],
      },
    ],
  };
  const documentTypeDropdown = {
    documentTypeDefaultValue: "Select Document Type",
    action_item: [
      {
        label: "Document type",
        id: "document",
        errorType: "action_category",
        data: modifyMetaData(DOCUMENT_TYPE),
      },
    ],
  };
  const [searchParams] = useSearchParams();
  const [companyList, setCompanyList] = useState([]);
  const getCompanyData = () => {
    const company_list =
      companyList?.data?.income_details?.salary_income_dto_list;

    const newArr = company_list?.map((item) => {
      const { name, brand_name } = item?.company_details;
      return {
        label: truncateString(brand_name, 20),
        type: name,
        value: item.external_id,
        isSelected: false,
      };
    });

    const objNew = {
      label: "Payslip",
      id: "payslip",
      errorType: "payslip",
      data: newArr || [],
    };

    const finalArray = [objNew];
    setCompanyName(finalArray);
  };

  useEffect(() => {
    getCompanyData();
  }, [companyList]);

  const { client_actionable_title, add_action } = client_text_content;
  const { documentTypeDefaultValue } = documentTypeDropdown;

  const [isError, setIsError] = useState({ error: false, errorArr: [] });
  const [document, setDocument] = useState({ type: "", action: "" });
  const [actionableData, setActionableData] = useState(
    dropDownData.action_item
  );
  const [documentTypeData, setDocumentType] = useState(
    documentTypeDropdown.action_item
  );

  const [isCommentOnAction, setCommentOnAction] = useState(true);
  const [comment, setComment] = useState("");
  const [actionableList, setActionableList] = useState([]);
  const [payslip, setPayslip] = useState("");

  const [companyName, setCompanyName] = useState([]);

  const userId = searchParams.get("user_id");

  const handleDeleteActionable = (index) => {
    const newArr = actionableList;
    newArr.splice(index, 1);
    setActionableList([...newArr]);
  };

  let SALARY = "SALARY";
  const fyId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID);
  const fetchData = useCallback(async () => {
    const data = await SuperSaverIncomeService.getIncomeRevampData(
      SALARY,
      userId,
      fyId
    );
    setCompanyList(data);
  }, []);

  useEffect(() => {
    payslip === "PAYSLIP" && fyId && fetchData();
  }, [payslip]);

  let card_items = [];
  const handleDropDownType = (value, index, e) => {
    const newArr = actionableData.map((d, i) => {
      if (d.id === e.target.name) {
        return {
          ...d,
          data: d.data.map((dropdownValues, index) => {
            card_items.push(dropdownValues);

            return {
              ...dropdownValues,
              isSelected:
                dropdownValues.label === value ||
                dropdownValues.value === value,
            };
          }),
        };
      } else {
        return d;
      }
    });

    setActionableData(newArr);
    setDocument({
      ...document,
      [e.target.id]: +value,
    });
  };

  const findActionableType = (actionableData, type) => {
    const item = actionableData.filter((d, i) => {
      return d.id === type;
    });

    return item[0].data?.filter((d) => d?.isSelected)[0]?.value;
  };

  const findActionableDocumentType = (documentTypeData, type) => {
    const item = documentTypeData.filter((d, i) => {
      return d.id === type;
    });

    return item[0].data?.filter((d) => d?.isSelected)[0]?.value;
  };

  const hadnleComment = (e) => {
    setComment(e.target.value);
  };

  const handleDocumentDropDownType = (value, index, e) => {
    setPayslip(value);
    const newItem = documentTypeData.map((d, i) => {
      if (d.id === e.target.name) {
        return {
          ...d,
          data: d.data.map((dropdownValues, index) => {
            card_items.push(dropdownValues);

            return {
              ...dropdownValues,
              isSelected:
                dropdownValues.label === value ||
                dropdownValues.value === value,
            };
          }),
        };
      } else {
        return d;
      }
    });
    setDocumentType(newItem);
  };

  const handlePayslipType = (value, index, e) => {
    const newItem = companyName.map((d, i) => {
      if (d.id === e.target.name) {
        return {
          ...d,
          data: d.data.map((dropdownValues, index) => {
            card_items.push(dropdownValues);

            return {
              ...dropdownValues,
              isSelected:
                dropdownValues.label === value ||
                dropdownValues.value === value,
            };
          }),
        };
      } else {
        return d;
      }
    });
    setCompanyName(newItem);
  };

  const getCompanydata = () => {
    let selectedCompanyType = null;
    companyName.forEach((item) => {
      const selectedData = item.data.find((obj) => obj.isSelected);
      if (selectedData) {
        selectedCompanyType = selectedData;
        return;
      }
    });

    return selectedCompanyType;
  };

  return (
    <ClientActionableItemsStyle>
      <div className="heading">{client_actionable_title}</div>
      <div style={{ display: "flex" }}>
        <div className="dropdown-container">
          {actionableData.map((item, ind) => {
            return (
              <div key={ind}>
                <div style={{ marginBottom: "10px" }}>{item.label}</div>
                <DropDownComponent
                  dataOptions={item.data}
                  id={item.id}
                  name={item.id}
                  defaultValue={item.defaultValue}
                  type={item.data?.filter((d) => d.isSelected)[0]?.value}
                  handleDropDownChange={handleDropDownType}
                  width={dim._scale(270)}
                  isError={isError}
                  componentCategory={item?.errorType}
                />
              </div>
            );
          })}
          <div>
            {findActionableType(actionableData, "action") ===
              "DOCUMENT_UPLOAD" &&
              documentTypeData.map((item, ind) => {
                return (
                  <div key={ind}>
                    <div style={{ marginBottom: "10px" }}>{item.label}</div>
                    <DropDownComponent
                      dataOptions={item.data}
                      id={item.id}
                      name={item.id}
                      defaultValue={documentTypeDefaultValue}
                      type={item.data?.filter((d) => d.isSelected)[0]?.value}
                      handleDropDownChange={handleDocumentDropDownType}
                      width={dim._scale(270)}
                      isError={isError}
                      componentCategory={"document_type"}
                    />
                  </div>
                );
              })}
          </div>
          <div>
            {payslip === "PAYSLIP" &&
              (companyName && companyName[0]?.data?.length > 0 ? (
                companyName.map((item, ind) => {
                  const fy = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR);
                  return (
                    <div key={ind}>
                      <div style={{ marginBottom: "10px" }}>
                        {item.label} (F.Y. {fy})
                      </div>
                      <DropDownComponent
                        dataOptions={item.data}
                        id={item.id}
                        name={item.id}
                        defaultValue={documentTypeDefaultValue}
                        type={item.data?.filter((d) => d.isSelected)[0]?.value}
                        handleDropDownChange={handlePayslipType}
                        width={dim._scale(270)}
                        isError={isError}
                        componentCategory={"company_id"}
                      />
                    </div>
                  );
                })
              ) : (
                <p style={{ padding: " 20px 0 0" }}>No Company available</p>
              ))}
          </div>
        </div>
      </div>
      <div
        className="border-bottom"
        style={{
          position: "relative",
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <textarea
              className="msg-box"
              rows="4"
              cols="77"
              placeholder="Type Details..."
              value={comment}
              type="text"
              id="name"
              onChange={hadnleComment}
            >
              {comment}
            </textarea>
          </div>

          <TextButton
            onClick={() => {
              setCommentOnAction(false);
              const obj = {
                text: comment.replace(/\s/g, "").length > 0 ? comment : "",
                action_category: findActionableType(actionableData, "type"),
                action_type: findActionableType(actionableData, "action"),
                status: "PENDING",
                owned_by: "USER",
                ...(findActionableDocumentType(documentTypeData, "document") ==
                  "PAYSLIP" &&
                  companyName[0]?.data !== undefined &&
                  companyName[0]?.data.length > 0 && {
                    company_id: getCompanydata()?.value,
                    company_name: getCompanydata()?.label,
                  }),
                ...(findActionableType(actionableData, "action") ===
                  "DOCUMENT_UPLOAD" &&
                  findActionableDocumentType(documentTypeData, "document") && {
                    document: {
                      document_type: findActionableDocumentType(
                        documentTypeData,
                        "document"
                      ),
                    },
                  }),
                ...(findActionableType(actionableData, "action") ===
                  "DOCUMENT_UPLOAD" && {
                  document_type: findActionableDocumentType(
                    documentTypeData,
                    "document"
                  ) && {
                    document_type: findActionableDocumentType(
                      documentTypeData,
                      "document"
                    ),
                  },
                }),
              };
              const { document_type, ...withoutDocumentType } = obj;

              const newObj =
                findActionableType(actionableData, "action") ===
                "DOCUMENT_UPLOAD"
                  ? obj
                  : withoutDocumentType;
              const data = errorValidator(findUndefinedProperties(newObj));
              !data.error && setActionableData(dropDownData.action_item);
              !data.error &&
                setActionableList((state) => {
                  return [...state, newObj];
                });
              !data.error && setActionableData(dropDownData.action_item);
              setIsError(data);
              !data.error && setComment("");
              !data.error && setDocumentType(documentTypeDropdown.action_item);
              !data.error && setPayslip("");
              !data.error && getCompanyData();
            }}
          >
            + ADD ACTION
          </TextButton>
        </div>
        {isError?.error && isError.errorArr.includes("text") && (
          <FormErrorMsgHandler style={{ bottom: "-22px" }}>
            {ERROR_MESSAGE.text}
          </FormErrorMsgHandler>
        )}
      </div>
      {actionableList.map((item, ind) => (
        <div>
          <AdvisorSubmitProof
            item={item}
            key={ind}
            handleDeleteActionable={handleDeleteActionable}
          />
        </div>
      ))}
      <div className="button-align">
        <TextButton
          style={{
            background: theme.color.green_20,
            color: theme.color.white_0,
            padding: dim._10px,
            fontSize: dim._14px,
            width: "18%",
            letterSpacing: "2.343px",
            opacity: actionableList.length > 0 ? "1" : "0.3",
            cursor: actionableList.length > 0 ? "pointer" : "not-allowed",
          }}
          disabled={actionableList.length > 0 ? false : true}
          onClick={() => {
            handleClientData(actionableList);
            handleHelpShow(true);
          }}
        >
          Save
        </TextButton>
      </div>
    </ClientActionableItemsStyle>
  );
};

export default ClientActionableItems;
