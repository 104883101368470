import React, { useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { CustomizedButton } from "../../../../commonComponents/Button";
import SuperSaverDiscussionBookService from "../../../../Services/SuperSaverClientChat";
import { actions, postSessionMessage } from "../../store/action";
import UploadAttachment from "../UploadAttachment";
import { MessageSendContainerStyled } from "./MessageSendContainerStyled";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, getDefaultKeyBinding } from "draft-js";
import draftToHtml from "draftjs-to-html";
import DocumentPreview from "../DocumentPreview";
import { internalChatData } from "../../constant";
const TAGS = {
  LOW: "LOW",
  NORMAL: "NORMAL",
  HIGH: "HIGH",
  URGENT: "URGENT",
};

const TEXTAREAPLACEHOLDER = "Type a new message...";

const MessageSendContainer = ({
  handleAttachment,
  advisorId,
  userId,
  checkDocStatus,
  attachment,
  setAttachment,
  attachmentInput,
  setAttachmentInput,
  handleChangeAttachment,
  setMessageDetail,
  messageDetail
}) => {
  const messageContainerRef = useRef(null);
  const [elapsed, setElapsed] = useState(0);
  const [lastEventTime, setLastEventTime] = useState(0);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const notification = useSelector((state) => state.chat.notification);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [tooltipActive, setTooltipActive] = useState(false);
  const [tagColor, setTagColor] = useState("");
  const dispatch = useDispatch();
  const selectedSession_id = useSelector((state) => state.chat.sessionId);

  const handleChangeFile = (e) => {
		handleChangeAttachment(e.target.files);
	};

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setMessageDetail({
      ...messageDetail,
      msg: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  const handleMessageSend = () => {
    dispatch(
      postSessionMessage({
        session_id: selectedSession_id,
        receiver_id: userId,
        sender_id: advisorId,
        message: {
          message: messageDetail?.msg,
          attachments: messageDetail?.attachment,
        },
        attachment: messageDetail?.attachment?.length > 0 ? "YES" : "NO",
        label: messageDetail?.label,
      })
    );
    setAttachment([]);
    setMessageDetail({
      msg: "",
      label: TAGS.NORMAL,
      attachment: [],
      msg_by: "client",
    });
    setTagColor("");
    setEditorState(EditorState.createEmpty());
  };

  const handleCrossImage = (index) => {
    const arr = attachment;
    arr.splice(index, 1);
    setAttachment([...arr]);
  };

  const onAction = (event) => {
    if (
      messageContainerRef.current &&
      messageContainerRef.current.contains(event.target) &&
      elapsed - lastEventTime > 600
    ) {
      setLastEventTime(elapsed);
      if (
        notification &&
        notification.unread_sessions > 0 &&
        notification.unread_messages !== undefined
      ) {
        const position = notification.unread_messages.findIndex((item) => {
          return item.session_id === sessionId;
        });
        if (position >= 0) {
          markAsRead();
        }
      }
    }
  };

  const reloadNotification = async () => {
    const details =
      await SuperSaverDiscussionBookService.getSessionNotification({
        userId: userId,
        receiverId: advisorId,
      });
    dispatch({
      type: actions.UPDATE_NOTIFICATION,
      data: details,
    });
  };

  const markAsRead = async () => {
    await SuperSaverDiscussionBookService.markMessageAsRead({
      requestId: advisorId,
      sessionId: sessionId,
      isRead: true,
    });
    await reloadNotification();
  };

  const { getElapsedTime } = useIdleTimer({
    onAction,
    timeout: 10000,
    throttle: 500,
  });

  const handleSendMessage = () => {
    if (
      stripHtmlTags(messageDetail?.msg)?.trim()?.length === 0 &&
      messageDetail?.attachment?.length === 0
    ) {
      return; // Do not send the message if input is empty
    }
    handleMessageSend();
  };

  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      // Enter key without Shift
      return "submit-message";
    }
    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command) => {
    if (command === "submit-message") {
      handleSendMessage();
      return "handled"; // Prevent the default behavior
    }
    return "not-handled";
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsed(getElapsedTime());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    checkDocStatus(attachment);
  }, [attachment]);

  useEffect(() => {
    setMessageDetail({
      msg: "",
      label: TAGS.NORMAL,
      attachment: [],
      msg_by: "client",
    });
    setAttachment([]);
    setEditorState(EditorState.createEmpty());
  }, [selectedSession_id]);

  const isDisabled =
    stripHtmlTags(messageDetail?.msg)?.trim()?.length === 0 &&
    messageDetail?.attachment?.length === 0 &&
    messageDetail?.msg?.trim()?.length === 0;

  return (
    <MessageSendContainerStyled
      className="chatField"
      tooltipActive={tooltipActive}
      tagColor={tagColor}
      isAttached={attachment?.length > 0}
      ref={messageContainerRef}
    >
      {attachment?.length > 0 && (
        <div className="all_chat_attachments">
          {attachment?.map((d, i) => (
            <DocumentPreview
              uploading={true}
              key={i}
              link={false}
              handleCrossImage={handleCrossImage}
              index={i}
              data={d}
            />
          ))}
        </div>
      )}
      <div className="input_items">
        <Editor
          className="text-area-component"
          editorState={editorState}
          wrapperClassName="wrapperClassName "
          editorClassName="editorClassName text-area-component"
          toolbar={{
            options: [],
          }}
          toolbarHidden={true}
          placeholder={TEXTAREAPLACEHOLDER}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={myKeyBindingFn}
          onEditorStateChange={onEditorStateChange}
        />
        <div className="action_icons">
          <UploadAttachment
            handleChangeattechment={handleChangeFile}
            attachmentInput={attachmentInput}
          />

          <CustomizedButton
            disabled={isDisabled}
            style={{
              width: "40px",
              height: "40px",
              padding: 0,
              borderRadius: "10px",
            }}
            onClick={handleSendMessage}
          >
            <img src={internalChatData.send_icon} alt="" />
          </CustomizedButton>
        </div>
      </div>
    </MessageSendContainerStyled>
  );
};

export default MessageSendContainer;
