import styled from "styled-components";
import { dim } from "../../../styles/theme";
import { theme } from "../../../styles/theme";

export const StyledCard = styled.div`
  background-color:${theme.color.white_0};
  padding: ${dim._10px};
  border-radius: ${dim._8px};
  box-shadow: 0 ${dim._2px} ${dim._8px} rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
  max-width: ${dim._scale(280)};

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 ${dim._4px} ${dim._12px} rgba(0, 0, 0, 0.2);
  }

  h3 {
    font-size: ${dim._14px};
    margin-bottom: ${dim._8px};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }

  .ant-typography-secondary {
    font-size: ${dim._14px};
    color:${theme.color.grey_155};
  }

  p {
    margin: ${dim._4px} 0;
    font-size:${dim._14px};
    color:${theme.color.grey_160};
  }

  .avatar {
    display: flex;
    align-items: center;
    gap: ${dim._8px};
    margin-top: ${dim._12px};
  }

  .creator-name {
    font-weight: 500;
    color: ${theme.color.grey_165};
  }

  .due-date, .task-status {
    font-weight: 400;
    color: ${theme.color.orange_25};
    margin-top: ${dim._4px};
    font-size:  ${dim._12px};;
  }
  .due-date-wrapper{
    display: flex;
    justify-content: space-between;
  }
  .tags{
    text-transform: capitalize;
    max-width: 100%;
    overflow:hidden;
    text-overflow: ellipsis;
  }
`;