import React, {useState} from "react";
import {EditableRowStyle} from "./EditableRowStyle";
import {AiOutlineCheck} from "react-icons/ai";
import {RxCross2} from "react-icons/rx";
import {useSelector} from "react-redux";
import Select from "react-select";
import {dim} from "../../../../../styles/theme";
const EditableRow = ({items, handleEditClick, editId, componentTitle}) => {
  const [data, setData] = useState({
    label: items.label ? items.label : "",
    amount: items.amount ? items.amount : "",
    type: items.type ? items.type : "",
    manualLabel: items.manualLabel ? items.manualLabel : "",
  });
  const deductionComponents = useSelector(
    (state) => state.PayslipManualReviewReducer.deductionComponents
  );

  const earningComponents = useSelector(
    (state) => state.PayslipManualReviewReducer.earningComponents
  );
  const [componentArr, setComponentArr] = useState(
    componentTitle === "Deduction" ? deductionComponents : earningComponents
  );
  const handleChange = (e) => {
    const {value, name} = e.target;
    if (name === "amount") {
      setData({...data, [name]: value.toUpperCase()});
    } else if (name === "manualLabel") {
      setData({...data, manualLabel: value});
    } else {
      setData({...data, [name]: value.toUpperCase()});
    }
  };
  const handleDropDownChange = (value) => {
    const {type} = value;
    setData({...data, type: type, label: value.label});
  };
  const handleDropDownOtherChange = (value) => {
    setData({...data, type: undefined, label: value.label});
  };
  const componentStyles = {
    control: (styles) => ({
      fontSize: dim._12px,
      border: "none",
      borderBottom: `${dim._1px} solid #808080`,
      outline: "none",
      width: "auto",
      margin: "0",
      padding: "0",
      boxSizing: "border-box",
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        padding: "0px",
        paddingLeft: "0px",
        width: "auto",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingDown: "0px",
      };
    },
    input: (provided, state) => {
      return {
        ...provided,
        padding: "0px",
        paddingLeft: "0px",
        width: "auto",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingDown: "0px",
      };
    },
  };

  return (
    <EditableRowStyle active={data.label.length > 0 && data.amount.length > 0}>
      <div className="sideWrapper">
        <Select
          options={[
            ...componentArr?.map((d, i) => {
              return {...d, label: d.label};
            }),
          ]}
          value={
            data.label && {
              label: data.label,
            }
          }
          isSearchable={true}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          getOptionValue={(option) => option.label}
          className="component-name"
          placeholder="Search..."
          styles={componentStyles}
          onChange={(value) => {
            value.__isNew__
              ? handleDropDownOtherChange(value)
              : handleDropDownChange(value);
          }}
        />

        <input
          type="text"
          label={""}
          autoComplete="off"
          value={data.manualLabel}
          onWheel={(e) => e.target.blur()}
          placeholder={"Manual label ..."}
          name={"manualLabel"}
          onChange={handleChange}
        />
      </div>
      <div className="parentPos sideWrapper">
        <input
          type="number"
          label={""}
          autoComplete="off"
          value={data.amount}
          onWheel={(e) => e.target.blur()}
          placeholder={"Amount"}
          name={"amount"}
          onChange={handleChange}
        />
        <AiOutlineCheck
          className="icon  submit"
          onClick={() =>
            data.label.length > 0 && data.amount.length > 0
              ? handleEditClick(editId, false, "save", data)
              : null
          }
        />
        {
          <RxCross2
            className="icon"
            style={{
              background: "#ff4136",
              cursor: "pointer",
              right: dim._10px,
            }}
            onClick={() => {
              handleEditClick(
                editId,
                false,
                "cancel",
                "",
                items.isNew ? items.isNew : false
              );
            }}
          />
        }
      </div>
    </EditableRowStyle>
  );
};

export default EditableRow;
