import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

export const SentryActivate=()=>{
    const SENTRY_DSN=process.env.REACT_APP_SENTRY_DSN;
    const ENV_PRODUCTION = process.env.REACT_APP_ENVIRONMENT;

ENV_PRODUCTION === 'PRODUCTION' &&
    Sentry.init({
        dsn:SENTRY_DSN ,
        integrations: [new BrowserTracing()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
      });
}