import styled from "styled-components";
import { dim } from "../../styles/theme";

export const StyledCsrAssignment = styled.div`
width: 100%;
  .dropdown-container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: left;
    gap: ${dim._40px};
    margin:  ${dim._20px} ${dim._0px} ${dim._0px} ${dim._0px};
    box-sizing: border-box;
  }

  .assign-button{
    margin-left:  ${dim._scale(100)};
  }

  .users-table{
    padding-top: ${dim._30px};
  }
`;
