import React, { useState } from "react";
import { Modal, Spin } from "antd";
import { TASK_STATUS } from "../constant";
import { StyledCsrTaskModal } from "./StyledCsrTaskModal";
import { USER_ROLE } from "../../../constants/userRole";
import { CsrTaskService } from "../../../Services/CsrTaskService";
import toast from "react-hot-toast";
import { getFormattedDate } from "../../../utils/timeUtils";
import { Form, Input } from "antd";

const { TextArea } = Input;

const CsrTaskModal = ({ isVisible, onCancel, task, userRole, onSuccess = () => { } }) => {

  const [loading, setLoading] = useState(false);
  const [remark,setRemark] = useState('');

  const handleMarkComplete = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      setLoading(true);
      const body = {
        'status': TASK_STATUS.COMPLETED,
        'remark':remark
      }
      const markCompleted = await CsrTaskService.updateCsrTaskStatus(task?.task_detail?.task_id, body);
      toast.success("Marked as completed successfully");
      onSuccess();
      onCancel();
    }
    catch (e) {
      toast.error("Something went wrong")
    }
    finally {
      setLoading(false)
    }
  };

  return (
    <Modal
      title="Task Details"
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
    >
      <StyledCsrTaskModal>
        <div className="modal-wrapper">
          <div className="task-info">
            <h3>{task?.task_detail?.title}</h3>
            <div dangerouslySetInnerHTML={{ __html:task?.task_detail?.content}}></div>
            {task?.task_status === TASK_STATUS.ACTIVE && (
              <div className="due-date-wrapper">
                <p className="due-date">
                  Due Date:{" "}
                  {
                    getFormattedDate(task?.task_detail?.due_date)
                  }
                </p>
              </div>
            )}
            <p className="task-status">{task?.task_status}</p>
            <p className="tags">
              Tags:{" "}
              {task?.task_detail?.tags
                .join(", ")
                .replaceAll("_", " ")
                .toLowerCase()}
            </p>
            <p className="remarks">
              Created by : {task?.created_by?.first_name}
            </p>
            {
              task?.task_status === TASK_STATUS.COMPLETED &&
              <p className="remarks">Closing Remarks: <span >{task?.task_detail?.remark} </span></p>

            }
          </div>
          {task?.task_status === TASK_STATUS.ACTIVE && (userRole === USER_ROLE.OPERATION_EXECUTIVE || userRole === USER_ROLE.ADMIN)  && (
            <div>
              <Form.Item label="" required>
                <TextArea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  placeholder="Enter Closing Remark"
                  rows={2}
                  required
                />
              </Form.Item>
              <button
                className={remark ? 'mark-complete-btn' :'disabled'}
                onClick={(e) => handleMarkComplete(e)}
              >
                {loading ? 'Loading...' : "Mark Completed"}
              </button>
            </div>
          )}
        </div>
      </StyledCsrTaskModal>
    </Modal>
  );
};

export default CsrTaskModal;
