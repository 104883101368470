import BASE_URL from "./API_ENDPOINT";
import { getAuthToken, httpClient } from "./httpUtil";

export const PreSignedUrl = {
  uploadPreSignedUrl: async ( user_id, fileName, bucketName = "") => {
    const url = `${BASE_URL}user-files/super-saver-client-docs/presigned-s3url-for-upload?userId=${user_id}&fileName=${fileName}&bucketName=${bucketName}&bucketSuffix=`;
    const response = await httpClient.get(url);
    const data = await response?.data;
    return data;
  },

  downloadPreSignedUrl: async (idToken, userId, fileName, bucketName = "",fileType) => {
    const url = (bucketName = ""
      ? `${BASE_URL}user-files/super-saver-client-docs/presigned-s3url-for-download?userId=${userId}&fileName=${fileName}&bucketSuffix=`
      : `${BASE_URL}user-files/super-saver-client-docs/presigned-s3url-for-download?userId=${userId}&fileName=${fileName}&bucketSuffix=&bucketName=${bucketName}`);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `${idToken}`,
        Source: "cms",
      },
    });
    const data = await response.text();

    return data;
  },
};
