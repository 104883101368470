import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import LoaderPage from "../../commonComponents/LoaderPage";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import { getSuperSaverUser } from "../../Services/SuperSaver";
import {
  columnsForAdvisor,
  getPrimaryAdvisorName,
} from "../../utils/superSaverUser";
import { AssignedUsersStyled } from "./AssignedUsers.styled";
import { userActions } from "./store/reducer";
import { TabContext, TabPanel } from "@mui/lab";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import ActionFilter from "../../components/ActionFilter";
import Toggle from "../../commonComponents/Toggle/Toggle";
import AdvisorService from "../../Services/AdvisorService";
import toast from "react-hot-toast";

const call_status = {
  CALL_SUMMARY_PENDING: "CALL_SUMMARY_PENDING",
  TOMORROW_CALL: "TOMORROW_CALL",
  TODAY_CALL: "TODAY_CALL",
};
const tabList = [
  {
    label: "All User",
    value: "ALL",
  },
  {
    label: "Super Saver User",
    value: "SUPER_SAVER_USER",
  },
  {
    label: "ITR Filing User",
    value: "ITR_FILING_USER",
  },
  {
    label: "Expired User",
    value: "EXPIRED_USER",
  },
];
const AssignedUsers = () => {
  const selectedView = localStorage.getItem(
    LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE
  );
  const [Data, setData] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState("PRIMARY");

  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(
    (tabList.some((d) => d.value === selectedView) && selectedView) || "ALL"
  );
  const { user_roll_id: userRollId } = JSON.parse(
    localStorage.getItem(LOCAL_DB_KEYS.CURRENT_USER)
  );
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    localStorage.setItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE, newValue);
    setValue(newValue);
    navigate("/users");
  };
  const getUserList = async (email, param, filter) => {
    setLoader(true);

    try {
      const data = await AdvisorService.getAllUserByAdvisorId(
        userRollId,
        selectedUserType,
        (value !== "ALL" && value) || null
      );
      const userList = await data.data;
      setData(userList);
      setLoader(false);
    } catch (error) {

      toast.error(error.message);
    }
  };
  useEffect(() => {
    getUserList();
  }, [value, selectedUserType]);

  useEffect(() => {
    dispatch({ type: userActions.ADD_ASSIGNED_USERS, payload: Data });
  }, [Data]);

  function rowFunction(value, index, array) {
    const {
      referral_code,
      targeted_tax,
      category_id,
      category_type,
      invite_id,
      onboard_id,
      user_id,
      activity_details,
      invitation_status,
      advisor_details,
      app_version,
    } = value;
    return {
      id: index,
      subscription_status: value?.subscription_status
        ? value?.subscription_status
        : "",
      name: value?.user_response?.first_name,
      referral_code: referral_code,
      service_type: category_type,
      targetTax: targeted_tax,
      super_saver_booking_id: value?.external_id,
      service_id: category_id,
      email: value?.user_response?.email,
      invite_id: invite_id,
      onboard_id: onboard_id,
      user_id: user_id,
      activity_details: activity_details,
      invitation_status: invitation_status,
      advisor: getPrimaryAdvisorName(value),
      advisor_details: value?.assigned_advisor_details?.filter((item) => item.type === "PRIMARY")[0],
      appVersion: app_version,
      appVersions:
   value?.meta_data_payload?.app_version,
   csr_name: value?.csr_user ? value?.csr_user?.name : "-",
    };
  }
  const handleOnCellClick = (params, navigate) => {
    const userId=params?.row?.user_id ;
    navigate(`/userProfileForAdvisor?user_id=${userId}`, {
      state: { item: params.row },
    });
  };

  return loader ? (
    <LoaderPage />
  ) : (
    <AssignedUsersStyled className="super">
      <TabContext value={value} variant="fullWidth">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tabList.map((d, i) => {
              return <Tab {...d} key={i} sx={{ width: 200 }} />;
            })}
          </TabList>
        </Box>
        {
          <Toggle
            selectedValue={selectedUserType}
            firstValue={"Primary"}
            secondValue={"Secondary"}
            handleSelectedValue={(value) => {
              setSelectedUserType(value.toUpperCase());
            }}
          />
        }
        <TabPanel value="ALL" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={columnsForAdvisor}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
        <TabPanel value="ITR_FILING_USER" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={columnsForAdvisor}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
        <TabPanel value="SUPER_SAVER_USER" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={columnsForAdvisor}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
        <TabPanel value="EXPIRED_USER" sx={{ width: "100%", padding: 0 }}>
          <div className="resultS">
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={columnsForAdvisor}
                handleOnCellClick={handleOnCellClick}
                rowFunction={rowFunction}
                enablePaging={true}
                pathname="/"
              />
            </CardAddSlot>
          </div>
        </TabPanel>
      </TabContext>
    </AssignedUsersStyled>
  );
};

export default AssignedUsers;
