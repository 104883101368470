import React from "react";
import {StyledConsultationBar} from "./ConsultationBar.styled";
import {CustomizedButton} from "../../../../commonComponents/Button";
import {dim, theme} from "../../../../styles/theme";
import {timeStampDateConverter} from "../../../../utils/superSaverUser";
import {
  changeDateFormate,
  isTimestampMoreThanRequiredHoursOld,
} from "../../../../utils/timeUtils";
import {convertToPascalCase} from "../../../../utils/textUtil";
import {PosthogActions} from "../../../../Integrations/Posthog/constant";
import posthog from "posthog-js";
const ConsultationBar = ({meeting_link, start_time, end_time, type, user}) => {
  const requiredTitle =
    convertToPascalCase(type?.replaceAll("_", " ")) + " with " + user?.name;

  const duration =
    timeStampDateConverter(start_time) +
    " - " +
    timeStampDateConverter(end_time);

  const actionTitle = "Join";

  const disableMessage =
    "The button will be enabled before 2 hours of call time";
  const disabled = isTimestampMoreThanRequiredHoursOld(start_time);
  return (
    <StyledConsultationBar>
      <div className="callDetails">
        <div className="callTitle">{requiredTitle}</div>
        <div className="callTime">{duration}</div>
      </div>
      <CustomizedButton
        disabled={disabled}
        style={{
          background: theme.color.green_20,
          color: theme.color.white_0,
          weight: "600",
          fontSize: dim._12px,
          width: "fit-content",
        }}
        onClick={() => {
          posthog.capture(PosthogActions.JOIN_BUTTON_USED, {
            url: meeting_link,
            clickLocation: "dashboard",
          });
          window.open(meeting_link, "_blank");
        }}
      >
        {actionTitle}
      </CustomizedButton>
    </StyledConsultationBar>
  );
};

export default ConsultationBar;

export const ConsultationBarForMarkingDue = ({
  start_time,
  end_time,
  type,
  user,
}) => {
  const requiredTitle =
    convertToPascalCase(type)?.replaceAll("_", " ") + " with " + user?.name;

  const duration =
    timeStampDateConverter(start_time) +
    " - " +
    timeStampDateConverter(end_time);

  return (
    <StyledConsultationBar
      style={{
        paddingTop: dim._18px,
        paddingBottom: dim._18px,
      }}
    >
      <div className="callDetails">
        <div className="callTitle">{requiredTitle}</div>
      </div>
      <div className="callTime">
        {" "}
        {changeDateFormate(start_time)} | {duration}
      </div>
    </StyledConsultationBar>
  );
};
