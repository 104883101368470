import React from "react";
import { StyledArticleTopbar } from "./ArticleTopbar.styled";
import Button from "../../../../commonComponents/Button";
import { Chip } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router";
import { getFormattedDateMonthYear } from "../../../../utils/timeUtils";

const ArticleTopbar = ({ content, saveDraft, preview, handleUnPublish }) => {
  const category =
    content?.article_category?.replaceAll(/_/gi, " ") || "Not Found";
  const { month, day, year, time } = getFormattedDateMonthYear(
    content?.updated_on
  );

  const date =
    content?.updated_on &&
    "Last updated on " + `${month} ${day}, ${year} ${time}`;
  const navigate = useNavigate();

  return (
    <StyledArticleTopbar>
      <img
        className="arrow"
        src={process.env.PUBLIC_URL + "/assets/icons/arrow.svg"}
        alt="arrow"
        onClick={() => {
          navigate(
            "/articles/" +
              content?.article_category?.toLowerCase().replaceAll("_", "-")
          );
        }}
      />

      <div className="articleDetail">
        <div className="articleInfoTitle">
          Article Detail
          {content?.article_status && (
            <Chip
              sx={{
                height: "auto",
                backgroundColor: "#fff",
                borderRadius: "16px",
                border: "1px solid #764FDB",
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                  color: "#764FDB",
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  textTransform: "capitalize",
                },
              }}
              label={content?.article_status}
            />
          )}
        </div>
        <div className="categoryInfo">
          {category}
          {date && `| ${date}`}
        </div>
      </div>

      <div className="articleCta">
        <Button
          style={{
            borderRadius: "8px",
            border: "1.2px solid #05387E",
            color: "#05387E",
            background: "#FFF",

            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "20px",
            textTransform: "capitalize",
            padding: "10px 20px",
            whiteSpace: "nowrap",
          }}
          onButtonClick={() => {
            content?.article_status === "PUBLISHED"
              ? handleUnPublish()
              : saveDraft();
          }}
        >
          {content?.article_status === "PUBLISHED"
            ? "unpublish"
            : "Save as draft"}
        </Button>
        <Button
          style={{
            borderRadius: "8px",
            background: " #05387E",
            color: "#fff",
            boxShadow: " 0px 1px 3px 0px rgba(16, 24, 40, 0.15)",
          }}
          onButtonClick={() => {
            preview();
          }}
        >
          Preview
        </Button>
      </div>
    </StyledArticleTopbar>
  );
};

export default ArticleTopbar;
