import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { StyledCsrTaskHtmlEditor } from "./StyledCsrTaskEditor";

const CsrTaskHtmlEditor = ({ onChange }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorChange = (state) => {
    setEditorState(state);
    const htmlContent = draftToHtml(convertToRaw(state.getCurrentContent()));
    onChange(htmlContent);
  };

  return (
    <StyledCsrTaskHtmlEditor>
      <div className="rich-text-editor">
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbar={{
            options: ["list"],
            list: { inDropdown: false, options: ["unordered"] },
          }}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
        />
      </div>
    </StyledCsrTaskHtmlEditor>
  );
};

export default CsrTaskHtmlEditor;
