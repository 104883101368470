import React, { useState } from "react";
import { StyledPayslipReview } from "./PayslipReview.styled";
import { useSearchParams } from "react-router-dom";
import PDFViewer from "../../../../commonComponents/PDFViewer";
import ManualUpdatePayslip from "../../component/ManualUpdatePayslip";
import SuccessMessage from "../../component/SuccessMessage";
import { useEffect } from "react";
import { PayslipRead } from "../../../../Services/PayslipReadApi";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import toast from "react-hot-toast";
import { modifySalaryComponentToRemoveUnusedData } from "../../utils";

const PayslipReview = () => {
  const [searchParams] = useSearchParams();
  const [organizationName, setOrganizationName] = useState('')
  const documentIdentifier = searchParams.get("documentIdentifier");
  const reviewerId = searchParams.get("reviewerId");
const [salaryComponents, setSalaryComponents] = useState({earning:[],deduction:[]})
  const [isReviewed, setIsReviewed] = useState(false)
const [pdfUrl, setPdfUrl] = useState(null)
const [loading,setLoading] = useState(false);
const [uploadMonth,setUploadMonth] = useState({});
const [nonVerifiedPayslipMonths, setNonVerifiedPayslipMonths] = useState([]);
const [availableMonths, setAvailableMonths] = useState([]);
const [hasAlreadyApprovedPayslipInMonth,setHasAlreadyApprovedPayslipInMonth] = useState(false);
const fyId = localStorage.getItem(LOCAL_DB_KEYS?.FINANCIAL_YEAR_ID);

const months = [
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
  "JANUARY",
  "FEBRUARY",
  "MARCH",
];
  
  const { user_roll_id: userRollId } = JSON.parse(
    localStorage.getItem(LOCAL_DB_KEYS.CURRENT_USER)
  );
  useEffect(() => {
    (async () => {
      setLoading(true);
      try{
        const {data:url} = await PayslipRead.getPresignedS3Url(documentIdentifier,userRollId);
  
      setPdfUrl(url)
      const payslipManualReview = await PayslipRead.payslipManualReview(documentIdentifier,
        userRollId,
        fyId
      );
      const {data}=payslipManualReview

      if(payslipManualReview?.data?.auto_parsed_document_data){
        setIsReviewed(true)
      }setOrganizationName(data?.organization_name)
      modifySalaryComponent(data?.earnings?.earning_type_to_amount_map,'earning',data?.organization_name);
      modifySalaryComponent(data?.deductions?.deduction_type_to_amount_map,'deduction',data?.organization_name)

      if(payslipManualReview?.data?.uploaded_for_month){
        const monthOfUpload= {
          label: payslipManualReview?.data?.uploaded_for_month,
          value: payslipManualReview?.data?.uploaded_for_month
        }
        setUploadMonth(monthOfUpload);
        setAvailableMonths(payslipManualReview?.data?.non_verified_payslip_months);
      }

      const enabledOptions = payslipManualReview?.data?.non_verified_payslip_months;
      const options = months?.map((item) => {
        return {
          label: !enabledOptions.includes(item) ? item+` (Verified)` : item,
          value: item,
          isDisabled: enabledOptions && !enabledOptions.includes(item)
        }
      });
      setNonVerifiedPayslipMonths(options);
      setAvailableMonths(payslipManualReview?.data?.non_verified_payslip_months);
      }catch(error){
        toast.error("Error fetching paylip data");
      }finally{
        setLoading(false);
      }
    })();
  }, []);

  useEffect( () => {
    const hasApprovedPayslip = availableMonths && !availableMonths.includes(uploadMonth?.value);
    setHasAlreadyApprovedPayslipInMonth(hasApprovedPayslip);    
  }, [availableMonths,uploadMonth]);

  const getModifiedComponent=(arr)=>{
    const newObj={}
    arr?.filter((d)=>{

      return +d.amount>0
    })
    ?.map((d)=>{
  
        const data= {
            amount:{amount_value:+d.amount},
            component_label:d.manualLabel ? d.manualLabel : d.label,
            did_we_parse:false
          }
          newObj[d.type] = newObj[d.type] ? newObj[d.type].concat(data) : [data];
        })
        return newObj  
   }
  

  const submit=async(earningComponent,deductionComponent,manualReviewStatus)=>{
    try {
      
   
 
    const deduction_type_to_amount_map= manualReviewStatus==='COMPLETED'?getModifiedComponent(deductionComponent):{};
    const earning_type_to_amount_map= manualReviewStatus==='COMPLETED'?getModifiedComponent(earningComponent):{};

const data = {
  document_identifier:documentIdentifier,
  manually_updated_document_data: {
    currency_unit: "INR",
    deductions: {
      deduction_type_to_amount_map,
   
    },
    earnings: {
      earning_type_to_amount_map,
    },
    organization_name:organizationName,
  },
  uploaded_for_month: uploadMonth?.value,
  manual_review_status:manualReviewStatus,
  user_id: reviewerId
}

    const res = await PayslipRead.postPayslipManualReview(data,userRollId);
    toast.success(`Processed ${uploadMonth?.value} payslip...`);
    setIsReviewed(true)
  } catch (error) {
    console.log(error,'error');
    toast.error('Opps something went wrong')  
  }

  }


  const modifySalaryComponent=(amount_map,Type,organization_name)=>{
   
    const salaryComponent=amount_map && Object.entries(amount_map).flatMap(item => {
      return item[1].map(sc => {
        return [item[0], sc];
      });
    }).map((d)=>{
      const type=d[0]
      const amountObj=d[1]
      return {
        label:amountObj.component_label,
        amount:amountObj.amount.amount_value,
        type,  
        editStatus: false,
        isEditable: true,
        manualLabel: "",
        id:crypto.randomUUID()
      }
    })
    setSalaryComponents(state=>{ return {...state,organization_name,[Type]:modifySalaryComponentToRemoveUnusedData(salaryComponent)
   
        }})
    return salaryComponent
  }
 
 
  

  return (
    <StyledPayslipReview>
      <PDFViewer src={pdfUrl} style={{ width: "50%" }} />
      {!isReviewed ? (
        <ManualUpdatePayslip
          submit={submit}
          month={uploadMonth}
          loading={loading}
          setUploadMonth={setUploadMonth}
          nonVerifiedPayslipMonths={nonVerifiedPayslipMonths}
          EarningComponent={salaryComponents.earning}
          DeductionComponent={salaryComponents.deduction}
          companyName={salaryComponents?.organization_name}
          hasAlreadyApprovedPayslipInMonth={hasAlreadyApprovedPayslipInMonth}
        />
      ) : (
        <SuccessMessage />
      )}
    </StyledPayslipReview>
  );
};

export default PayslipReview;
