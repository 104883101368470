import React, { useEffect, useLayoutEffect, useState } from "react";
import { StyledCallSummary } from "./CallSummary.styled";
import ConsultationCommentModel from "../../components/ConsultationCommentModel";
import ConsultationRatingModel from "../../components/ConsultationRatingModel";
import {
  errorValidator,
  findUndefinedProperties,
  handleSubmitMom,
} from "../../utils";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import { CALL_SUMMARY_TYPE } from "../../constant";
import { objectEmptyValidater } from "../../../../utils/validate";
import { modifyMetaData } from "../../../../utils/modifyMetaData";
import NoteEditor from "./AdvisorNotes/NoteEditor";
import NoteViewer from "./AdvisorNotes/NoteViewer";
import NotesGrid from "./AdvisorNotes/NotesGrid";
import { AdvisorNotesService } from "../../../../Services/AdvisorNotesService";
import { sortArrayAndAppendOtherAtLast } from "../../../../utils/sortArrayAndAppendOtherAtLast";

const commentObj = {
  type: "",
  text: "",
};

const getRating = (value) => {
  if (value < 4) {
    return "Average";
  }
  if (value < 8) {
    return "Good";
  }

  return "Excellent";
};

const CallSummary = ({ call_booking_id, id, setLoader, loader }) => {
  const CALL_SUMMARY_TYPE = useSelector(
    (state) => state.cmsReducer.discussion_type
  );
  const callSummary = useSelector((state) => state.ConsultationReducer.momData);
  const [commentList, setCommentList] = useState([commentObj]);

  const [dropDownArrData, setDropDownData] = useState(
    modifyMetaData(CALL_SUMMARY_TYPE)
  );
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [clientSatisfaction, setClientSatisfaction] = useState({
    rate: undefined,
    text: "",
  });
  const [isError, setIsError] = useState({ error: false, errorArr: [] });
  const defaultValue = "Select Discussion Type";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notes,setNotes] = useState([]);
  const [saveNewNotes,setSaveNewNotes] = useState('');

  const [searchParams] = useSearchParams();
  const handleDropDownChange = (value, index, e) => {
    if (value === defaultValue) return null;
    const newArr = dropDownArrData.map((d, i) => {
      return {
        ...d,
        isSelected: d.label === value,
      };
    });
    setDropDownData(newArr);
    handleCommentChange(e, index);
  };
  const handleCommentChange = (e, index) => {
    const { name, value } = e.target;
    const newArr = commentList.map((d, i) => {
      if (index === i) {
        return { ...d, [name]: value };
      }
      return d;
    });
    setCommentList(newArr);
  };

  useLayoutEffect(() => {
    setDropDownData(modifyMetaData(CALL_SUMMARY_TYPE));
  }, [CALL_SUMMARY_TYPE]);
  const handleAddMoreDocument = () => {
    setCommentList((state) => [...state, commentObj]);
  };
  const dropdownDataOptions = modifyMetaData(CALL_SUMMARY_TYPE);
  const dropDownProps = {
    defaultValue,
    dataOptions: sortArrayAndAppendOtherAtLast(dropdownDataOptions),
    name: "type",
    handleDropDownChange: isEditDisabled ? () => {} : handleDropDownChange,
  };
  const handleSatisfactionChange = (e) => {
    const { name, value } = e.target;
    const newObj = {
      ...clientSatisfaction,
      [name]: +value,
      text: getRating(+value),
    };

    setClientSatisfaction(newObj);
  };

  function checkAddValidation() {
    const arrLength = commentList.length;
    if (arrLength === 0) return true;
    const arr = commentList.filter((d, i) => {
      return d.type !== "" && d.text !== "";
    });
    return arrLength === arr.length;
  }

  const handleSubmit = async () => {
    const client_satisfaction_rate =
      errorValidator(findUndefinedProperties(clientSatisfaction)).error ===
      false
        ? clientSatisfaction
        : undefined;
    const commentArr = checkAddValidation() ? commentList : [];

    const call_summary_id =
      localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID) === "undefined"
        ? null
        : localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID);
    const value = {
      call_summary_id,
      call_booking_id,
      written_by: id,

      call_summary_comments: {
        comments: commentArr,
        comment_status: "SUBMITTED",
        client_satisfaction_rate: client_satisfaction_rate,
      },
    };
    const errorValidation = errorValidator(
      findUndefinedProperties(value.call_summary_comments)
    );
    setIsError(errorValidation);
    errorValidation.error === false &&
      handleSubmitMom(
        id,
        value,
        setIsEditDisabled,
        navigate,
        searchParams,
        dispatch,
        setLoader
      );
  };

  const callSummaryhandle = () => {
    callSummary?.call_summary_comments?.comments &&
      setCommentList(callSummary?.call_summary_comments?.comments);

    callSummary?.call_summary_comments?.client_satisfaction_rate &&
      setClientSatisfaction(
        callSummary?.call_summary_comments?.client_satisfaction_rate
      );
    setIsEditDisabled(true);
  };
  useEffect(() => {
    const comment_submit = callSummary?.call_summary_comments?.comment_status;

    comment_submit === "SUBMITTED" && callSummaryhandle();
  }, [callSummary?.call_summary_comments]);
  const handleDeleteFile = (index) => {
    const newArr = commentList;
    newArr.splice(index, 1);
    setCommentList([...newArr]);
  };
  const userId = searchParams.get("user_id");
  const getUserNotes = async() =>{
    const call = await  AdvisorNotesService.getNotesByUserIdAndCallBookingId(userId,call_booking_id);
    setNotes(call?.data?.notes || []);
  }
  useEffect(()=>{
         getUserNotes();
  },[saveNewNotes])

  return (
    <StyledCallSummary isEditDisabled={isEditDisabled}>
      <div className="formContainer">
        <ConsultationCommentModel
          commentList={commentList}
          handleCommentChange={isEditDisabled ? () => {} : handleCommentChange}
          handleAddMoreDocument={
            isEditDisabled ? () => {} : handleAddMoreDocument
          }
          isError={isError}
          componentCategory={"comments"}
          isEditDisabled={isEditDisabled}
          dropDownProps={dropDownProps}
          handleDeleteFile={handleDeleteFile}
        />
        <ConsultationRatingModel
          clientSatisfaction={clientSatisfaction}
          handleSatisfactionChange={
            isEditDisabled ? () => {} : handleSatisfactionChange
          }
          isError={isError}
          componentCategory={"client_satisfaction_rate"}
        />
       
      </div>
     
      <div
        className={
          isEditDisabled || loader ? "greenButton disabled" : "greenButton"
        }
        onClick={() => (isEditDisabled || loader ? () => {} : handleSubmit())}
      >
        Submit MOM
      </div>
      <div className="notesSection">
        <NotesGrid notes={notes}/>
        <div className="note-editor-section">
          <p className="leave-a-note-header">Add a note </p>
          <NoteEditor  setSaveNewNotes={setSaveNewNotes} callBookingId={call_booking_id}/>
        </div>

      </div>
    </StyledCallSummary>
  );
};

export default CallSummary;
