import { dateFormatter } from "../../../../utils/timeUtils";

export const onboardingArray = (onboardingData) => {
  let arr = [];
  onboardingData.expenses &&
    onboardingData.expenses.house_rental_expense &&
    arr.push({
      title: "House rent paid per month",
      priority: 17,
      value: onboardingData.expenses.house_rental_expense,
    });
  onboardingData.health_details &&
    (() => {
      onboardingData.health_details.family_member_disabilities_identifier &&
        arr.push({
          title: "Family members have the  Disabilities",
          priority: 16,
          value:
            onboardingData.health_details.family_member_disabilities_identifier,
        });
      onboardingData.health_details.health_insurance_identifier.length > 0 &&
        arr.push({
          title: "Health insurance in this financial year",
          priority: 15,
          value:
            onboardingData.health_details.health_insurance_identifier.join(
              ", "
            ),
        });
      onboardingData.health_details
        .special_disease_medical_expense_identifier &&
        arr.push({
          title: "Special disease medical expense ",
          priority: 14,
          value:
            onboardingData.health_details
              .special_disease_medical_expense_identifier,
        });
    })();
  onboardingData.investments &&
    (() => {
      onboardingData.investments.nps_identifier &&
        arr.push({
          title: "Nps",
          priority: 13,
          value: onboardingData.investments.nps_identifier,
        });
      onboardingData.investments.other_investments_sources.length > 0 &&
        arr.push({
          title: "Other investments sources",
          priority: 12,
          value:
            onboardingData.investments.other_investments_sources.join(", "),
        });
      onboardingData.investments.tax_saving_investment_sources.length > 0 &&
        arr.push({
          title: "Tax saving investment sources",
          priority: 11,
          value:
            onboardingData.investments.tax_saving_investment_sources.join(", "),
        });
    })();
  onboardingData.loan_details &&
    (() => {
      onboardingData.loan_details.education_loan &&
        arr.push({
          title: "Education loan",
          priority: 10,
          value: onboardingData.loan_details.education_loan,
        });
      onboardingData.loan_details.home_loan &&
        arr.push({
          title: "Home loan",
          priority: 9,
          value: onboardingData.loan_details.home_loan,
        });
    })();
  onboardingData.personal_details &&
    (() => {
      onboardingData.personal_details.legal_name &&
        arr.push({
          title: "Legal name",
          priority: 1,
          value: onboardingData.personal_details.legal_name,
        });
      onboardingData.personal_details.living_status &&
        arr.push({
          title: "Living status",
          priority: 2,
          value: onboardingData.personal_details.living_status,
        });
      onboardingData.personal_details.marital_status &&
        arr.push({
          title: "Marital status",
          priority: 6,
          value: onboardingData.personal_details.marital_status,
        });
      onboardingData.personal_details.number_of_kids &&
        arr.push({
          title: "Number of kids",
          priority: 7,
          value: onboardingData.personal_details.number_of_kids,
        });
      onboardingData.personal_details.parents_grandparents_sc &&
        arr.push({
          title: "Parents or grandparents senior citizen",
          priority: 8,
          value: onboardingData.personal_details.parents_grandparents_sc,
        });
      onboardingData.personal_details.pan_card_number &&
        arr.push({
          title: "Pan card number",
          priority: 6,
          value: onboardingData.personal_details.pan_card_number,
        });
      onboardingData.personal_details.home_loan &&
        arr.push({
          title: "Home loan",
          priority: 7,
          value: onboardingData.personal_details.home_loan,
        });
      onboardingData.personal_details.current_location &&
        onboardingData.personal_details.current_location.city &&
        arr.push({
          title: "Current location",
          priority: 4,
          value: onboardingData.personal_details.current_location.city,
        });
      onboardingData.personal_details.current_location &&
        onboardingData.personal_details.current_location.pin_code &&
        arr.push({
          title: "Pin code",
          priority: 5,
          value: onboardingData.personal_details.current_location.pin_code,
        });
      onboardingData.personal_details.dob &&
        onboardingData.personal_details.dob &&
        arr.push({
          title: "Date of birth",
          priority: 9,
          value: dateFormatter(onboardingData.personal_details.dob),
        });
    })();
  return arr;
};
