export const tabList = [
    {
      label: "Temporary Assignment",
      value: "TEMP_ASSIGN",
    },
    {
      label: "Undo Assignment",
      value: "REVERT_ASSIGN",
    },
    {
      label: "Permanent Assignment",
      value: "NEW_ASSIGN",
    },
];

export const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "E-mail", width: 200 },
    { field: "mobile", headerName: "Mobile", width: 150 },
    { field: "service_type", headerName: "Plan Type", width: 150 },
    { field: "advisor", headerName: "Primary Advisor", width: 150 },
    { field: "secondaryAdvisor", headerName: "Secondary Advisor", width: 150 },
  ];

export const rowFunction = (value, index) => {
    const {
      first_name: primaryAdvisorFirstName,
      last_name: primaryAdvisorLastName,
    } = value?.advisor_details || {};
    const {
      first_name: secondaryAdvisorFirstName,
      last_name: secondaryAdvisorLastName,
    } = value?.secondary_advisor_details || {};
    const secondaryAdvisor =
      secondaryAdvisorFirstName &&
      `${secondaryAdvisorFirstName || ""} ${secondaryAdvisorLastName || ""}`;
    const primaryAdvisor =
      primaryAdvisorFirstName &&
      `${primaryAdvisorFirstName || ""} ${primaryAdvisorLastName || ""}`;
    return {
      id: value?.user_id,
      name: value?.user_response?.first_name,
      service_type: value?.category_type,
      mobile: value?.user_response?.mobile,
      advisor: value?.advisor_details ? primaryAdvisor : "Not-Assigned",
      secondaryAdvisor: secondaryAdvisor || "Not-Assigned",
      email: value?.user_response ? value?.user_response.email : "",
    };
};