import React, { useEffect, useState } from "react";
import { StyledCsrAssignment } from "./CsrAssignment.styled";
import CustomDropdown from "./components/Dropdown";
import { getCsr, getCsrClients, reassignCsr } from "../../Services/SuperSaver";
import toast from "react-hot-toast";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Button from "../../commonComponents/Button";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import { columns, rowFunction, tabList } from "./constants";
import { getParamsForApiCall } from "./utils";

const CsrAssignment = () => {
  const [csrArr, setCsrArr] = useState([]);
  const [primaryCsr, setPrimaryCsr] = useState(null);
  const [secondaryCsr, setSecondaryCsr] = useState(null);
  const [primaryCsrOptions, setPrimaryCsrOptions] = useState([]);
  const [secondaryCsrOptions, setSecondaryCsrOptions] = useState([]);
  const [selectedAssignmentType, setSelectedAssignmentType] =
    useState("TEMP_ASSIGN");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getCsrList = () => {
    getCsr()
      .then((value) => {
        const filteredList = value?.filter((csr) => (csr?.name !== "" ));
        const csrList = filteredList.map((d) => {
          return {
            label: d?.name,
            value: d?.id,
            temp_assigned_csr_list: d?.temp_assigned_csr_list,
          };
        });
        setCsrArr(csrList);
      })
      .catch((error) => {
        toast.error(error?.message || "Something went wrong");
      });
  };

  const getCsrPermanentClients = (csr_id) => {
    setLoading(true);
    getCsrClients(csr_id)
      .then((value) => {
        const clientData = value.map((d) => {
          return {
            user_id: d?.user_id,
            category_type: d?.category_type,
            user_response: d?.user_response,
            advisor_details: d?.advisor_details,
            secondary_advisor_details: d?.secondary_advisor_details,
            created_at: d?.created_at,
          };
        });
        setData(clientData);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error?.message || "Something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    getCsrList();
  }, []);

  useEffect(() => {
    setSecondaryCsr(null);
    setSelectedUsers([]);
    if(primaryCsr && selectedAssignmentType ==="NEW_ASSIGN"){
      getCsrPermanentClients(primaryCsr?.value);
    }
  }, [primaryCsr]);

  useEffect(() => {
    const options = csrArr?.sort((a, b) => a.label.localeCompare(b.label));
    setPrimaryCsrOptions(options);
  }, [csrArr]);

  function filteredOptionsForSecondaryCsr(arr) {
    let filteredOptions;
    const options = arr.filter(
      (element) => element?.value !== primaryCsr?.value
    );
    filteredOptions = options;
    if (selectedAssignmentType === "REVERT_ASSIGN") {
      filteredOptions = [];
      const optionIds = primaryCsr?.temp_assigned_csr_list;
      options.map((element) => {
        if(optionIds?.includes(element?.value)){
          filteredOptions.push(element);
        }
      })
    }
    return filteredOptions;
  }

  useEffect(() => {
    const filteredOptions = filteredOptionsForSecondaryCsr(
      primaryCsrOptions
    ).sort((a, b) => a.label.localeCompare(b.label));
    primaryCsr?.value && setSecondaryCsrOptions(filteredOptions);
  }, [primaryCsr, selectedAssignmentType, primaryCsrOptions]);

  useEffect(() => {
    setPrimaryCsr(null);
    setSecondaryCsr(null);
  }, [selectedAssignmentType]);

  const handleAssignButtonClick = () => {
    const primaryCsrId = primaryCsr?.value;
    const secondaryCsrId = secondaryCsr?.value;
    const params = getParamsForApiCall(
      primaryCsrId,
      secondaryCsrId,
      selectedAssignmentType,
      selectedUsers
    );
    reassignCsr(params)
      .then((response) => {
        toast.success(response?.message || "Operation successful");
        getCsrList();
        setSelectedUsers([]);
        setData([]);
        setPrimaryCsr(null);
        setSecondaryCsr(null);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  const handleChange = (event, newValue) => {
    setSelectedAssignmentType(newValue);
  };

  const handleRowSelection = (newSelection) => {
    setSelectedUsers(newSelection);
  };

  return (
    <StyledCsrAssignment>
      <h2>CSR Assignment</h2>
      <TabContext value={selectedAssignmentType} variant="fullWidth">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tabList.map((d, i) => {
              return <Tab {...d} key={i} sx={{ width: 300 }} />;
            })}
          </TabList>
        </Box>

        <div className="dropdown-container">
          <CustomDropdown
            heading="Select Primary(From) CSR"
            options={primaryCsrOptions}
            handleSelect={setPrimaryCsr}
            value={primaryCsr}
          />
          <ArrowRightAltIcon />
          <CustomDropdown
            heading="Select Secondary(To) CSR"
            options={secondaryCsrOptions}
            handleSelect={setSecondaryCsr}
            value={secondaryCsr}
            noOptionsMessage={() => "Selected CSR has no temporary clients"}
          />
          <Button
            onButtonClick={handleAssignButtonClick}
            buttonClassName="assign-button"
            buttonName="Assign"
          />
        </div>
        <div className="users-table">
          <TabPanel value="NEW_ASSIGN" sx={{ width: "100%", padding: 0 }}>
            <BasicExampleDataGrid
              data={data}
              columns={columns}
              rowFunction={rowFunction}
              checkboxSelection={true}
              loading={loading}
              onRowSelectionModelChange={handleRowSelection}
              rowSelectionModel={selectedUsers}
            />
          </TabPanel>
        </div>
      </TabContext>
    </StyledCsrAssignment>
  );
};

export default CsrAssignment;
