import React, { useState } from "react";
import { useLocation } from "react-router";
import {
  Back,
  ClientDetails,
  Front,
  StyledSuperClientProfile,
} from "./SuperSaverProfile.styled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import PopupBtn from "../../commonComponents/Popup/Popup";
import {
  assignAdvisor,
  getadvisor,
  getSuperSaverUser,
  inviteUser,
} from "../../Services/SuperSaver";
import { getAuth } from "firebase/auth";
import Alert1 from "../../commonComponents/Alert/Alert1";
import { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import LoaderPage from "../../commonComponents/LoaderPage";
import AdvisorSelection from "../SuperSaverUserProfile/Components/AdvisorSelection";

const SuperClientProfile = () => {
  const state = useLocation();
  const item = state.state.item;
  const [isCopied, setIsCopied] = useState(false);
  const [loader, setLoader] = useState(true);
  const [checked, setchecked] = useState(item.payment_status === "SUCCESS");
  const [open, setopen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [userId, setUserId] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [advArr, setAdvArr] = useState([]);
  const [advisorId, setAdvisorId] = useState("");
  const [reInvitetion, setReInvitetion] = useState(false);
  const [secondaryAdvisorId, setSecondaryAdvisorId] = useState(null);
  const [status, setStatus] = useState([
    {
      placeholder: "Payment status",
      value: item.payment_status === "SUCCESS",
    },
  ]);

  const handleChange = (event) => {
    if (checked) {
    } else {
      setopen(true);
    }
  };

  const handleMsg = (msg) => {
    setAlertMsg(msg);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };
  const handleUserId = (id) => {
    setUserId(id);
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const clientArray = [
    {
      placeholder: "Name",
      value: item.name,
    },
    {
      placeholder: "Mobile No",
      value: item.mobile,
    },
    {
      placeholder: "Service type",
      value: item.service_type,
    },
    {
      placeholder: "Service Id",
      value: item.service_id,
    },

    {
      placeholder: "super saver booking id",
      value: item.super_saver_booking_id,
    },

    {
      placeholder: "Email",
      value: item.email,
    },
  ];

  useEffect(() => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (token) {
          getSuperSaverUser().then((data) => {
            var userData = data.filter((d, i) => {
              return d.user_response.email === item.email;
            })[0];
            if (userData !== undefined) {
              setStatus([
                {
                  placeholder: "Payment status",
                  value: userData.payment_status === "SUCCESS",
                },
              ]);
              if (userData.payment_status == "SUCCESS") {
                setchecked(true);
              } else {
                setchecked(false);
              }
              setUserId(userData.user_id);
              userData.advisor_details !== undefined
                ? setAdvisorId(userData.advisor_details.external_id)
                : setLoader(false);
              const secondaryAdvId =
                userData?.secondary_advisor_details?.external_id;
              secondaryAdvId && setSecondaryAdvisorId(secondaryAdvId);
              getadvisor(token).then(function (value) {
                var arr = value.map((d, i) => {
                  return {
                    label:
                      d.user_details.first_name +
                      " " +
                      d.user_details.last_name,
                    advisorId: d.user_details.external_id,
                  };
                });
                setAdvArr(arr);
                setLoader(false);
              });

              setReInvitetion(
                userData.invitation_status === "INVITED" ? true : false
              );
            } else {
              setLoader(false);
            }
          });
        });
      }
    });
  }, [item.email, userId]);

  const assignExpert = (req) => {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        user.getIdToken().then(function (data) {
          assignAdvisor(data, req).then((da) => {
            da.advisor_id.length > 0 &&
              handleMsg("Advisor Updated Successfully");
          });
        });
      }
    });
  };
  const handleAdvisorUpdate = (e) => {
    const { name, value } = e.target;
    let userName = localStorage.getItem("user");
    const obj = {
      user_id: userId,

      ...(advisorId?.length > 0 && { advisor_id: advisorId }),
      ...(secondaryAdvisorId?.length > 0 && {
        secondary_advisor_id: secondaryAdvisorId,
      }),
      [name]: value,
      assigned_by: JSON.parse(userName).displayName,
    };
    e.target.value !== "" && assignExpert(obj);
    name === "advisor_id" && setAdvisorId(e.target.value);
    name === "secondary_advisor_id" && setSecondaryAdvisorId(e.target.value);
  };
  const AdvisorSelectionProps = {
    handleAdvisorUpdate,
    userId,
  };
  return loader ? (
    <LoaderPage />
  ) : (
    <StyledSuperClientProfile>
      <Alert1 ShowAlert={showAlert} mid={true} SubmitMsg={alertMsg} />
      <div className="ClientWrap">
        {clientArray.map((data, i) => (
          <div className="clientBox">
            <div className="ClientHed">{data.placeholder}</div>
            <ClientDetails className="ClientDetails">
              <div className="data">
                <Tooltip title={data.value}>
                  <span>
                    {data.value && data.value.length > 24
                      ? data.value.slice(0, 24) + " ..."
                      : data.value}
                  </span>
                </Tooltip>
              </div>
              <CopyToClipboard
                className="copyIcon"
                text={data.value}
                onCopy={onCopyText}
              >
                <span>{isCopied ? "Copied!" : <MdContentCopy />}</span>
              </CopyToClipboard>
            </ClientDetails>
          </div>
        ))}

        {userId && (
          <AdvisorSelection
            {...AdvisorSelectionProps}
            title={"Primary Advisor"}
            name={"advisor_id"}
            advisorId={advisorId}
            advArr={
              advArr.filter((d) => {
                return d.advisorId !== secondaryAdvisorId;
              }) || []
            }
          />
        )}
        {advisorId.length > 0 && (
          <AdvisorSelection
            {...AdvisorSelectionProps}
            title={"Secondary Advisor"}
            name={"secondary_advisor_id"}
            advisorId={secondaryAdvisorId}
            advArr={
              advArr.filter((d) => {
                return d.advisorId !== advisorId;
              }) || []
            }
          />
        )}
        {userId.length > 0 && advisorId.length > 0 && (
          <div className="clientox" style={{ width: "310px" }} width={310}>
            <ClientDetails
              className=""
              activate={userId.length > 0 && advisorId && advisorId.length > 0}
            >
              <div className="" style={{ margin: " 0 34px", padding: "24px" }}>
                <input type="checkbox" name="cb" id="cb" className="cb" />
                <label className="button" for="cb">
                  <Front
                    className="front"
                    onClick={() => {
                      if (userId.length > 0) {
                        getAuth().onAuthStateChanged(function (user) {
                          if (user) {
                            user.getIdToken().then(function (data) {
                              inviteUser(data, userId);
                              handleMsg("User invited successfully");
                              setReInvitetion(true);
                            });
                          }
                        });
                      }
                    }}
                  >
                    {reInvitetion ? "Re-Invite" : "Invite"}{" "}
                  </Front>
                  <Back className="back">Done </Back>
                </label>
              </div>
            </ClientDetails>
          </div>
        )}
      </div>
    </StyledSuperClientProfile>
  );
};

export default SuperClientProfile;
