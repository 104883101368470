import { getAuthToken, httpClient } from "./httpUtil";

export const PayslipRead = {
  payslipParse: (fileName) => {
    return httpClient.post(
      `payslip/parse`,
      fileName,
      {},
      {
        "Content-Type": "text/plain",
      }
    );
  },
  getPresignedS3Url: (document_identifier) => {
    return httpClient.get(
      `/user-document/get-presigned-s3-url`,
      {document_identifier}
    );
  },
  getPresignedS3UrlOld: ( fileName, userId , bucketSuffix = '') => {
    const url =`user-files/super-saver-client-docs/presigned-s3url-for-download`;
    const params = { fileName, userId, bucketSuffix };
    return httpClient.get( url, params );
  },
  parseJob: (jobId) => {
    return httpClient.get(`payslip/parsedJob?jobId=${jobId}`);
  },
  payslipManualReviewAll: async (user_id) => {
    const authToken = await getAuthToken();
    return httpClient.get(
      `payslip/manual-reviews`,
      { user_id },
      { user_id, authToken }
    );
  },
  payslipManualReview: async (document_identifier,user_id) => {
    const params = {
      document_identifier
    };
    return httpClient.get(`payslip/manual-review`, params, {user_id});
  },
  postPayslipManualReview: async (
   requestedData,user_id
  ) => {
    return httpClient.post(
      `payslip/manual-review`,requestedData,null,{user_id}
     
    );
  },
};
