import React from "react";
import { getUniqueFileName } from "../../utils/randomFileNameGenerator";
import { FileUploadService } from "../../Services/FileUploadServices";
import { useState } from "react";
import toast from "react-hot-toast";
import { StyledPromoImageLinkGenerator } from "./StyledPromoImageLinkGenerator";
import { MarketingImageUploadService } from "../../Services/MarketingImageUploadService";
import { getContentTypeOnExt } from "../../utils/getContentTypeOnExtension";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";

const PromoImageLinkGenerator = () => {
  const [promotionImgeUrl, setPromotionImgUrl] = useState("");

  const bucketName = process.env.REACT_APP_ARTICLE_BUCKET_NAME || "stage-articles-images";
  const zone = process.env.REACT_APP_BUCKET_ZONE || "eu-west-1";
  const [isLoading, setLoading] = useState(false);

  const handlePromotionImageUpload = async (e) => {
    setLoading(true);
    const file = e?.target?.files[0];
    const name = file?.name;
    const lastDot = name?.lastIndexOf(".");
    const ext = name?.substring(lastDot + 1);

    if (name?.endsWith(".svg")) {
      toast?.error("SVG File is not allowed");
      setLoading(false);
      return;
    }
    const uniqueFileName = getUniqueFileName(file, "promotion_image");
    try {
      const headers = {"Content-Type": getContentTypeOnExt(ext)};
      const params = {};
      let options = {params, headers};
      const pathname ="user-files/super-saver-client-docs/presigned-s3url-for-upload";
      const user = JSON.parse(localStorage.getItem(LOCAL_DB_KEYS.USER_DETAILS));
      const suffix = "";

      await MarketingImageUploadService.marketingImageUpload(uniqueFileName, file, bucketName,options,pathname,suffix,user);

      const imageUrl = `https://${bucketName}.s3.${zone}.amazonaws.com/${uniqueFileName}`;
      setPromotionImgUrl(imageUrl);
      toast.success("Image uploaded successfully");   
    } catch (error) {
      toast.error("Oops something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleCopyUrl = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(promotionImgeUrl).then(() => {
      toast.success("URL copied to clipboard");
    });
  };

  return (
    <StyledPromoImageLinkGenerator>
      <div className="promoImgUploadWrapper">
        <h3 className="heading">Promotion Image Link Generation</h3>
        <p className="sub-header">
          This will generate a public URL for the uploaded image which can be
          used for Promotion and Marketing
        </p>
        <input
          type="file"
          accept="image/*"
          onChange={handlePromotionImageUpload}
          style={{ display: "none" }}
          id="imageUploadInput"
        />
        <label htmlFor="imageUploadInput" className="promoImgUploadBtn">
          {isLoading ? "Uploading..." : "Upload Image"}
        </label>

        {promotionImgeUrl && (
          <div className="promoImagePreview">
            <div className="promoImg" onClick={(e) => e.stopPropagation()}>
              <img src={promotionImgeUrl} alt="Promotion Image" />
              <button className="copyUrlBtn" onClick={(e) => handleCopyUrl(e)}>
                Copy URL
              </button>
            </div>
          </div>
        )}
      </div>
    </StyledPromoImageLinkGenerator>
  );
};

export default PromoImageLinkGenerator;
