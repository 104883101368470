import React, { useState } from "react";
import Conversation from "../Conversation";
import ConversationHeading from "../ConversationHeading";
import { ChatScreenStyled } from "./ChatScreenStyled";
import { useSelector } from "react-redux";
import MessageSendContainer from "../MessageSendContainer";
import { useParams } from "react-router";
import { PreSignedUrlUpload } from "../../../../utils/preSignedUrl";
import { getFillName } from "../../../../utils/randomFileNameGenerator";
import toast from "react-hot-toast";
import DropzoneWrapper from "../../../../commonComponents/Dropzone";

const TAGS = {
	LOW: 'LOW',
	NORMAL: 'NORMAL',
	HIGH: 'HIGH',
	URGENT: 'URGENT'
};

const ChatScreen = (props) => {
  const {
    selectedChat,
    handleAttachment,
    attachment,
    userDetails,
    userId,
    advisorId,
    name,
  } = props;
  const params = useParams();
  const selectedAdvisorId = params["id"];
  const sessions = useSelector((state) => state.chat.session);
  const [docUploading, setDocUploading] = useState([]);
  const [attachmentProp, setAttachmentProp] = useState([]);
	const [attachmentInput, setAttachmentInput] = useState('');
	const [messageDetail, setMessageDetail] = useState({
		msg: '',
		label: TAGS.NORMAL,
		attachment: attachment,
		msg_by: 'client'
	});

  const checkDocStatus = (docs) => {
    setDocUploading(docs);
  };

  const handleChangeAttachment = (files) => {
    Array.from(files).forEach((file) => {
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);

      const newFilename = getFillName(fileName, ext, "chat-cms", userId);

      const ReturnValueSetter = () => {
        setAttachmentInput(""); // Clear the input value after processing
        let arr = attachmentProp;
        let index = file.name.lastIndexOf(".") + 1;
        const type = file.name.substr(index);
        arr.push({
          link: newFilename,
          file_type: type,
          name: name,
        });
        handleAttachment([...arr]);
        setMessageDetail({ ...messageDetail, attachment: arr });
      };

      const preSignedUrl = PreSignedUrlUpload(
        newFilename,
        file,
        userId,
        ReturnValueSetter
      );
      toast.promise(preSignedUrl, {
        loading: `Uploading Document...`,
        success: `Document Uploaded`,
        error: `Failed to upload document`,
      });
    });
  };

  return (
    <ChatScreenStyled>
      <DropzoneWrapper handleChangeAttachment={handleChangeAttachment} id='chat-screen-dropzone'>
        <div className="chat_conversation_components">
          <Conversation
            advisorId={advisorId}
            selectedChat={selectedChat}
            handleAttachment={handleAttachment}
            attachment={attachment}
            userDetails={userDetails}
            name={name}
            userId={userId}
          />
          {!selectedAdvisorId &&
            sessions[selectedChat] &&
            sessions[selectedChat].status === "OPEN" && (
              <MessageSendContainer
                handleAttachment={handleAttachment}
                advisorId={advisorId}
                attachment={attachmentProp}
                userId={userId}
                checkDocStatus={checkDocStatus}
                setAttachment={setAttachmentProp}
                attachmentInput={attachmentInput}
                setAttachmentInput={setAttachmentInput}
                handleChangeAttachment={handleChangeAttachment}
                setMessageDetail={setMessageDetail}
                messageDetail={messageDetail}
              />
            )}
        </div>
        {docUploading?.length > 0 && <div className="doc_backdrop"></div>}
      </DropzoneWrapper>
    </ChatScreenStyled>
  );
};

export default ChatScreen;
