import React from "react";

import { StyledDesktopPreview } from "./DesktopPreview.styled";
import { getFormattedDateMonthYear } from "../../../../../utils/timeUtils";
import renderHTML from "../../../../../utils/renderHTML";
import AuthorDetail from "../../../authorDetail";

const DesktopPreview = ({
  article,
  content,
  filteredHeadings,
  newAuthor,
  pathname,
}) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const baseURL =
    environment === "STAGING"
      ? "https://stage.prosperr.io"
      : "https://prosperr.io";
  const { month, day, year } = getFormattedDateMonthYear(article?.published_on);
  const date = month && `• ${month} ${day}, ${year}`;
  const shareArticle = [
    {
      img: "shareFbIcon.svg",
      label: "facebook",
    },
    {
      img: "shareTwitterIcon.svg",
      label: "twitter",
    },
    {
      img: "shareLinkedinIcon.svg",
      label: "linkedin",
    },
  ];

  return (
    <StyledDesktopPreview>
      <div className="box">
        <div className="container">
          <div className="title-wrapper">
            {renderHTML(content?.title, "div", "title-preview")}
          </div>

          <div className="articleInfoShare">
            <div className="logo">
              By
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/prosperr-logo.svg"}
                alt="prosperrLogo"
              />
              <div>PROSPERR</div>
            </div>
            <div className="publishedWrapper">
              Published on
              <div className="publishDate">{date}</div>
            </div>
            <div className="shareWrapper">
              <div className="shareTitle">Share on</div>
              <div className="icons">
                {shareArticle?.map((d, i) => {
                  return (
                    <img
                      className="shareIcons"
                      src={process.env.PUBLIC_URL + "/assets/icons/" + d?.img}
                      alt="prosperrLogo"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", width: "100%" }}>
          {filteredHeadings && filteredHeadings?.length > 0 && (
            <div className="tableWrapper">
              <div className="table-of-contents">
                <h2 className="table-of-content-title">In This Blog</h2>
                <div className="tableOfContentWrapper">
                  {filteredHeadings.map((heading, i) => (
                    <div
                      key={heading?.id}
                      className={
                        heading.isSelected
                          ? "Active tableOfContentText"
                          : "tableOfContentText"
                      }
                    >
                      <div style={{ cursor: "pointer" }}>{heading?.text}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div style={{ width: "100%" }} className="second-container">
            <div className="hero-section">
              <img src={content.banner_url} alt="Hero" />
            </div>

            <div className="summary-wrapper">
              <div class="summary-title">Article Brief</div>
              {renderHTML(content.summary, "div", "")}
            </div>

            <div className="body-wrapper">
              {renderHTML(content.payload, "div", "body-content")}
            </div>

            <div className="tags-wrapper">
              {content.tags &&
                content.tags.map((it) => {
                  return (
                    <div className="tag-name">
                      {it.tag || it.replaceAll("_", " ")}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <AuthorDetail
          authorData={newAuthor?.user_details}
          authorType={newAuthor?.author_category}
          data={content}
          brief={newAuthor?.brief}
          interest={newAuthor?.interest}
          path={baseURL + pathname}
          isVerified={content?.is_verified}
        />
      </div>
    </StyledDesktopPreview>
  );
};

export default DesktopPreview;
