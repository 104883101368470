import React from "react";
import { Tooltip } from "@material-ui/core";
import CopyClipboard from "../CopyClipboard";
import styled from "styled-components";

const DataGridCustomStyle = styled.div`
  width: ${(props) => props.cellWidth};
  position: relative;
  white-space: nowrap;
  &:hover .clipboard {
    display: flex;
    background-color: #f5f5f5;
    padding: 0px 10px 2px 10px;
  }
`;

const DataGridCustomTooltip = ({
  showExpiredBg = false,
  showCCB = true,
  dataVal='-',
  cellWidth = "200px",
  toTrim = false,
}) => {
  return (
    <DataGridCustomStyle cellWidth={cellWidth}>
      {showExpiredBg && <div className="background"></div>}
      {showCCB && <CopyClipboard text={dataVal} />}
      <Tooltip title={dataVal || ""}>
        <span className="userName">
          {toTrim ? dataVal.slice(0, 20) + "..." : dataVal}
        </span>
      </Tooltip>
    </DataGridCustomStyle>
  );
};

export default DataGridCustomTooltip;
