import axios from "axios";
import { LOCAL_DB_KEYS } from "../constants/localStorage";
import BASE_URL from "./API_ENDPOINT";
import { getHeaders } from "./SuperSaverClientChat";

export const MarketingImageUploadService = {
    marketingImageUpload: async (fileName, imageFile, bucketName,options,pathname,suffix,user) => {
        try {
            const url = `${BASE_URL}${pathname}?userId=${user.id}&fileName=${fileName}&bucketName=${bucketName}&bucketSuffix=${suffix}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    ...(await getHeaders()),
                    userId: user.id,
                },
            });
            const presignedUrl = await response.text();
            return await axios.put(presignedUrl, imageFile,options);
        } catch (error) {
            return error;
        }
    },
};
