import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import AdvisorProfile from "../../commonComponents/AdvisorProfile/AdvisorProfile";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import AdvisorData from "../../Context/Advisor/AdvisorData";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import BASE_URL from "../../Services/API_ENDPOINT";
import { AddSlotsStyle } from "./AddSlots.styled";
import { httpClient } from "../../Services/httpUtil";

const Addslot = ({ user }) => {
  const [dataArray, setDataArray] = useState([]);
  const a = useContext(AdvisorContext);

  useEffect(() => {
    a.setUsername(JSON.parse(localStorage.getItem("user")).displayName);
  });

  useEffect(() => {
    const url = `advisors/v1/all/cms`;
    httpClient.get(url)
      .then(function(response) {
        if (response.status === 200 || response.status === 204) {
          var data = response.data
          setDataArray(
            data?.map((item) => {
              return {
                ...item,
                isActive: false,
                isSlotsActive: false,
                isCreateSlotActive: false,
                isAppointmentsActive: false,
                isFeeActive: false,
              };
            })
          );
        }
      })
      .catch(function(ex) {
        toast.error('Cannot fetch data. Error: ' + ex?.message);
      });
  }, [] );

  return (
    <AdvisorData>
      <AddSlotsStyle>
        {dataArray.map((d, i) => (
          <div className="" key={i}>
            <CardAddSlot>
              {/* {a.Token !== false ?  */}
              <AdvisorProfile
                setData={setDataArray}
                arrayData={dataArray}
                data={d}
                Id={i}
                token={a.Token !== false ? a.Token : null}
              />
              {/* : null} */}
            </CardAddSlot>
          </div>
        ))}
      </AddSlotsStyle>
    </AdvisorData>
  );
};

export default Addslot;
