export const getParamsForApiCall = (primaryCsrId, secondaryCsrId, assign_type, user_ids=[]) => {
    let details = {
        temp_csr_id: secondaryCsrId,
        csr_ids: [ primaryCsrId ],
      };
      if(assign_type==="NEW_ASSIGN"){
        details = {
          csr_id: secondaryCsrId,
          user_ids
        }
      }
      if(assign_type==="REVERT_ASSIGN"){
        details = {
          temp_csr_id: primaryCsrId,
          csr_ids: [ secondaryCsrId ]
        }
      }
      const params = {
        assign_type,
        details
      }
      return params;
}