import styled from "styled-components";
import { dim } from "../../../styles/theme";

export const StyledCsrTaskHtmlEditor = styled.div`
  .demo-wrapper {
    padding: ${dim._12px};
    border-radius:  ${dim._8px};
    background-color: #f9f9f9;
  }

  .demo-editor {
    min-height:  ${dim._scale(150)};
    max-height:${dim._scale(200)};
    overflow-y: auto;
    padding: ${dim._8px};
    font-size: ${dim._14px};
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: ${dim._6px};
    overflow-y: auto;
  }

  .public-DraftEditorPlaceholder-root {
    color: #bfbfbf;
    font-size: ${dim._14px};
  }

  .rdw-editor-toolbar {
    border: none;
    background-color: #fafafa;
    padding: ${dim._8px};
    border-radius: ${dim._8px};
    margin-bottom:${dim._8px};
  }

  .rdw-option-wrapper {
    border-radius:${dim._4px};
    border: 1px solid #d1d1d1;
    background-color: #fff;
    transition: background-color 0.3s, color 0.3s;
  }

  .rdw-option-wrapper:hover {
    background-color: #e6f7ff;
    color: #1890ff;
    border-color: #1890ff;
  }

  .rdw-option-active {
    background-color: #1890ff;
    color: #fff;
    border-color: #1890ff;
  }
`;
