import styled from "styled-components";
import {StyledFlexWrapper} from "../../container/ActionDashboard/components/ActionWidget/ActionWidget.styled";
import {dim, theme} from "../../styles/theme";

export const StyledPopupCardForActionInfo = styled(StyledFlexWrapper)`
  padding: ${dim._40px} ${dim._38px};
  flex-direction: column;
  align-items: center;
  width: ${dim._scale(428)};
  gap: ${dim._14px};
  border-radius: ${dim._20px} ;
  background:${theme.color.white_0};
  position: relative;
  .title {
    font-size: ${dim._18px};
    font-weight: 400;
    line-height: ${dim._24px};
    text-align: center;
    color: ${theme.color.red_50} ;
  }
  .description{
    font-size: ${dim._16px};
    font-weight: 600;
    line-height: ${dim._24px};
    text-align: center;

  }
  .actionButton{
    margin-top:${dim._12px};
    border-radius: ${dim._12px};
    box-sizing: border-box;
  }
  .lastBtn{
    margin: 0;
    background: ${theme.color.white_0};
    color: ${theme.color.green_20};
    border: 1px solid  ${theme.color.green_20} ;
    border-radius: ${dim._12px} ;
    box-sizing: border-box;
  }
  .crossIcon {
		border-radius: 50%;
		position: absolute;
		border: none;
		color:${theme.color.white_0} ;
		padding: ${dim._4px};
		font-size: ${dim._30px};
		box-sizing: border-box;
    background: ${theme.color.red_50} ;
    cursor: pointer;
    right: ${dim._10px};
    top: ${dim._10px};
	}
  
  @media screen and (max-height: 650px) {
    top: ${dim._20px};
  }
`;
