import { getAuth } from "@firebase/auth";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import AmountCard from "../../commonComponents/AmountCard";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import ClientProfile from "../../container/AssignedUsers/components/ClientProfile";
import { getUserDocument } from "../../Services/SuperSaver";
import {
  convertNumberIntoINR,
  sectioncolumns,
} from "../../utils/deductionView";
import BasicExampleDataGrid from "../SuperSaverTable/SuperTable";
import { DeductionViewStyled } from "./DeductionView.styled";
import LayoutHOC from "../UserSideBar";

const DeductionView = ({ user_id }) => {
  const [Data, setData] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);

  const state = useLocation();
  const getDeduction = () => {
    state?.state &&
      getUserDocument(state?.state?.user_id).then((data) => {
        if (data !== undefined) {
          setSectionArr(data?.section_details);
          var productArr = data?.section_details.map((d) => {
            return {
              ...d,
              product_details: d?.product_details?.filter((prod) => {
                return prod?.investment_details?.length > 0;
              }),
            };
          });
          var sectionProductArr = productArr?.map((section, i) => {
            return section?.product_details?.map((d, i) => {
              return { ...d, section: section };
            });
          });
          var arr = sectionProductArr?.map((d, i) => {
            return d.map((d, i) =>
              d?.investment_details?.map((investment, i) => {
                return { ...investment, ...d };
              })
            );
          });

          var newArr = [];

          for (var i = 0; i < arr.length; i++) {
            newArr = newArr.concat(arr[i]);
          }
          var investmentArr = [];

          for (var j = 0; j < newArr.length; j++) {
            investmentArr = investmentArr.concat(newArr[j]);
          }

          setData(investmentArr);
        }
      });
  };
  useEffect(() => {
    getDeduction();
  }, []);

  const timeStampDate = (timeStamp) => {
    var date = new Date(timeStamp);
    var yyyy = date.getFullYear();
    var mm = date.getMonth() + 1; // Months start at 0!
    var dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    date = `${dd}-${mm}-${yyyy}`;
    return date;
  };

  function rowFunction(value, index, array) {
    return {
      id: index,
      name: value.name,
      section: value.section.name,
      invested_amount: convertNumberIntoINR(value.invested_amount),
      view: { value: value.doc_links.links[0], user: state.state.user_id },
      grant_total_invested_amount: convertNumberIntoINR(
        value.section.grant_total_invested_amount
      ),
      investment_date: timeStampDate(value.investment_date),
      description:
        value.description === "" ? "No-description" : value.description,
    };
  }
  return (
    <div style={{ width: "100%" }}>
      <ClientProfile userDetails={state.state} view={"Deduction View"} />
      <DeductionViewStyled>
        <div className="resultS">
          <CardAddSlot>
            <div className="cardArrange">
              {sectionArr.map((d, i) => (
                <AmountCard
                  amount={d.grant_total_invested_amount}
                  title={d.name}
                />
              ))}
            </div>
            <BasicExampleDataGrid
              data={Data}
              columns={sectioncolumns}
              rowFunction={rowFunction}
            />
          </CardAddSlot>
        </div>
      </DeductionViewStyled>
    </div>
  );
};

export default LayoutHOC(DeductionView);
