import React, { useEffect, useState } from "react";
import { DatePicker, Checkbox, Input, Button, Form, Modal, Spin } from "antd";
import moment from "moment";
import toast from "react-hot-toast";
import { CsrTaskService } from "../../../Services/CsrTaskService";
import CsrTaskHtmlEditor from "../CsrTaskHtmlEditor";



const CsrTaskCreationPopUp = ({
  isModalVisible,
  setIsModalVisible,
  onTaskCreated,
  userId,
}) => {
  const [dueDate, setDueDate] = useState(null);
  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [availableTags,setAvailableTags] = useState([])

  const fetchTags = async () =>{
    setLoading(true);
    try{
      const tags = await CsrTaskService.getCsrTaskTags();
      setAvailableTags([...tags?.data?.tags]);
    }catch(e){
      toast.error("Something went wrong");
      handleCancel();
    }finally{
      setLoading(false);
    }
  }
  useEffect(()=>{
    fetchTags();
  },[])

  const handleSubmit = async () => {
    if (!dueDate) {
      toast.error("Please select due date");
      return;
    }
    if (tags?.length === 0) {
      toast.error("Please select a tag");
      return;
    }
    if(!description){
      toast.error("Please add description");
      return;
    }

    setLoading(true);
    const taskData = {
      user_id: userId,
      due_date: dueDate,
      title,
      content: description,
      tags,
    };
    try {
      const response = await CsrTaskService.createCsrTsk(taskData);
      if (response.status === 200) {
        onTaskCreated(response?.data);
        setIsModalVisible(false);
        toast.success("Task Created successfully");
      }
    } catch (error) {
      toast.error("Someting went wrong ,please try again");
    } finally {
      setLoading(false);
    }
  };
  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const handleTagChange = (tag) => {
    if (tags?.includes(tag)) {
      setTags(tags?.filter((t) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  };
  const handleDateChange = (date) => {
    const selectedDate = new Date(date.$d);
    setDueDate(moment(selectedDate).valueOf());
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Create New CSR Task"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Action Type (Tags)" required>
            {availableTags?.map((tag) => (
              <Checkbox
                key={tag}
                checked={tags?.includes(tag)}
                onChange={() => handleTagChange(tag)}
              >
                {tag?.replaceAll("_", " ")}
              </Checkbox>
            ))}
          </Form.Item>

          <Form.Item label="Title" required>
            <Input
              value={title}
              onChange={(e) => {
                if (e?.target?.value?.length <= 100) {
                  setTitle(e?.target?.value);
                }
              }}
              placeholder="Enter task title"
              required
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <CsrTaskHtmlEditor onChange={setDescription} />
          </Form.Item>
          <Form.Item label="Due Date" required>
            <DatePicker
              onChange={handleDateChange}
              disabledDate={disabledDate}
            />
          </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? "Creating..." : "Create Task"}
              </Button>
              <Button
                style={{ marginLeft: "8px" }}
                onClick={handleCancel}
                disabled={loading}
              >
                Close
              </Button>
            </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CsrTaskCreationPopUp;
