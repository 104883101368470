import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ClientProfile from "../../container/AssignedUsers/components/ClientProfile";
import { deleteAdvDoc, getPendingDoc } from "../../Services/AdvisorDoc";
import { deleteElement } from "../../utils/deleteElement";
import Button from "../Button";
import PopUPCms from "../PopUPCms";
import Backdrop from "../PopUPCms/BackDrop";
import UploadAdvDocForm from "../UploadAdvDocForm";
import { PendingAdvisorDocStyled } from "./PendingAdvisorDoc.styled";
import Row from "./Row";
import LayoutHOC from "../../components/UserSideBar";
import { getAuthToken } from "../../Services/httpUtil";

const PendingAdvisorDoc = ({ type }) => {
  const [show, setShow] = useState(false);
  const [docArr, setDocArr] = useState([]);
  const handleCross = () => {
    setShow(false);
  };
  const onHideModelClick = () => {
    setShow(true);
  };
  const state = useLocation();

  useEffect(() => {
    getAuthToken().then(function (data) {
      getPendingDoc(data, state.state.user_id).then((data) => {
        setDocArr(data);
      });
    });
  }, [state.state]);
  const updateDocArr = (arr) => {
    setDocArr(arr);
  };

  const handleDeleteExemption = (value) => {
    deleteElement(value, docArr, updateDocArr);
    getAuthToken().then(function (data) {
      deleteAdvDoc(data, value.external_id);
    });
  };

  return (
    <div
      className=""
      style={{
        width: "100%",
      }}
    >
      <ClientProfile userDetails={state.state} view={"Advisor Document View"} />
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h3
          className="superHeading"
          style={{ color: "#7F7F7F", margin: "10px 0 0" }}
        >
          Uploaded Document
        </h3>
        {type === "advisor" && (
          <Button
            onButtonClick={() => onHideModelClick()}
            buttonName="New Upload +"
            buttonClassName="Block"
          />
        )}
      </div>

      <PendingAdvisorDocStyled>
        <div className="head">
          <p className="c1">Document Name</p>
          <p className="c2">Details </p>
          <p className="c3">Document</p>
          <p className="c2">Document Type</p>
          <p className="c4">Document Action</p>
          <p className="c5">Delete</p>
        </div>

        {docArr
          .map((d, i) => {
            if (d.document_action === '"APPROVED"') {
              return { ...d, rank: 2 };
            } else {
              return { ...d, rank: 1 };
            }
          })
          .sort((a, b) => {
            return a.rank - b.rank;
          })
          .map((d, i) => {
            return (
              <Row
                type={type}
                data={d}
                docArr={docArr}
                updateDocArr={updateDocArr}
                handleDeleteExemption={handleDeleteExemption}
                index={i}
                userId={state.state.user_id}
              />
            );
          })}
        {docArr.length === 0 && (
          <p style={{ margin: "10px auto" }}>No data available</p>
        )}
      </PendingAdvisorDocStyled>
      <PopUPCms show={show} top={8} handleCross={handleCross}>
        <UploadAdvDocForm
          handleCross={handleCross}
          userId={state?.state?.user_id}
          docArr={docArr}
          updateDocArr={updateDocArr}
        />
      </PopUPCms>

      <Backdrop onHideModelClick={handleCross} show={show} />
    </div>
  );
};

export default LayoutHOC(PendingAdvisorDoc);
