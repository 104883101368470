import React, { useEffect, useLayoutEffect, useState } from "react";
import { StyledBasicCallDetailsForm } from "./BasicCallDetailsForm.styled";
import ConsultationConfirmation from "../../components/ConsultationConfirmation";
import ConsultationExtension from "../../components/ConsultationExtension";
import { StyledRadioComponent } from "../../components/RadioComponent/RadioComponent.styled";
import Chip from "../../../../commonComponents/Chip";
import { meetingTopics } from "../../../ManageAppointment/constants";
import { ConsultationMomService } from "../../../../Services/ConsultationMomService";
import {
  errorValidator,
  findUndefinedProperties,
  handleSubmitMom,
} from "../../utils";
import FormErrorMsgHandler from "../../../../commonComponents/FormErrormsgHandler";
import { ERROR_MESSAGE } from "../../constant";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOCAL_DB_KEYS } from "../../../../constants/localStorage";
import { modifyMetaData } from "../../../../utils/modifyMetaData";
import { sortArrayAndAppendOtherAtLast } from "../../../../utils/sortArrayAndAppendOtherAtLast";

const BasicCallDetailsForm = ({
  callSummary,
  call_booking_id,
  id,
  loader,
  setLoader,
  callTitle,
}) => {
  const THEME_DISCUSSED = useSelector(
    (state) => state.cmsReducer.discussed_keywords
  );
  const meetingTopics = useSelector((state) => state.cmsReducer.call_agenda);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const radioArr = [
    { id: 3, value: true, text: "Yes", isSelected: false },
    { id: 3, value: false, text: "No", isSelected: false },
  ];
  const [radioArrData, setRadioArrData] = useState(radioArr);
  const [dropDownArrData, setDropDownData] = useState(
    modifyMetaData(meetingTopics)
  );
  const [themeArrData, setThemeData] = useState(
    modifyMetaData(THEME_DISCUSSED)
  );
  const extensionOptionArr = [
    { id: 7, value: true, text: "Yes", isSelected: false },
    { id: 7, value: false, text: "No", isSelected: false },
  ];

  useLayoutEffect(() => {
    setThemeData(modifyMetaData(THEME_DISCUSSED));
  }, [THEME_DISCUSSED]);
  useLayoutEffect(() => {
    setDropDownData(modifyMetaData(meetingTopics));
  }, [meetingTopics]);

  const [extensionArrData, setExtensionArrData] = useState(extensionOptionArr);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [isError, setIsError] = useState({ error: false, errorArr: [] });
  const handleExtensionChange = (index, value) => {
    const newArr = extensionArrData.map((d, i) => {
      return {
        ...d,
        isSelected: i === index || d.value === value,
      };
    });
    setExtensionArrData(newArr);
  };

  const extensionComponentProps = {
    radioArrData: extensionArrData,
    title: "Was it extension of old call?",
    componentCategory: "is_extension_of_old_call",
    handleRadioChange: isEditDisabled ? () => {} : handleExtensionChange,
    isError: isError,
  };
  const handleRadioChange = (index, value) => {
    const newArr = radioArrData.map((d, i) => {
      return {
        ...d,
        isSelected: i === index || d?.value === value,
      };
    });
    setRadioArrData(newArr);
  };
  const handleDropDownChange = (value) => {
    const newArr = modifyMetaData(meetingTopics).map((d, i) => {
      return {
        ...d,
        isSelected: d.label === value || d.value === value,
      };
    });
    setDropDownData(newArr);
  };

  const title = `Was this a ${callTitle} call?`;
  const radioComponentProps = {
    radioArrData,
    title,
    componentCategory: "is_correct_call",
    handleRadioChange: isEditDisabled ? () => {} : handleRadioChange,
    isError: isError,
  };
  const DropDownComponentProps = {
    defaultValue: "Select agenda",
    dataOptions: sortArrayAndAppendOtherAtLast(dropDownArrData),
    componentCategory: "call_agenda",
    handleDropDownChange: isEditDisabled ? () => {} : handleDropDownChange,
    isError: isError,
  };
  const callConfirmationProp = {
    radioComponentProps,
    DropDownComponentProps,
  };
  const handleChipClick = (value) => {
    const newArr = themeArrData.map((d, i) => {
      return {
        ...d,
        isSelected:
          d.label === value || d.value === value ? !d.isSelected : d.isSelected,
      };
    });
    setThemeData(newArr);
  };

  const handleSubmit = async (callSummary) => {
    const isCorrectCall = radioArrData.filter((d) => d.isSelected)[0]?.value;
    const is_extension_of_old_call = extensionArrData.filter(
      (d) => d.isSelected
    )[0]?.value;
    const discussed_keywords = themeArrData
      .filter((d) => d.isSelected)
      .map((d, i) => {
        return d.value;
      });

    const callAgenda = dropDownArrData.filter((d) => d.isSelected)[0]?.value;
    const obj = {
      is_correct_call: isCorrectCall,
      call_agenda: callAgenda,
      is_extension_of_old_call,
      discussed_keywords,
      basic_details_status: "SUBMITTED",
    };
    const { call_agenda, ...correctCallObj } = obj;
    const call_summary_id =
      localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID) === "undefined"
        ? null
        : localStorage.getItem(LOCAL_DB_KEYS.CALL_SUMMARY_ID);
    const value = {
      call_summary_id,
      call_booking_id,
      written_by: id,

      call_summary_basic_details: isCorrectCall ? correctCallObj : obj,
    };
    const errorValidation = errorValidator(
      findUndefinedProperties(value.call_summary_basic_details)
    );

    setIsError(errorValidation);

    errorValidation.error === false &&
      handleSubmitMom(
        id,
        value,
        setIsEditDisabled,
        navigate,
        searchParams,
        dispatch,
        setLoader
      );
  };
  const updateThemeData = () => {
    let newArr = modifyMetaData(THEME_DISCUSSED);
    callSummary?.call_summary_basic_details?.discussed_keywords?.map(
      (value) => {
        newArr = newArr.map((d, i) => {
          return {
            ...d,
            isSelected:
              d.label === value || d.value === value
                ? !d.isSelected
                : d.isSelected,
          };
        });
      }
    );
    setThemeData(newArr);
  };
  const callSummaryhandle = () => {
    setIsEditDisabled(true);
    handleRadioChange(
      null,
      callSummary?.call_summary_basic_details?.is_correct_call
    );
    handleDropDownChange(callSummary?.call_summary_basic_details?.call_agenda);
    updateThemeData(callSummary);

    handleExtensionChange(
      null,
      callSummary?.call_summary_basic_details?.is_extension_of_old_call
    );
  };
  useEffect(() => {
    callSummary?.call_summary_basic_details?.basic_details_status ===
      "SUBMITTED" && callSummaryhandle();
  }, [
    callSummary?.call_summary_basic_details,
    call_booking_id,
    THEME_DISCUSSED,
  ]);

  return (
    <StyledBasicCallDetailsForm isEditDisabled={isEditDisabled}>
      <div className="formContainer">
        <ConsultationConfirmation {...callConfirmationProp} />
        <ConsultationExtension {...extensionComponentProps} />
        <StyledRadioComponent className="theme">
          <div className="title">Theme discussed during this call</div>
          <div className="radioModel">
            {sortArrayAndAppendOtherAtLast(themeArrData)?.map((data, index) => {
              return (
                <Chip
                  index={index}
                  key={index}
                  className={data.isSelected ? `chip activeChip` : `chip`}
                  onClick={() =>
                    isEditDisabled ? () => {} : handleChipClick(data.label)
                  }
                >
                  {data.label} {data.isSelected && `x`}
                </Chip>
              );
            })}
          </div>
          {isError?.error &&
            isError.errorArr.includes("discussed_keywords") && (
              <FormErrorMsgHandler style={{ bottom: "-4px" }}>
                {" "}
                {ERROR_MESSAGE.discussed_keywords}
              </FormErrorMsgHandler>
            )}
        </StyledRadioComponent>
      </div>
      <div
        className={
          isEditDisabled || loader ? "greenButton disabled" : "greenButton"
        }
        onClick={() => (isEditDisabled || loader ? () => {} : handleSubmit())}
      >
        Submit basic call details
      </div>
    </StyledBasicCallDetailsForm>
  );
};

export default BasicCallDetailsForm;
