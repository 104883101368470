import styled from "styled-components";
import { dim } from "../../styles/theme";
export const StyledPromoImageLinkGenerator = styled.div`
  width: 100%;
  height: 100%;

  .promoImgUploadWrapper {
    text-align: center;
    width: 100%;
    height: 100%;
  }
  .sub-header {
    margin-bottom: ${dim._30px};
  }
  .promoImgUploadBtn {
    cursor: pointer;
    padding: ${dim._10px};
    border: 1px solid #ccc;
    border-radius: ${dim._6px};
    background-color: #f8f8f8;
  }
  .promoImagePreview {
    margin-top: ${dim._30px};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .promoImg {
    max-width: ${dim._scale(550)};
  }
  .promoImg img {
    width: 100%;
    object-fit: contain;
  }
  .promoImageWrapper {
    display: flex;
    align-items: center;
  }
  .copyUrlBtn {
    margin-top: ${dim._10px};
    background-color: #4caf50;
    color: white;
    border: none;
    padding: ${dim._10px} ${dim._20px};
    font-size: ${dim._16px};
    cursor: pointer;
    border-radius: ${dim._6px};
    transition: background-color 0.3s ease;
  }
  .copyUrlBtn:hover {
    background-color: #45a049;
  }
`;
