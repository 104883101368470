
import styled from "styled-components";
import { dim } from "../../../../styles/theme";

export const UserProfileForAdvisorStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 84%;
  position: fixed;
  .example::-webkit-scrollbar {
    display: none;
  }
  .expireStrip{
    width: 100%;
    background-color: rgb(255 26 0 / 30%);
    color: red;
    padding: ${dim._10px};
    font-size: ${dim._18px};
    font-weight: 600;
    box-sizing: border-box;
}
  .chat {
    margin: 0 ${dim._18px} ${dim._18px};
    width: 100%;
  }
  .user-profile-for-adv-wrapper{
    display: flex;
    width: 100%;
  }
  .plan-name{
    color:#212121;
    font-weight: 500;
  }
  .user-profile-for-adv-info p {
    color:#212121;
    font-weight: 500;
  }
  
`;
export const ProfileStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75%;
  height: auto;
  margin: 0 ${dim._18px} ${dim._10px};
  padding: 0 0 ${dim._10px} 0;
  box-sizing: border-box;
`;

export const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0 ${dim._6px} 0;
  padding: ${dim._10px};
  background: #ffffff;
  border-radius: ${dim._4px};
  box-sizing: border-box;

  img {
    width: ${dim._64px};
    height: ${dim._64px};
    margin: 0 0 ${dim._10px} 0;
  }

  h1 {
    margin: 0px 0 0 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: ${dim._18px};
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #212f45;
  }
  .name-and-dp{
    display: flex;
    align-items: center;
    gap:10px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${dim._10px} 0 0 0;

  h2 {
    margin: 0 0 0 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: ${dim._14px};
    line-height: ${dim._18px};
    color: #828976;
  }

  p {
    margin: 0 0 0 ${dim._10px};
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: ${dim._14px};
    line-height: ${dim._18px};
    color: #828976;
  }

  .lang {
    padding: ${dim._4px} ${dim._8px};
    background-color: #e6e6e6;
    border-radius:${dim._16px};
  }

  .due {
    font-weight: 600;
    color: #d64541;
  }
`;
